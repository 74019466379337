import React, { useEffect, useState } from "react";
import { asyncUserSettingUpdate } from "../../actions/settingAction";
import { useDispatch, useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../actions/customFn";
import { getPageSettingData } from "../../actions/authActions";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const { settingData } = useSelector((state) => state.userAuth);
  const [userSettingData, setuserSettingData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (setting_title, setting_value, index) => {
    let valueData = {};
    valueData.setting_title = setting_title;
    valueData.setting_value = setting_value;

    asyncUserSettingUpdate(valueData).then((response) => {
      if (response) {
        let setting_data_arr = [...userSettingData];
        let clone = { ...setting_data_arr[index] };
        clone.value = setting_value == true ? "1" : "";
        setting_data_arr[index] = clone;
        setuserSettingData(setting_data_arr);
        dispatch(getPageSettingData({ dispatch, navigate }));
        // successMsg(response.data.message)
      } else {
        // errorMsg(response.data.message)
      }
    });
  };

  useEffect(() => {
    dispatch(getPageSettingData({ dispatch, navigate }));
  }, [dispatch]);

  useEffect(() => {
    if (
      settingData != undefined &&
      settingData.user_setting != undefined &&
      settingData.user_setting.length > 0
    ) {
      setuserSettingData(settingData.user_setting);
    }
  }, [settingData]);

  return (
    <>
      <div className="setting-page">
        <div className="setting-head p-2">
          <div className="row">
            <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-8 ">
              <h2>Setting</h2>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"></div>
          </div>
        </div>

        <div className="setting-bottom">
          {userSettingData.length > 0
            ? userSettingData.map((value, index) => {
                return (
                  <div className="setting-part px-2 border-0" key={index}>
                    <h4>{value.title}</h4>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        name="notification"
                        className="custom-control-input"
                        id={`customSwitchesChecked${index}`}
                        defaultChecked={value.value != "" ? true : ""}
                        onChange={(e) =>
                          handleChange(value.meta_key, !value.value, index)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`customSwitchesChecked${index}`}
                      ></label>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default Setting;
