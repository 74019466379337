import axios from "../axios";
import { errorMsg, getArray, successMsg } from "./customFn";

// ADD To Cart
export const getAddToCart = async (id, count, navigate, setIsCart) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      product_id: id,
      quantity: count,
    };
    const url = "/add/cart";
    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        successMsg(response.data.message);
        // navigate("/add-to-cart");

        setIsCart(true);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Cart List
export const getAddToCartList = async (
  setState,
  setCartSubTotals,
  setIsLoader,
  setInStock,
  is_reward_point_apply
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let formData = {
      customer_id: user_id,
      password: user_password,
    };
    if(is_reward_point_apply ==true){
      formData.is_reward_point_apply = is_reward_point_apply;
    }
    const url = "/carts";
    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        setState(response.data.data);
        setCartSubTotals(response.data.sub_totals);
        setInStock(response.data.is_stock);

        // console.log("CartList=",response.data);
      } else {
        // errorMsg(response.data.message);
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    });
  } catch (error) {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Update Cart
export const getUpdateCart = async (arr,setIsUpdate) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      cart_id: getArray(arr, "id"),
      quantity: getArray(arr, "quantity"),
    };
    const url = "/update/carts";
    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        successMsg(response.data.message);
        setIsUpdate((prevState) => (prevState === 0 ? 1 : 0));
      } else {
        errorMsg(response.data.message);
      }
      // setTimeout(() => {
      //   setIsLoader(false);
      // }, 1000);
    });
  } catch (error) {
    // console.log("error", error);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ---------- promo code -------------

export const asyncAddPromoCode = async (
  promoData,
  setPromoload,
  setIsLoader,
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("promo_code", promoData.promo_code);
    const url = "/apply_promocode";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        // setPromoload(!promoload);
        successMsg(response.data.message);
        // setIsLoader(true);
        setPromoload(true);
      } else {
        errorMsg(response.data.message);
      }
    });

    setTimeout(() => {
      setPromoload(false);
      // setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};


// ------------ remove promo code --------


export const asyncRemovePromoCode = async (
  promoData,
  setPromoload,
  setIsLoader,
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("promo_code", promoData);
    const url = "/remove_promocode";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        // setPromoload(!promoload);
        successMsg(response.data.message);
        // setIsLoader(true);
        setPromoload(true);
      } else {
        errorMsg(response.data.message);
      }
    });

    setTimeout(() => {
      setPromoload(false);
      // setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};


// -------- remove add to cart ---------

export const removeFromCartList = async (
  cartid,
  product_id,
  setReloadCart,
  setIsUpdate,
  setState,
  setCartSubTotals,
  setIsLoader,
  setInStock
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      cart_id : cartid,
      product_id : product_id,
      
    };
    const url = "/remove_cart_item";
    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
         
        successMsg(response.data.message);
        // setIsUpdate((prevState) => (prevState === 0 ? 1 : 0));
        // setReloadCart(true);
      } else {
        // errorMsg(response.data.message);
      }
      setTimeout(() => {
        // setIsLoader(false);
        // setReloadCart(false);
      }, 1000);
    });
  } catch (error) {
    setTimeout(() => {
      // setIsLoader(false);
      // setReloadCart(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};