import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPaginate from "react-paginate";
import RecordNotfound from "../Home/RecordNotfound";
import Loader from "../../hooks/Loader";

const SimpleCourse = (props) => {
  const { courselist } = props;

  const { id } = useParams();

  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };
  const navigate = useNavigate();

  const handleRoute = (url) => {
    navigate(url);
  };
  console.log(courselist, "courselist");
  return (
    <>
      {courselist.isLoader == undefined || courselist.isLoader == true ? (
        <Loader />
      ) : (
        <div className="wishlist_main_product " id="scrollableDiv">
          <div className="">
            {courselist.courselist != undefined &&
            courselist.courselist.length > 0 ? (
              <InfiniteScroll
                dataLength={courselist.courselist.length}
                next={courselist.handleLoadMore}
                hasMore={courselist.hasMore}
                loader={<Loader />}
                scrollableTarget="scrollableDiv"
                refreshFunction={courselist.refresh}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //   <h3 style={{ textAlign: "center" }}>Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //   <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
                // }
              >
                {courselist.courselist.map((value, index) => {
                  return (
                    <>
                      <div
                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 courses_card"
                        key={index}
                      >
                        <div className="inner_list_main">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                              {value.image == "" || value.image == undefined ? (
                                <div
                                  onClick={() =>
                                    handleRoute(`/courses-detail/${value.id}`)
                                  }
                                  className="product_img "
                                  style={{
                                    backgroundImage: `url(${images["timepost8.png"]})`,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="product_img "
                                  onClick={() =>
                                    handleRoute(`/courses-detail/${value.id}`)
                                  }
                                  style={{
                                    backgroundImage: `url(${value.image})`,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 ">
                              <div className="wish_lsit_info">
                                <div className="latestblogs_right_main_div">
                                  <Link
                                    to={`/friend_user_profile/${value.author_id}`}
                                    state={{ val: 1 }}
                                  >
                                    <div className="latestblogs_right_profile1">
                                      <div className="right_profile_left_bg">
                                        <img src={value.author_image} alt="" />
                                      </div>
                                      <div>
                                        <span>{value.author_name}</span>
                                        <div className="coures_total_student">
                                          <img
                                            src={
                                              images["coures_school_icon.png"]
                                            }
                                            alt=""
                                          />
                                          <div className="coures_total_student_text">
                                            (105) <span> Students</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="wish_lsit_info_top_btn">
                                    {value.category_title !== undefined && (
                                      <button
                                        onClick={
                                          value.id !== undefined
                                            ? () =>
                                                handleRoute(
                                                  `/courses-category/${value.category_id}`
                                                )
                                            : null
                                        }
                                      >
                                        {value.category_title
                                          ? value.category_title
                                          : "3D Animation"}{" "}
                                      </button>
                                    )}

                                    <img src={images["heart1.png"]} alt="" />
                                  </div>
                                </div>

                                <h2>{value.title}</h2>
                                <h6
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></h6>

                                <div className="prd_star_rating d-flex align-items-end justify-content-between">
                                  <div className="new_btn_all course-buy-btn">
                                    <button
                                      onClick={() =>
                                        handleRoute(
                                          `/courses-detail/${value.id}`
                                        )
                                      }
                                    >
                                      Buy now
                                    </button>
                                  </div>
                                  <ul>
                                    <li className="star_point">
                                      <ReactStars
                                        {...reactRatings}
                                        size={16}
                                        value={Number(value.rating)}
                                      />
                                    </li>
                                    <li className="star_point">
                                      {value.rating}
                                    </li>
                                  </ul>
                                  <div className="div_last_main w-auto">
                                    <span className="lecture-span">
                                      25 Lectures
                                    </span>
                                    <div className="product_price">
                                      <div className="price_main_tex">
                                        <p>
                                          <span>$</span>
                                          {value.price}
                                        </p>
                                        {/* <h3 className="per_person">13 Lectures</h3> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <RecordNotfound />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SimpleCourse;
