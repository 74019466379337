import React, { useEffect, useState } from "react";
// import { images } from "../actions/customFn";
// import { faStar } from "@fortawesome/fontawesome-free-solid";
// import { NavLink } from "react-router-dom";
import { FooterBig, Header, RecordNotfound } from "../components";
// import { TextField, IconButton, Autocomplete } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import FooterMobile from "../components/Home/FooterMobile";
// import ReactPaginate from "react-paginate";
import { motion } from "framer-motion";
// import Loader from "../hooks/Loader";
// import { getAllPost } from "../actions/uploadBlogs";
import {
  asyncDeleteCourseWishlist,
  asyncGetCourseWishlist,
} from "../actions/courseAction";
// import WishListItems from "../components/WishListItems";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Swal from "sweetalert2";
import CourseWishlist from "../components/CoursesDetail/CourseWishlist";
import ProductWishlist from "../components/CoursesDetail/ProductWishlist";
const WishList = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteCourse, setDeleteCourse] = useState(0);

  const paginate = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [wishlist, setWishList] = useState([]);
  const [page_count, setPagecount] = useState(0);

  useEffect(() => {
    setIsLoader(true);
    asyncGetCourseWishlist(setWishList, setPagecount, currentPage, setIsLoader);
  }, [currentPage, deleteCourse]);

  const handleDelete = (wishListID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this item from your wishlist ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7247e4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        asyncDeleteCourseWishlist(wishListID, setDeleteCourse);
        setTimeout(() => {
          setIsLoader(false);
        }, 1000);
      }
      if (res.isDismissed === true) {
      }
    });
  };
  const [value, setValue] = useState(0);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg wishlist-page">
        <div className="container-fluid">
          <Header />
          <div className="page-all-data orderhistory">
            <div>
              <div className="tab_orderhistory">
                <h1 className="heading">My Wishlist</h1>
                <div className="orderhistory_tab">
                  <ul>
                    <li>
                      <button
                        className={value === 0 && "active"}
                        onClick={() => handleChange(0)}
                      >
                        Course
                      </button>
                    </li>
                    <li>
                      <button
                        className={value === 1 && "active"}
                        onClick={() => handleChange(1)}
                      >
                        Product
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="orderhistory_scrolls">
                  {value === 0 && <CourseWishlist />}
                  {value === 1 && <ProductWishlist />}
                </div>
              </div>
            </div>
            <div className="mobile-footer-new">
              <FooterMobile />
            </div>
          </div>
          <FooterBig />
        </div>
        <div className="container-fluid d-none">
          <Header />
          <div className="postlist_section">
            <div>
              <div className="orderhistory wishlist_page">
                {/* <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12"> */}
                <div className="orderhistory_top">
                  {/* <h1>Product</h1> */}
                  <div className="orderhistory_tab">
                    <Box
                      sx={{
                        maxWidth: { xs: 255, sm: 480 },
                        bgcolor: "background.paper",
                      }}
                    >
                      <Tabs value={value} onChange={handleChange}>
                        <Tab label="Course Wishlist" />
                        <Tab label="Product Wishlist" />
                      </Tabs>
                    </Box>
                  </div>
                </div>
                {/* <div className="postlist_heading">
                  <h3>Course Wishlist</h3>
                </div> */}
                {/* </div> */}
              </div>
              {value === 0 && <CourseWishlist />}

              {value === 1 && <ProductWishlist />}

              {/* {isLoader == true ? (
              <Loader />
            ) : (
              <>
                <div className="wishlist_main_product gridcourse" id="">
                  <div className="row">
                    {wishlist.length > 0 ? (
                      wishlist.map(function(value, index) {
                        return (
                          <WishListItems
                            value={value}
                            handleDelete={handleDelete}
                            wishlistID={value.wishlist_id}
                          />
                        );
                      })
                    ) : (
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 courses_card">
                        <RecordNotfound />

                      </div>
                    )}
                  </div>
                </div>
              </>
            )} */}

              {/* paginate */}
              {/* {page_count > 1 && (
              <div className="paginate_all">
                <ReactPaginate
                  pageCount={page_count}
                  onPageChange={paginate}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"active"}
                  pageRange={5}
                  marginPagesDisplayed={2}
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                        transform="translate(7.172) rotate(90)"
                        fill="#454AA6"
                      />
                    </svg>
                  }
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                        transform="translate(15.086 -5) rotate(90)"
                        fill="#454AA6"
                      />
                    </svg>
                  }
                />
              </div>
            )} */}
            </div>
          </div>
          <FooterBig />

          <div className="mobile-footer-new">
            <FooterMobile />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default WishList;
