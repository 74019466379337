import axios from "../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getcountryList = createAsyncThunk(
  "auth/country_list",
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/get-countries");
      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getStateList = async (setState,country_id) => {
  try {
    const params = {
      country: country_id
    };

    const response = await axios.post("/get-states", params);
    if (response.data.status === true) {
      setState(response.data.data);
    } else {
      setState([]);
    }
  } catch (error) {
    if (error.response.status === 401) {
    } else if (error.response.status === 402) {
    }
  }
};


export const getCityList = async (setState,State_id) => {
  try {
    const params = {
      state: State_id
    };

    const response = await axios.post("/get-cities", params);
    if (response.data.status === true) {
      setState(response.data.data);
    } else {
      setState([]);
    }
  } catch (error) {
    if (error.response.status === 401) {
    } else if (error.response.status === 402) {
    }
  }
};
