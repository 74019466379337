import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
// import { images } from "../../actions/customFn";
// import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";

const CourseRating = (props) => {
  const { course_retings } = props;

  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={""} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  return (
    <div className="course_rating_main">
      {props.avg_course_retings != undefined && (
        <>
          {Number(props.avg_course_retings) > 0 ? (
            <div className="course_rating_main_hesing">
              <div className="prd_star_rating ">
                <ul>
                  <li>
                    <ReactStars
                      {...reactRatings}
                      size={14}
                      value={Number(props.avg_course_retings)}
                    />
                  </li>

                  <li className="star_point">{props.avg_course_retings}</li>
                </ul>
              </div>
              <h1>Course Rating</h1>
            </div>
          ) : (
            ""
          )}
        </>
      )}

      <div className="row">
        {course_retings.length > 0 &&
          course_retings.map((value, index) => {
            return (
              <div
                className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                key={index}
              >
                <div className="course_rating_card_inner">
                  <div className="latestblogs_right_profile1_left">
                    <div className="right_profile_left_bg">
                      <img src={value.image} alt="" />
                    </div>
                    <div className="right_profile_right_new">
                      <h1>
                        {value.first_name} {value.last_name}
                      </h1>
                      <div className="prd_star_rating ">
                        <ul>
                          <li>
                            <ReactStars
                              {...reactRatings}
                              size={14}
                              value={Number(value.ratings)}
                            />
                          </li>

                          <li className="star_point">{value.ratings}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h2>{value.description}</h2>
                </div>
              </div>
            );
          })}
      </div>
      {course_retings != undefined ||
        (course_retings.length > 0 && (
          <div className="course_rating_main_last_btn">
            <button>Show More</button>
          </div>
        ))}
    </div>
  );
};

export default CourseRating;
