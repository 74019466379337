import React from "react";
import { images } from "../../actions/customFn";

const chapterdata = [
  {
    pics: images["chapter_img1.png"],
    unit: "Chapter 02",
    name: "Lorem Ipsum is simply dummy...",
    para: "It has survived not only five centuries,",
  },
  {
    pics: images["chapter_img2.png"],
    unit: "Chapter 02",
    name: "Lorem Ipsum is simply dummy...",
    para: "It has survived not only five centuries,",
  },
  {
    pics: images["chapter_img3.png"],
    unit: "Chapter 02",
    name: "Lorem Ipsum is simply dummy...",
    para: "It has survived not only five centuries,",
  },
  {
    pics: images["chapter_img4.png"],
    unit: "Chapter 02",
    name: "Lorem Ipsum is simply dummy...",
    para: "It has survived not only five centuries,",
  },
  {
    pics: images["chapter_img5.png"],
    unit: "Chapter 02",
    name: "Lorem Ipsum is simply dummy...",
    para: "It has survived not only five centuries,",
  },
];

const ChapterList = () => {
  return (
    <>
      <div className="chaptersection">
        <h5>Chapter List</h5>

        {chapterdata.map((value, index) => {
          return (
            <div className="chapter_lists" key={index}>
              <div className="chapter_img">
                <img src={value.pics} alt="" />
              </div>
              <div className="chapter_content">
                <h6>{value.unit}</h6>
                <h2>{value.name}</h2>
                <p>{value.para}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChapterList;
