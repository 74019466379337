import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
// import { EarnRewards } from "..";
import { NavLink } from "react-router-dom";
import { asyncgetEarnReward } from "../../actions/rewardAction";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";

const EarnRewardsPoint = () => {
  const [isLoader, setIsLoad] = useState();
  const [reward, setReward] = useState();

  useEffect(() => {
    setIsLoad(true);
    asyncgetEarnReward(setReward, setIsLoad);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <div className="earn_rewads_point_section">
          <div className="earn_heading">
            <h2>Earn Rewards</h2>
          </div>
          <hr />
          {isLoader == true ? (
            <Loader />
          ) : (
            <div className="earn_rewards_scroll">
              <div className="row">
                {reward != undefined && reward.length > 0 ? (
                  reward.map(function(value, index) {
                    return (
                      <div
                        className="col-xl-6 col-lg-12 col-md-12 col-sm-12 earn_reward_pinkbox"
                        key={index}
                      >
                        <div className="earn_main_box">
                          <div className="challenge_day">
                            <h5>{value.point} Point</h5>
                          </div>

                          <div className="earn_rewards_main" key={index}>
                            <div className="earn_rewaeds_box">
                              <div className="earn_rewaeds_box_left">
                                <h5>{value.title}</h5>
                              </div>

                              <div className="challenges_img earn-reward">
                                <img
                                  src={images["challenges-img.png"]}
                                  alt=""
                                />
                                <div className="go_now_button mt-3">
                                  <NavLink>Go Now</NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <RecordNotfound />
                )}
              </div>
            </div>
          )}
        </div>
      </>
    </motion.div>
  );
};
export default EarnRewardsPoint;
