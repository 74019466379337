import React from "react";
import OwlCarousel from "react-owl-carousel";

const CoursePlaylist = (props) => {
  const { course_playlist } = props;

  const experience = {
    // loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 4.5,
      },
    },
  };
  return (
    <div className="Course_Playlist_main">
      <h1>Course Playlist</h1>

      <OwlCarousel
        {...experience}
        className="owl-carousel owl-theme slider_main"
        margin={10}
      >
        {/* <div className="row"> */}
        {course_playlist.map((value, index) => {
          return (
            <div className="item" key={index}>
              <div className="course-list1 position-relative">
                <button className="Add_wishlist_btn p-2 position-absolute m-2">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_407_637)">
                        <path
                          d="M14.5832 1.59741C13.6448 1.61201 12.7269 1.87396 11.9222 2.3568C11.1174 2.83965 10.4544 3.5263 9.99988 4.34741C9.54542 3.5263 8.88233 2.83965 8.07758 2.3568C7.27283 1.87396 6.35493 1.61201 5.41655 1.59741C3.92067 1.6624 2.51131 2.31679 1.49637 3.41761C0.481433 4.51842 -0.0565749 5.97618 -0.000116138 7.47241C-0.000116138 11.2616 3.98822 15.3999 7.33322 18.2057C8.08007 18.8334 9.02435 19.1774 9.99988 19.1774C10.9754 19.1774 11.9197 18.8334 12.6665 18.2057C16.0115 15.3999 19.9999 11.2616 19.9999 7.47241C20.0563 5.97618 19.5183 4.51842 18.5034 3.41761C17.4885 2.31679 16.0791 1.6624 14.5832 1.59741Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_407_637">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </button>
                <img src={value.image} alt="" />
                <div className="course-cont">
                  <h5>{value.playlist_title}</h5>
                  <h6>{value.heading}</h6>
                  {/* <p
                    className="course_playlistdiscp"
                    dangerouslySetInnerHTML={{
                      __html: value.playlist_description,
                    }}
                  ></p> */}
                  <button className="buy-btn">Buy Now</button>
                </div>
              </div>
            </div>
          );
        })}
        {/* </div> */}
      </OwlCarousel>
    </div>
  );
};

export default CoursePlaylist;
