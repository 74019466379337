import React from "react";

const RecordNotfound = () => {
  return (
    <div className="text-center mt-4 col-12" style={{color:'#fff'}}>
      <h2>Record Not Found</h2>
    </div>
  );
};

export default RecordNotfound;
