import React from "react";

const CourseDetailsDescription = (props) => {
  const { description } = props;
  return (
    <>
      <div className="course_description_section">
        <div className="course_details_description">
          <h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_2628_133)">
                <path
                  d="M18.667 13.889V10.556H16.444V13.889H13.111V16.111H16.444V19.444H18.666V16.111H22V13.889H18.667ZM2 5H14.222V7.222H2V5ZM2 9.444H14.222V11.666H2V9.444ZM2 13.888H10.889V16.11H2V13.888Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2628_133">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            Description
          </h5>

          {/* <h2>Lorem Ipsum is simply dummy text of the printing</h2> */}

          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
        {/* <div className="chapter_courselist">
          <ul className="course-detail_blog_list">
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default CourseDetailsDescription;
