import React from "react";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const FilterAll = (props) => {
  const {
    coursedetail,
    setSelectchecked,
    Selectchecked,
    setCurrentPage,
    setCourseList,
    setcoursecount,
  } = props;

  const handleCheckCategory = (e, id) => {
    setCurrentPage(0);
    if (e.target.checked === true) {
      setSelectchecked([...Selectchecked, id]);
    } else {
      const result = Selectchecked.filter((e) => e !== id);
      setSelectchecked(result);
    }
    setCourseList([]);
  };

  return (
    <div className="page_left_side_main filter_desktop_main">
      <div className="page_left_side_main_hediing ">
        <h1>Filter</h1>
        <p></p>
      </div>

      <div>
        <Accordion
          defaultExpanded={true}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Course Categories</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                {coursedetail != undefined &&
                  coursedetail.length > 0 &&
                  coursedetail.map((value, index) => {
                    return (
                      <div className="all-input-filter" key={index}>
                        {/* <FormControlLabel control={<Checkbox />} label={value.title} /> */}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => handleCheckCategory(e, value.id)}
                          id={"category" + index}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"category" + index}
                        >
                          {value.title}
                        </label>
                      </div>
                    );
                  })}
                {/* <FormControlLabel control={<Checkbox />} label="Business" />
                <FormControlLabel control={<Checkbox />} label="Technology" />
                <FormControlLabel control={<Checkbox />} label="Art" />
                <FormControlLabel control={<Checkbox />} label="Wellness" /> */}
              </FormGroup>
              {/* </div> */}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Levels</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <div className="all-input-filter">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label">Levels</label>
                </div>
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Featured Course</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <div className="all-input-filter">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label">Featured Course</label>
                </div>
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Level</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <div className="all-input-filter">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label">Level</label>
                </div>
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Course Cards</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <div className="all-input-filter">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label">Course Cards</label>
                </div>
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Elite</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <div className="all-input-filter">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label">NFT CERTIFICATES</label>
                </div>
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Featured Courses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="New" />
                <FormControlLabel control={<Checkbox />} label="Trending" />
                <FormControlLabel control={<Checkbox />} label="Highly Rated" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Course Cards</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Required" />
                <FormControlLabel control={<Checkbox />} label="Required" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Subtitles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Required" />
                <FormControlLabel control={<Checkbox />} label="Required" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Price</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Required" />
                <FormControlLabel control={<Checkbox />} label="Required" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion> */}
      </div>
    </div>
  );
};

export default FilterAll;
