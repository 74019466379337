import React from "react";

const Description = (props) => {
  const { description } = props;

  return (
    <div className="description_section">
      <h1>Description</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></p>

      {/* <div className="description_section_inner">
        <div className="blog_details_text">
          <ul className="custom-list_blog">
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default Description;
