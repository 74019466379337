import React, { useEffect, useState } from "react";
import { getArray, images } from "../../actions/customFn";

import FsLightbox from "fslightbox-react";
import UploadPhotoModal from "../Modal/UploadPhotoModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllPhotosList } from "../../actions/uploadPhoto";
import RecordNotfound from "../Home/RecordNotfound";

const UserPhotos = (props) => {
  const dispatch = useDispatch();
  const { photosList } = props;

  // const [toggler, setToggler] = useState(false);
  // const [productIndex, setProductIndex] = useState(0);

  const [uploadload, setUploadLoad] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [photo_array, setPhotoArray] = useState([]);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number, image) {
    // setPhotoArray([image]);
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number + 1,
    });
  }

  useEffect(() => {
    if (photosList !== undefined && photosList.length > 0) {
      setPhotoArray(getArray(photosList, "photo"));
    }
  }, [photosList]);

  return (
    <>
      <div className="allphotos">
        <div className="allphotos-head p-2">
          <h2>Photos</h2>
          {/* <div className="uploadphoto_btn"> */}
          {/* <button className="upload_btn" onClick={handleShow}>
              Upload Images
            </button> */}
          {/* </div> */}
        </div>

        <div className="allphotos-bottom px-2">
          {photosList !== undefined && photosList.length > 0 ? (
            <>
              <div className="allphotos-bottom_inner">
                {photosList.map((value, index) => {
                  return (
                    <div className="allphotos-bottom_inner_main" key={index.id}>
                      <div className="allphoto-one all_images_popup">
                        <img
                          src={value.photo}
                          alt=""
                          onClick={() => {
                            openLightboxOnSlide(index, value.photo);
                          }}
                        />
                      </div>
                      <br /> <br />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="">
              <RecordNotfound />
            </div>
          )}

          <div className="all_images_popup">
            {/* <FsLightbox toggler={toggler} sources={productIndex} type="image" key={productIndex} /> */}
            <FsLightbox
              toggler={lightboxController.toggler}
              sources={photo_array}
              slide={lightboxController.slide}
            />
          </div>
        </div>

        <UploadPhotoModal
          show={show}
          setIsLoader={setIsLoader}
          setShow={setShow}
          close={handleClose}
          setUploadLoad={setUploadLoad}
        />
      </div>
    </>
  );
};

export default UserPhotos;
