import React, { useEffect, useState } from "react";
// import { images } from "../actions/customFn";
// import { faStar } from "@fortawesome/fontawesome-free-solid";
// import { NavLink } from "react-router-dom";
import { RecordNotfound } from "../../components";
// import { TextField, IconButton, Autocomplete } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
// import FooterMobile from "../components/Home/FooterMobile";
import ReactPaginate from "react-paginate";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
// import { getAllPost } from "../actions/uploadBlogs";
import {
  asyncDeleteCourseWishlist,
  asyncGetCourseWishlist,
} from "../../actions/courseAction";
// import WishListItems from "../components/WishListItems";
import Swal from "sweetalert2";
import WishListItems from "../WishListItems";
const CourseWishlist = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteCourse, setDeleteCourse] = useState(0);

  const paginate = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [wishlist, setWishList] = useState([]);
  const [page_count, setPagecount] = useState(0);

  useEffect(() => {
    setIsLoader(true);
    asyncGetCourseWishlist(setWishList, setPagecount, currentPage, setIsLoader);
  }, [currentPage, deleteCourse]);

  const handleDelete = (wishListID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this item from your wishlist ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7247e4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        asyncDeleteCourseWishlist(wishListID, setDeleteCourse);
        setTimeout(() => {
          setIsLoader(false);
        }, 1000);
      }
      if (res.isDismissed === true) {
      }
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="">
        <div className="postlist_section productwish_margin">
          {/* <div className="row post_list_recent">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="postlist_heading">
                  <h3>Course Wishlist</h3>
                </div>
              </div>
            </div> */}

          {isLoader === true ? (
            <Loader />
          ) : (
            <>
              <div className="gridcourse" id="">
                {" "}
                {/* // wishlist_main_product */}
                <div className="row">
                  {wishlist.length > 0 ? (
                    wishlist.map(function(value, index) {
                      return (
                        <WishListItems
                          value={value}
                          handleDelete={handleDelete}
                          wishlistID={value.wishlist_id}
                        />
                      );
                    })
                  ) : (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 courses_card">
                      <RecordNotfound />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {/* paginate */}
          {page_count > 1 && (
            <div className="paginate_all">
              <ReactPaginate
                pageCount={page_count}
                onPageChange={paginate}
                containerClassName={"navigationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"navigationDisabled"}
                activeClassName={"active"}
                pageRange={5}
                marginPagesDisplayed={2}
                nextLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.172"
                    height="14"
                    viewBox="0 0 7.172 14"
                  >
                    <path
                      id="fi-rr-angle-small-down"
                      d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                      transform="translate(7.172) rotate(90)"
                      fill="#454AA6"
                    />
                  </svg>
                }
                previousLabel={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.172"
                    height="14"
                    viewBox="0 0 7.172 14"
                  >
                    <path
                      id="fi-rr-angle-small-down"
                      d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                      transform="translate(15.086 -5) rotate(90)"
                      fill="#454AA6"
                    />
                  </svg>
                }
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CourseWishlist;
