import React, { useState } from "react";
import AskEducatorQuestionModal from "../Modal/AskEducatorQuestionModal";
import { Link, useParams } from "react-router-dom";
import { errorMsg, successMsg } from "../../actions/customFn";
import { asyncCourseLike } from "../../actions/courseAction";

const CourseIncludes = (props) => {
  const {
    handleShow,
    // handlepaymentClose,
    handlepaymentShow,
    CourseDetail,
  } = props;

  const { id } = useParams();
  const [likeData, setLikeData] = useState(CourseDetail.course_like);
  const [likecount, setLikeCount] = useState(CourseDetail.total_likes);
  const [formData, setFormData] = useState({
    course_id: id,
  });

  const handlecourselike = async () => {
    setLikeCount(!likeData ? likecount + 1 : likecount - 1);
    setLikeData(!likeData);
    try {
      asyncCourseLike(formData, likeData, setLikeData);
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  const objectStyle = {
    backgroundColor: "#ff0098",
  };
  const objectStyleValid = {
    backgroundColor: "transparent",
  };

  function copyLink() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    successMsg("Link copied to Clipboard");
  }

  return (
    <div className="course_includesmai">
      {CourseDetail.tag_title != undefined ||
        (CourseDetail.tag_title != "" && (
          <button className="animation_btn">{CourseDetail.tag_title}</button>
        ))}
      <div className="ci-type-course">
        <button>3D Animation</button>
      </div>
      <h1>This course includes</h1>

      <div className="row">
        {CourseDetail.course_duration != undefined &&
        CourseDetail.course_duration.length > 0 ? (
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="course_includesmai_text">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.5265 19.3553C20.6833 19.3554 20.8385 19.3246 20.9834 19.2646C21.1282 19.2046 21.2598 19.1167 21.3707 19.0058C21.4815 18.895 21.5694 18.7633 21.6294 18.6185C21.6893 18.4736 21.7201 18.3183 21.72 18.1616V5.83854C21.7201 5.68176 21.6893 5.52651 21.6294 5.38165C21.5694 5.23679 21.4815 5.10516 21.3707 4.99429C21.2598 4.88343 21.1282 4.79549 20.9834 4.73552C20.8385 4.67555 20.6833 4.64471 20.5265 4.64478H3.47379C3.317 4.64471 3.16174 4.67555 3.01688 4.73552C2.87202 4.79549 2.7404 4.88342 2.62953 4.99428C2.51867 5.10514 2.43074 5.23677 2.37077 5.38163C2.3108 5.52649 2.27997 5.68175 2.28003 5.83854V18.1616C2.27997 18.3184 2.3108 18.4736 2.37077 18.6185C2.43074 18.7633 2.51867 18.895 2.62953 19.0058C2.7404 19.1167 2.87202 19.2046 3.01688 19.2646C3.16174 19.3246 3.317 19.3554 3.47379 19.3553H20.5265ZM19.3328 16.9678H4.66707V7.0323H19.3328V16.9678Z"
                    fill="#454AA6"
                  />
                  <path
                    d="M15.5566 12.0692L12.6814 13.7283L9.80762 15.3884V8.75098L12.6814 10.4113L15.5566 12.0692Z"
                    fill="#454AA6"
                  />
                </svg>
              </div>
              <p>{CourseDetail.course_duration} on-demand video</p>
            </div>
          </div>
        ) : (
          ""
        )}

        {CourseDetail.course_includes != undefined &&
          CourseDetail.course_includes != "" && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="course_includesmai_text">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21.3334 8.66675H16.0001C15.6465 8.66675 15.3073 8.80722 15.0573 9.05727C14.8072 9.30732 14.6667 9.64646 14.6667 10.0001V20.0001C14.6667 20.3537 14.8072 20.6928 15.0573 20.9429C15.3073 21.1929 15.6465 21.3334 16.0001 21.3334H21.3334C21.687 21.3334 22.0262 21.1929 22.2762 20.9429C22.5263 20.6928 22.6667 20.3537 22.6667 20.0001V10.0001C22.6667 9.64646 22.5263 9.30732 22.2762 9.05727C22.0262 8.80722 21.687 8.66675 21.3334 8.66675ZM21.3334 10.0001V18.6667H16.0001V10.0001H21.3334Z"
                      fill="#454AA6"
                    />
                    <path
                      d="M18.6666 2.66675H2.66659C2.31296 2.66675 1.97382 2.80722 1.72378 3.05727C1.47373 3.30732 1.33325 3.64646 1.33325 4.00008V14.6667C1.33325 15.0204 1.47373 15.3595 1.72378 15.6096C1.97382 15.8596 2.31296 16.0001 2.66659 16.0001H7.99992V17.3334H6.21325C6.00901 17.3102 5.80367 17.3665 5.63982 17.4906C5.47598 17.6148 5.3662 17.7972 5.33325 18.0001C5.3662 18.203 5.47598 18.3854 5.63982 18.5095C5.80367 18.6337 6.00901 18.69 6.21325 18.6667H13.2799V18.4201H13.3333V14.6667H2.66659V4.00008H18.6666V7.33342H19.9999V4.00008C19.9999 3.64646 19.8594 3.30732 19.6094 3.05727C19.3593 2.80722 19.0202 2.66675 18.6666 2.66675Z"
                      fill="#454AA6"
                    />
                  </svg>
                </span>
                <p>
                  Access on{" "}
                  {CourseDetail.course_includes?.map((value, index) => {
                    return <span key={index}> {value.title} </span>;
                  })}{" "}
                </p>
              </div>
            </div>
          )}

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="course_includesmai_text">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11.25"
                  stroke="#454AA6"
                  strokeWidth="1.5"
                />
                <path
                  d="M17.1447 8.15377L10.2358 15.3738C10.1893 15.4225 10.1341 15.4612 10.0733 15.4876C10.0126 15.514 9.94738 15.5276 9.88156 15.5276C9.81574 15.5276 9.75056 15.514 9.68978 15.4876C9.62899 15.4612 9.57379 15.4225 9.52734 15.3738L6.85557 12.5789C6.80912 12.5302 6.75392 12.4915 6.69313 12.4651C6.63235 12.4387 6.56717 12.4251 6.50135 12.4251C6.43553 12.4251 6.37036 12.4387 6.30957 12.4651C6.24878 12.4915 6.19358 12.5302 6.14713 12.5789C6.1005 12.6275 6.06349 12.6851 6.03825 12.7487C6.013 12.8122 6 12.8803 6 12.9491C6 13.0179 6.013 13.086 6.03825 13.1495C6.06349 13.2131 6.1005 13.2708 6.14713 13.3193L8.8199 16.1121C9.10185 16.4062 9.48394 16.5714 9.88231 16.5714C10.2807 16.5714 10.6628 16.4062 10.9447 16.1121L17.8531 8.89364C17.8997 8.84511 17.9366 8.78745 17.9618 8.72397C17.987 8.66049 18 8.59243 18 8.52371C18 8.45498 17.987 8.38692 17.9618 8.32345C17.9366 8.25997 17.8997 8.20231 17.8531 8.15377C17.8067 8.10503 17.7515 8.06636 17.6907 8.03997C17.6299 8.01358 17.5647 8 17.4989 8C17.4331 8 17.3679 8.01358 17.3071 8.03997C17.2463 8.06636 17.1911 8.10503 17.1447 8.15377Z"
                  fill="#454AA6"
                  stroke="#454AA6"
                />
              </svg>
            </span>
            <p>Full lifetime access</p>
          </div>
        </div>
      </div>
      <hr className="border_line1" />

      <div className="course_includesmai_share_btn">
        <button className="course_share_btn1" onClick={copyLink}>
          <span>
            <h1>Share</h1>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M14.7656 12.6563C14.0525 12.656 13.3678 12.9352 12.8582 13.434L7.89058 10.641C7.99265 10.2198 7.99265 9.78025 7.89058 9.359L12.8566 6.56603C13.3205 7.0173 13.9299 7.28872 14.5757 7.33168C15.2215 7.37465 15.8615 7.18636 16.3811 6.80052C16.9008 6.41468 17.2661 5.85648 17.4117 5.22587C17.5574 4.59525 17.4738 3.93339 17.176 3.35876C16.8782 2.78414 16.3855 2.33429 15.7863 2.08973C15.1871 1.84517 14.5204 1.82187 13.9055 2.02402C13.2907 2.22616 12.7679 2.64054 12.4307 3.19298C12.0935 3.74542 11.9639 4.39983 12.0652 5.03908L6.98198 7.89845C6.58271 7.56579 6.09691 7.35381 5.58149 7.28732C5.06607 7.22084 4.54238 7.30262 4.07176 7.52307C3.60115 7.74352 3.2031 8.09352 2.92426 8.53207C2.64541 8.97061 2.49731 9.47954 2.49731 9.99923C2.49731 10.5189 2.64541 11.0279 2.92426 11.4664C3.2031 11.9049 3.60115 12.2549 4.07176 12.4754C4.54238 12.6958 5.06607 12.7776 5.58149 12.7111C6.09691 12.6447 6.58271 12.4327 6.98198 12.1L12.0652 14.9609C11.9773 15.5132 12.0609 16.0791 12.3046 16.5824C12.5483 17.0857 12.9405 17.5022 13.4282 17.7757C13.916 18.0491 14.4759 18.1665 15.0324 18.112C15.5889 18.0574 16.1153 17.8335 16.5407 17.4705C16.966 17.1075 17.2698 16.6228 17.4112 16.0817C17.5525 15.5407 17.5246 14.9693 17.3311 14.4447C17.1377 13.92 16.788 13.4673 16.3293 13.1475C15.8705 12.8277 15.3248 12.6563 14.7656 12.6563Z"
                fill="#FF0098"
              />
            </svg>
          </span>{" "}
        </button>

        <button
          className="course_share_btn2"
          onClick={() => handlecourselike()}
          style={likeData == true ? objectStyle : objectStyleValid}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33341 17.5001H4.16675V6.66675H3.33341C2.89139 6.66675 2.46746 6.84234 2.1549 7.1549C1.84234 7.46746 1.66675 7.89139 1.66675 8.33341V15.8334C1.66675 16.2754 1.84234 16.6994 2.1549 17.0119C2.46746 17.3245 2.89139 17.5001 3.33341 17.5001ZM16.6667 6.66675H10.8334L11.7684 3.86008C11.8518 3.60959 11.8746 3.34287 11.8347 3.08188C11.7949 2.82089 11.6936 2.57309 11.5392 2.35891C11.3849 2.14473 11.1818 1.97028 10.9468 1.84994C10.7118 1.72961 10.4516 1.66682 10.1876 1.66675H10.0001L5.83341 6.19841V17.5001H15.0001L18.2601 10.3367L18.3334 10.0001V8.33341C18.3334 7.89139 18.1578 7.46746 17.8453 7.1549C17.5327 6.84234 17.1088 6.66675 16.6667 6.66675Z"
                fill="white"
              />
            </svg>
          </span>
          <span>
            <h1>{likecount}</h1>
          </span>
        </button>
      </div>
      <hr className="border_line2 mb-0" />
      <div className="price_main_tex">
        <p>
          {" "}
          <span>$</span>
          {CourseDetail.price}
        </p>
      </div>
      <div className="card_Buy_now">
        {CourseDetail.is_purchased ? (
          <Link to={`/chapter-details/${id}`}>
            <button>View Chapter Details</button>
          </Link>
        ) : (
          <button
            onClick={(e) => {
              handlepaymentShow();
            }}
          >
            Buy now
          </button>
        )}
      </div>

      <div className="ask_card_btn">
        <button onClick={handleShow}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0001 18.3334C5.39758 18.3334 1.66675 14.6026 1.66675 10.0001C1.66675 5.39758 5.39758 1.66675 10.0001 1.66675C14.6026 1.66675 18.3334 5.39758 18.3334 10.0001C18.3334 14.6026 14.6026 18.3334 10.0001 18.3334ZM10.0001 16.6667C11.7682 16.6667 13.4639 15.9644 14.7141 14.7141C15.9644 13.4639 16.6667 11.7682 16.6667 10.0001C16.6667 8.23197 15.9644 6.53628 14.7141 5.28604C13.4639 4.03579 11.7682 3.33341 10.0001 3.33341C8.23197 3.33341 6.53628 4.03579 5.28604 5.28604C4.03579 6.53628 3.33341 8.23197 3.33341 10.0001C3.33341 11.7682 4.03579 13.4639 5.28604 14.7141C6.53628 15.9644 8.23197 16.6667 10.0001 16.6667ZM9.16675 13.3334H10.8334V15.0001H9.16675V13.3334ZM9.16675 11.6734C9.16675 11.6734 10.8334 11.6667 10.8334 11.6734C10.8334 10.8384 13.3334 10.0001 13.3334 8.33341C13.3334 6.49175 11.8559 5.00008 10.0076 5.00008C9.56921 4.99909 9.13495 5.08459 8.72967 5.25166C8.32438 5.41873 7.95603 5.66411 7.64571 5.97374C7.33539 6.28336 7.08918 6.65116 6.9212 7.05607C6.75321 7.46098 6.66675 7.89504 6.66675 8.33341H8.33341C8.33341 7.41675 9.08341 6.66675 10.0001 6.66675C10.9167 6.66675 11.6667 7.41675 11.6667 8.33341C11.6667 9.08341 9.16675 10.3059 9.16675 11.6734Z"
                fill="#FF0098"
              />
            </svg>
          </span>
          Ask the Educator a Question
        </button>
      </div>
    </div>
  );
};

export default CourseIncludes;
