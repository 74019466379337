import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

// -------------- get challenges ------------------
export const asyncgetChallenges = async (setState, setLoadPage) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    const url = "/challenges";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);

        setLoadPage(true);
      } else {
        errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
