import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import RecordNotfound from "../Home/RecordNotfound";
const Groups = () => {
  const { groups_list } = useSelector((state) => state.chatData);
  const [groupList, setGroupList] = useState([]);
  useEffect(() => {
    if (groups_list.length > 0) {
      setGroupList(groups_list);
    }
  }, [groups_list]);
  return (
    <>
      <div className="groups_page">
        <div className="groups_top">
          <h4>
            Group <span>( {groupList.length} )</span>{" "}
          </h4>
          <NavLink to={"/chat"}>
            <button className="groups-btn" type="button">
              Create Group
            </button>
          </NavLink>
        </div>

        <div className="groups_botm">
          {groupList.length > 0 ? (
            groupList.map((value, index) => {
              return (
                <NavLink to={"/chat"}>
                  <div className="group_data" key={index}>
                    <div className="group_img">
                      <img src={images["grup_img1.png"]} alt="" />
                    </div>

                    <div className="grp_name">
                      <h4>{value.name}</h4>
                      <h6>{value.member_count}</h6>
                    </div>
                  </div>
                </NavLink>
              );
            })
          ) : (
            <div className="group_not_found">
              <RecordNotfound />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Groups;
