import React from "react";

export const CreateChannelIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 11H20" stroke="white" stroke-width="3" stroke-linecap="round" />
    <path
      d="M11 2L11 20"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
);
