import React from "react";
import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { useState } from "react";
import validator from "validator";
import { asyncAskQuestion } from "../../actions/courseAction";
import { useParams } from "react-router-dom";
import { errorMsg, handleScrollError } from "../../actions/customFn";
import { addCompaignComment } from "../../actions/addCampaign";

const CampaignCommentModal = (props) => {

  const { id } = useParams();


  const [formErrors, setFormError] = useState({});
  const errors = {};

  const [formData, setFormData] = useState({
    comments: "",
  });

  const validateFormData = async () => {
    try {
      if (formData.comments === "") {
        errors.comments = "Please Enter Comment";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
  };

  const handelAddComment = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        addCompaignComment(id, formData, props.setCommentshow,props.setIsAddComment);
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    
    <Modal
      show={props.Commentshow}
      size="lg"
      centered
      className="main_popup_inner donate_popup_main Ask-Educator-Question"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <Modal.Title className="popup_title_name">
         Add Comment
        </Modal.Title>
      </Modal.Header>
      <div className="row main_popup_inner1 new-all-inputs">

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <TextField
            sx={{
              width: "100%",
            }}
            name="comments"
            error={formErrors.comments}
            className="input_username new-edit-main-all"
            id="outlined-basic"
            label="Comment"
            multiline
            rows={5}
            placeholder="Enter your text"
            onChange={handleChange}
            helperText={formErrors.comments}
          />
        </div>
      </div>

      <div className="popup_donate_btn">
        <button type="button" onClick={(e) => handelAddComment(e)}>
          Send
        </button>
      </div>
    </Modal>
  );
};

export default CampaignCommentModal;
