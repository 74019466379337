import React, { useEffect, useState } from "react";
import { FooterBig, Header, RecordNotfound } from "../components";
import { motion } from "framer-motion";
import CourseDetailVideo from "../components/ChapterDetails/CourseDetailVideo";
import CourseDetailsDescription from "../components/ChapterDetails/CourseDetailsDescription";
import CourseDetailsImages from "../components/ChapterDetails/CourseDetailsImages";
import { errorMsg, images } from "../actions/customFn";
import CourseChapterList from "../components/ChapterDetails/CourseChapterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import EducatorFeedback from "../components/Modal/EducatorFeedback";
import AskEducatorQuestionModal from "../components/Modal/AskEducatorQuestionModal";
import ReactStars from "react-rating-stars-component";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getCourseDetail } from "../actions/courseAction";
import Loader from "../hooks/Loader";
import { errorMessage } from "stream-chat-react/dist/components/AutoCompleteTextarea/utils";
import { courseImages } from "../utils/data";

const ChapterDetails = () => {
  const { id } = useParams();
  // const { CourseDetail,topCourses } = ;
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const [CourseDetail, setCourseDetail] = useState("");

  const defaultPlaylist = {
    image: "",
    media_type: "video",
    playlist_description: "",
    playlist_title: "",
    video: "",
  };

  const [playlist, setPlaylist] = useState({});

  const updatePlaylist = (newVideo) => {
    setPlaylist(newVideo);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // EducatorFeedback Modal
  const [Educatorshow, setEducatorShow] = useState(false);
  const handleEducatorClose = () => setEducatorShow(false);
  const handleEducatorShow = () => setEducatorShow(true);

  //Payment Form
  const [paymentModalshow, setpaymentModalShow] = useState(false);
  const handlepaymentClose = () => setpaymentModalShow(false);
  const handlepaymentShow = () => setpaymentModalShow(true);

  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  useEffect(() => {
    if (id != "") {
      setIsLoader(true);
      getCourseDetail(id, setCourseDetail, setIsLoader, setPlaylist, navigate);
    }
  }, [id]);

  const directpage = (path) => {
    navigate(`/${path}`);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <div className="all_page_bg courses_detail_bg_color">
          <div className="container-fluid">
            <Header />
            {isLoader == true ? (
              <Loader />
            ) : (
              <div className="page-all-data">
                {CourseDetail != "" ? (
                  <>
                    <div className="">
                      <div className="courses_detail_main">
                        <div className="row">
                          <div className="col-xl-9 col-lg-7 col-md-12 col-sm-12 ">
                            <CourseDetailVideo
                              playlist={playlist}
                              courseId={id}
                            />
                            <CourseDetailsDescription
                              description={
                                CourseDetail != undefined &&
                                CourseDetail.course_details != undefined &&
                                CourseDetail.course_details.description !=
                                  undefined
                                  ? CourseDetail.course_details.description
                                  : ""
                              }
                            />
                            <CourseDetailsImages
                              // course_media={
                              //   CourseDetail != undefined &&
                              //   CourseDetail.course_details != undefined &&
                              //   CourseDetail.course_details.course_media !=
                              //     undefined
                              //     ? CourseDetail.course_details.course_media
                              //     : ""
                              // }
                              course_media={courseImages}
                            />
                          </div>
                          <div className="col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12">
                            <CourseChapterList
                              updatePlaylist={updatePlaylist}
                              getAllPlayList={
                                CourseDetail.course_details?.course_playlist
                              }
                            />
                            <div className="Educator_new_section">
                              <div className="eductor-heading">
                                <h2>Educator</h2>
                              </div>

                              <div className="inner-grp-ed">
                                <div className="eductor-user-info">
                                  <div className="ed-user-profiler">
                                    {CourseDetail != undefined &&
                                      CourseDetail.course_details !=
                                        undefined &&
                                      CourseDetail.course_details
                                        .author_image != undefined && (
                                        <img
                                          src={
                                            CourseDetail.course_details
                                              .author_image
                                          }
                                          alt=""
                                        />
                                      )}
                                  </div>
                                  <div className="ed-user-main">
                                    {CourseDetail != undefined &&
                                      CourseDetail.course_details !=
                                        undefined &&
                                      CourseDetail.course_details.author_name !=
                                        undefined && (
                                        <h2>
                                          {
                                            CourseDetail.course_details
                                              .author_name
                                          }
                                        </h2>
                                      )}
                                    <p>university</p>
                                  </div>
                                </div>

                                <div className="ed-mid-button">
                                  <div className="btn-user-new">
                                    <button>
                                      <img
                                        src={images["user_btn.png"]}
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                  {CourseDetail != undefined &&
                                    CourseDetail.course_details != undefined &&
                                    CourseDetail.course_details
                                      .total_students != undefined && (
                                      <div className="ed-btn-like">
                                        <img
                                          src={images["ed-like.png"]}
                                          alt=""
                                        />{" "}
                                        <span>
                                          {
                                            CourseDetail.course_details
                                              .total_students
                                          }
                                        </span>
                                      </div>
                                    )}

                                  <div className="ed-star-rate course_rating_main">
                                    <div className="prd_star_rating ">
                                      {CourseDetail != undefined &&
                                        CourseDetail?.avg_course_retings !=
                                          undefined && (
                                          <ul>
                                            <li>
                                              <ReactStars
                                                {...reactRatings}
                                                size={14}
                                                value={
                                                  CourseDetail.avg_course_reting
                                                }
                                              />
                                            </li>
                                            <li className="star_point">
                                              {CourseDetail.avg_course_reting}
                                            </li>
                                          </ul>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div className="ed-both-btn">
                                  <div className="ask_card_btn">
                                    <button onClick={handleShow}>
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.0001 18.3334C5.39758 18.3334 1.66675 14.6026 1.66675 10.0001C1.66675 5.39758 5.39758 1.66675 10.0001 1.66675C14.6026 1.66675 18.3334 5.39758 18.3334 10.0001C18.3334 14.6026 14.6026 18.3334 10.0001 18.3334ZM10.0001 16.6667C11.7682 16.6667 13.4639 15.9644 14.7141 14.7141C15.9644 13.4639 16.6667 11.7682 16.6667 10.0001C16.6667 8.23197 15.9644 6.53628 14.7141 5.28604C13.4639 4.03579 11.7682 3.33341 10.0001 3.33341C8.23197 3.33341 6.53628 4.03579 5.28604 5.28604C4.03579 6.53628 3.33341 8.23197 3.33341 10.0001C3.33341 11.7682 4.03579 13.4639 5.28604 14.7141C6.53628 15.9644 8.23197 16.6667 10.0001 16.6667ZM9.16675 13.3334H10.8334V15.0001H9.16675V13.3334ZM9.16675 11.6734C9.16675 11.6734 10.8334 11.6667 10.8334 11.6734C10.8334 10.8384 13.3334 10.0001 13.3334 8.33341C13.3334 6.49175 11.8559 5.00008 10.0076 5.00008C9.56921 4.99909 9.13495 5.08459 8.72967 5.25166C8.32438 5.41873 7.95603 5.66411 7.64571 5.97374C7.33539 6.28336 7.08918 6.65116 6.9212 7.05607C6.75321 7.46098 6.66675 7.89504 6.66675 8.33341H8.33341C8.33341 7.41675 9.08341 6.66675 10.0001 6.66675C10.9167 6.66675 11.6667 7.41675 11.6667 8.33341C11.6667 9.08341 9.16675 10.3059 9.16675 11.6734Z"
                                            fill="#FF0098"
                                          />
                                        </svg>
                                      </span>
                                      Ask a Question
                                    </button>
                                  </div>

                                  <div className="Feedback-ed-btn">
                                    <button
                                      onClick={() => {
                                        handleEducatorShow();
                                      }}
                                      className="course_Feedback_btn2"
                                    >
                                      Educator Feedback
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="top-courses-bottom">
                              <div className="top_courses_heading">
                                <h1>Top Courses</h1>
                                <div className="Top_courses_main">
                                  {CourseDetail.top_courses?.map(function(
                                    value,
                                    index
                                  ) {
                                    return (
                                      <NavLink to="">
                                        <div
                                          className="Top_courses_main_inner"
                                          key={index}
                                        >
                                          <img
                                            src={
                                              value.image
                                                ? value.image
                                                : images["chapter_img3.png"]
                                            }
                                            alt=""
                                          />
                                          <div className="Top_main_inner_text">
                                            <h2>{value.title}</h2>
                                            <p>{value.sort_description}</p>

                                            <div className="course_rating_main_hesing">
                                              <div className="prd_star_rating ">
                                                <ul>
                                                  <li>
                                                    <ReactStars
                                                      {...reactRatings}
                                                      size={14}
                                                      value={Number(
                                                        value.rating
                                                      )}
                                                    />
                                                  </li>
                                                  <li className="star_point">
                                                    {value.rating}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </NavLink>
                                    );
                                  })}

                                  <div className="top_courses_btn">
                                    <button
                                      onClick={(e) => {
                                        directpage("courses");
                                      }}
                                    >
                                      Show More
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row"></div> */}
                    </div>

                    <EducatorFeedback
                      show={Educatorshow}
                      close={handleEducatorClose}
                    />

                    <AskEducatorQuestionModal
                      show={show}
                      setShow={setShow}
                      close={handleClose}
                    />
                  </>
                ) : (
                  <div>
                    <RecordNotfound />
                  </div>
                )}
              </div>
            )}
            <FooterBig />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ChapterDetails;
