import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";

import {
  Courses,
  Header,
  CourseList,
  UploadCourse,
  ScoreBoard,
  FooterBig,
  FooterMobile,
} from "../components";
import { motion } from "framer-motion";
import { asyncgetMyCourse } from "../actions/courseAction";
import Loader from "../hooks/Loader";

const CreateCourse = () => {
  const [mycourses, setMyCourses] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const [loadmycourse, setLoadMycourse] = useState(false);

  useEffect(() => {
    setIsLoad(true);
    asyncgetMyCourse(setMyCourses, setIsLoad);
  }, [loadmycourse]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="all_page_bg">
          <div className="container-fluid">
            <Header />
            {isLoad == true ? (
              <Loader />
            ) : (
              <div className="page-all-data profile_main my-course-page">
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <ScoreBoard />
                    </div>

                    <Courses mycourses={mycourses} />

                    <div className="col-md-3">
                      <UploadCourse />
                    </div>
                  </div>
                  {mycourses != undefined && mycourses.length > 0 && (
                    <div className="bottom-part">
                      <CourseList
                        mycourses={mycourses}
                        setLoadMycourse={setLoadMycourse}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <FooterBig />
            <div className="mobile-footer-new">
              <FooterMobile />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default CreateCourse;
