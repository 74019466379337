import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";

const YourGiftSlidre = (props) => {
  const bannerdetails = props.banner_slider;
  const modaldata = props.modal_show;
  const setDonateId = props.setDonateId;

  const Show_modal = (id) => {
    setDonateId(id);
    modaldata(true);
  };

  const experience = {
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  return (
    <div className="donate_slider_top">
      <OwlCarousel
        {...experience}
        className="owl-carousel owl-theme slider_main"
        margin={10}
      >
        {bannerdetails.map(function(value, index) {
          return (
            <div className="item" key={index}>
              <div
                className="ExperienceSlider_main"
                style={{
                  backgroundImage: `url(${value.image})`,
                }}
              >
                <div className="Experience_Text">
                  <div className="overlay">
                    <h1>{value.title}</h1>
                    <h2
                      dangerouslySetInnerHTML={{ __html: value.description }}
                    ></h2>
                    <NavLink
                      className="donate_btn"
                      variant="primary"
                      onClick={() => Show_modal(value.id)}
                    >
                      Donate Now
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>

      {/* {<DonatenowPopup show={show} close={handleClose} data={bannerdetails} />} */}
    </div>
  );
};

export default YourGiftSlidre;
