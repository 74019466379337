import React, { useEffect, useRef, useState } from "react";
import {
  errorMsg,
  handleScrollError,
  images,
  validateField,
} from "../../actions/customFn";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import SmallLoader from "react-js-loader";
import { asyncUploadBlog } from "../../actions/uploadBlogs";
// import validator from "validator";

const UploadPostModal = (props) => {
  const { setShow, setIsLoader, setUploadLoad, isRenderState } = props;

  const inputRef = useRef(null);

  const [editorType, setEditorType] = useState("");
  const [formErrors, setFormError] = useState({});
  const errors = {};
  // const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
  });

  const [buttonloader, setButtonLoader] = useState(false);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      if (name === "image") {
        setFormData((formData) => ({ ...formData, [name]: e.target.files[0] }));
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    } else {
      if (editorType != "") {
        setFormData((formData) => ({ ...formData, [editorType]: e }));
      }
    }
  };

  const validateFormData = async () => {
    try {
      if (formData.title === "") {
        errors.title = "Enter post title properly ";
      }
      if (formData.description === "") {
        errors.description = "Enter description";
      }
      // if (formData.description == "<p><br></p>") {
      //   errors.description = "Enter description";
      // }
      if (formData.image === "" || formData.image === undefined) {
        errors.image = "Please choose an image";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadPost = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        asyncUploadBlog(
          formData,
          setShow,
          setUploadLoad,
          setButtonLoader,
          isRenderState
        );
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };
  // const refreshPage =()=>{
  //   setTogglePage(!togglepage);
  // }

  const [preview, setpreview] = useState(images["upload-post1.png"]);

  const fileChangedHandler = (event) => {
    if (event.target.files[0].type == "video/mp4") {
      setpreview(images["upload-reel1.png"]);
    } else {
      setpreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <Modal
      show={props.show}
      className="upload_post_popup post-upload-modal"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <h4>Post Upload</h4>
      </Modal.Header>

      <div className="uploadpost_cont new-all-inputs">
        <div className="post_image_upload">
          <img src={preview} alt="" />
          <h6>Upload Image and Video</h6>

          <div className="file_upload_product">
            <input
              style={{ display: "none" }}
              id="fileInput"
              name="image"
              type="file"
              inputProps={{ accept: "image/*" }}
              onChange={(e) => {
                handleChange(e);
                fileChangedHandler(e);
              }}
              placeholder="browser"
              // multiple
              ref={inputRef}
            />

            <button
              onClick={() => {
                handleClick();
              }}
            >
              Browse files
            </button>
          </div>
          {formErrors.image && (
            <div className="image_error">
              <p> {formErrors.image} </p>
            </div>
          )}
        </div>

        <div className=" main_popup_inner1 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Post Title
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.title}
            name="title"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="Enter your title"
            onChange={handleChange}
            helperText={formErrors.title}
          />
        </div>
        <div className="main_popup_inner1">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Description
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.description}
            name="description"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="Enter text"
            onChange={handleChange}
            multiline
            rows={4}
            helperText={formErrors.description}
          />
        </div>
        {/* <div className="account_txteditor">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Description
          </FormHelperText>
          <div>
            <div
              style={{
                // display: "grid",
                color: "#fff",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <ReactQuill
                theme="snow"
                // error={formErrors.description}
                modules={props.modules}
                formats={props.formats}
                placeholder=""
                onFocus={(e) => {
                  setEditorType("description");
                }}
                onChange={handleChange}
                style={{ height: "80px", color: "#fff" }}
              ></ReactQuill>
            </div>
          </div>
        </div>
        {formErrors.description && (
          <div className="invalid-msg">{formErrors.description}</div>
        )} */}
        <div className="upload_btn_last">
          {buttonloader == true ? (
            <button type="button">
              <SmallLoader
                type="spinner-default"
                bgColor={"#fff"}
                color={"#fff"}
                size={40}
              />
            </button>
          ) : (
            <button
              onClick={(e) => {
                handleUploadPost(e);
              }}
            >
              Upload{" "}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadPostModal;
