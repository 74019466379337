import { configureStore } from '@reduxjs/toolkit'
import authSlice from './redux/authSlice'
import countrySlice from './redux/countrySlice'
import friendSlice from './redux/friendSlice'
import photosSlice from './redux/photosSlice'
import chatSlice from './redux/chatSlice'

const store = configureStore({
  reducer: {
    userAuth   : authSlice,
    countryData: countrySlice,
    friendData : friendSlice,
    photosData : photosSlice,
    chatData   : chatSlice,
  }
})
export default store