import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";

const Mountain = (props) => {
  const slider_list = props.data;
  const modaldata = props.modal_show;
  const setDonateId = props.setDonateId;
  const Show_modal = (id) => {
    setDonateId(id);
    modaldata(true);
  };

  console.log("setDonateId=", setDonateId);

  const navigate = useNavigate();
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const slider = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="mountain_box_main">
        <OwlCarousel
          {...slider}
          className="owl-theme slider_main_mountain"
          margin={10}
        >
          {slider_list.map(function(value, index) {
            return (
              <div className="slider_post" key={index}>
                <div className="item">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div
                        className="mountain_img"
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}
                      >
                        {/* <img src={value.image} alt="" /> */}
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="mountain_right_box">
                        <div className="mountain_heading">
                          <h2>{value.title}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: value.sort_description,
                            }}
                          ></p>
                        </div>

                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 ">
                            <div className="mountain_card">
                              <h4>Target</h4>
                              <p>
                                <span>$</span>
                                {value.target}
                              </p>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 ">
                            <div className="mountain_card">
                              <h4>Current</h4>
                              <p>
                                <span>$</span>
                                {value.current_donate}
                              </p>
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 ">
                            <div className="mountain_card">
                              <h4>Donors</h4>
                              <p>{value.donors}</p>
                            </div>
                          </div>
                        </div>

                        <div className="progress_bar_mountain">
                          <ProgressBar
                            now={value.percentage}
                            label={`${value.percentage}%`}
                          />
                        </div>

                        <div className="mountain_btn_main">
                          <div className="mountain_btn_main_btn">
                            <button
                              onClick={() => {
                                navigate(`/campaign-detail/${value.id}`);
                              }}
                            >
                              View details
                            </button>
                          </div>

                          <div className="mountain_button">
                            <button onClick={() => Show_modal(value.id)}>
                              Donate Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>

        {/* {<DonatenowPopup show={show} close={handleClose} />} */}
      </div>
    </>
  );
};

export default Mountain;
