import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

export const asynccheckout = async (
  formdata,
  navigation,
  token,
  setButtonLoad,
  is_reward_point_apply
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let formData = {
      customer_id: user_id,
      password: user_password,
      first_name: formdata.first_name,
      last_name: formdata.last_name,
      country: formdata.country,
      state: formdata.state,
      city: formdata.city,
      postal_code: formdata.postal_code,
      phone_number: formdata.phone_number,
      email: formdata.email,
      additional_info: formdata.additional_info,
      token: token,
    };
    if (is_reward_point_apply == true) {
      formData.is_reward_point_apply = is_reward_point_apply;
    }
    const url = "/checkout";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        successMsg(response.data.message);
        navigation("/buy_successfull/product");
      } else {
        errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setButtonLoad(false);
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          errorMsg("Somthing went wrong...");
          setTimeout(() => {
            setButtonLoad(false);
          });
        } else {
          console.log(`${error.message}-${error.response.status}`);
          setTimeout(() => {
            setButtonLoad(false);
          });
        }
      }
    }
  }
};

// -------------- My Orders ------------------
export const asycGetMyOrders = async (setState, setLoadPage, searchorder) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    if (searchorder != undefined) {
      if (searchorder.productname != "") {
        sendformData.append("product_name", searchorder.productname);
      }
      if (searchorder.product_category != "") {
        sendformData.append("category", searchorder.product_category);
      }
      if (searchorder.order_date != "") {
        sendformData.append("date", searchorder.order_date);
      }
    }

    const url = "/my_orders";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
        // errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// -------------- purchase_history ------------------
export const asycOrderHistory = async (setState, setLoadPage) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    const url = "/purchase_history";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        setState({
          products: response.data.products,
          courses: response.data.courses,
          nft: [],
        });
      } else {
        // errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------- course order history ------------------
export const asycCourseOrderHistory = async (
  setState,
  setLoadPage,
  searchcourse,
  currentPage,
  setPagecount
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    if (searchcourse != undefined) {
      if (searchcourse.coursename != "") {
        sendformData.append("course_name", searchcourse.coursename);
      }
      if (searchcourse.course_orderid != "") {
        sendformData.append("order_id", searchcourse.course_orderid);
      }
      if (searchcourse.courseorder_date != "") {
        sendformData.append("date", searchcourse.courseorder_date);
      }

      // if (
      //   searchcourse.coursename != undefined ||
      //   searchcourse.course_orderid != undefined ||
      //   searchcourse.courseorder_date != undefined
      // ) {
      //   sendformData.append("offset", 0);
      // } else {
        // sendformData.append("offset", currentPage);
      // }
    }
    
    sendformData.append("offset", currentPage);
    const url = "/course_orders";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        setState(response.data);
        setPagecount(response.data.page_count);
      } else {
        // errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------ product order history --------------
export const asycProductOrderHistory = async (
  setState,
  setLoadPage,
  searchproduct,
  currentPage,
  setPagecount
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    if (searchproduct != undefined) {
      if (searchproduct.product_name != "") {
        sendformData.append("product_name", searchproduct.product_name);
      }
      if (searchproduct.product_category != "") {
        sendformData.append("category", searchproduct.product_category);
      }

      // if (
      //   searchproduct.product_name != undefined ||
      //   searchproduct.product_category != undefined
      // ) {
      //   sendformData.append("offset", 0);
      // } else {
      // sendformData.append("offset", currentPage);
      // }
    }
    sendformData.append("offset", currentPage);
    const url = "/product_orders";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
        setPagecount(response.data.page_count);
      } else {
        // errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
  } catch (error) {
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
