import React from "react";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const NftFilter = () => {
  return (
    <div className="page_left_side_main filter_desktop_main">
      <div className="page_left_side_main_hediing ">
        <h1>Filter</h1>
      </div>

      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Status</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* <div> */}
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Buy Now" />
                <FormControlLabel control={<Checkbox />} label="On Auction" />
              </FormGroup>
              {/* </div> */}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Price</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Beginner" />
                <FormControlLabel control={<Checkbox />} label="Intermediate" />
                <FormControlLabel control={<Checkbox />} label="advanced" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Quantity</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="New" />
                <FormControlLabel control={<Checkbox />} label="Trending" />
                <FormControlLabel control={<Checkbox />} label="Highly Rated" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Currency</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Required" />
                <FormControlLabel control={<Checkbox />} label="Required" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Background</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Required" />
                <FormControlLabel control={<Checkbox />} label="Required" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Clothes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <FormGroup className="nft-filter-sides">
                <FormControlLabel control={<Checkbox />} label="Required" />
                <FormControlLabel control={<Checkbox />} label="Required" />
              </FormGroup>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default NftFilter;
