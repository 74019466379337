import React from "react";
import { errorMsg, images } from "../../actions/customFn";
import { useState } from "react";
import { useEffect } from "react";
import {
  asyncUnfreind,
  asyncallfriend,
  getAllFriendList,
} from "../../actions/friendAction";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { asyncCreateChannel } from "../../actions/chatAction";
import { useNavigate } from "react-router-dom";

const AllFriends = (props) => {
  const { isSearch, handleChat, handleUnfriend } = props;
  // const { all_friends, loading } = useSelector((state) => state.friendData);
  const dispatch = useDispatch();
  const [allfriends, setallfriends] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  // Accept Friend Request

  useEffect(() => {
    // dispatch(getAllFriendList({ isSearch }));
    setloading(true);
    asyncallfriend(setallfriends, setloading, isSearch);
  }, [isSearch]);

  return (
    <>
      {loading == true ? (
        <Loader />
      ) : (
        <div className="row m-0">
          {allfriends != undefined && allfriends.length > 0 ? (
            <>
              {" "}
              {allfriends.map((value, index) => {
                return (
                  <div
                    className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 "
                    key={index}
                  >
                    <div className="friends_card_inner new">
                      <div className="tab-info-dis">
                        <div className="user-img-tab">
                          <img src={value.image} alt="" />
                        </div>
                        <div className="user-text-info">
                          <h1>{value.username}</h1>
                          <div className="chat-design">
                            <p>( {value.total_freinds} ) friend</p>
                            <div className="chat_unfrind_btn">
                              <button
                                className="chat_btn_main"
                                onClick={() => {
                                  handleChat(value.customer_id);
                                }}
                              >
                                Chat
                              </button>
                              <button
                                className="unfriend_btn_main"
                                onClick={(e) => {
                                  handleUnfriend(
                                    value.customer_id,
                                    allfriends,
                                    setallfriends,
                                    "",
                                    dispatch
                                  );
                                }}
                              >
                                Unfriend
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="friends-record">
              <RecordNotfound />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllFriends;
