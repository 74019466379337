import React from "react";
import { errorMsg, images } from "../../actions/customFn";
import { useState } from "react";
import { useEffect } from "react";
import {
  asyncAcceptRequest,
  asyncRejectRequest,
  asyncfriendrequest,
} from "../../actions/friendAction";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import { useDispatch } from "react-redux";

const Request = (props) => {
  const {
    setIsSearch,
    isSearch,
    handleacceptfriend,
    handlerejectfriend,
  } = props;
  const dispatch = useDispatch();
  const [reRender, setRerender] = useState(false);
  const [FriendRequests, setFriendRequests] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    setIsLoader(true);
    asyncfriendrequest(setFriendRequests, setIsLoader, isSearch);
  }, [reRender, isSearch]);

  return (
    <>
      {isLoader == true ? (
        <Loader />
      ) : (
        <div className="row m-0">
          {FriendRequests != undefined && FriendRequests.length > 0 ? (
            <>
              {" "}
              {FriendRequests.map((value, index) => {
                return (
                  <div
                    className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12"
                    key={index}
                  >
                    <div className="friends_card_inner new">
                      <div className="tab-info-dis">
                        <div className="user-img-tab">
                          <img src={value.image} alt="" />
                        </div>
                        <div className="user-text-info">
                          <h1>{value.username}</h1>
                          <div className="chat-design">
                            <p>( {value.total_likes} ) friend</p>
                            <div className="chat_unfrind_btn">
                              <button
                                className="chat_btn_main"
                                onClick={() =>
                                  handleacceptfriend(
                                    value.customer_id,
                                    FriendRequests,
                                    setFriendRequests,
                                    dispatch,
                                    index,
                                    ""
                                  )
                                }
                              >
                                Accept
                              </button>
                              <button
                                className="unfriend_btn_main"
                                onClick={() =>
                                  handlerejectfriend(
                                    value.customer_id,
                                    FriendRequests,
                                    setFriendRequests,
                                    dispatch,
                                    index,
                                    ""
                                  )
                                }
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="friends-record">
              <RecordNotfound />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Request;
