import React, { useState, useffect } from "react";
import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { images } from "../../actions/customFn";
import { useRef } from "react";

const EducatorFeesbackModal = (props) => {
  // const handleShow = () => setShow(true);

  const inputRef = useRef(null);

  const handleProcedureContentChange = (content) => {};

  const [file, setFile] = useState(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const fileChangedHandler = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = function(e) {
      setFile(e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <div className="new-modal-Campaigns">
        <Modal
          show={props.show}
          className="main_popup_inner educator_feedback"
          onHide={props.close}
        >
          <FontAwesomeIcon onClick={props.close} icon={faTimes} />

          <Modal.Header>
            <Modal.Title className="popup_title_name">Feedback</Modal.Title>
          </Modal.Header>
          <h1>Rate Your Experience</h1>
          <p>Are you Satisfied with the service</p>

          <div className="feedback_star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.11189 12L3.60839 7.41346L0 4.91827H4.41958L6 0L7.60839 4.91827H12L8.39161 7.41346L9.91608 12L6 9.07212L2.11189 12Z"
                fill="#97999E"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.11189 12L3.60839 7.41346L0 4.91827H4.41958L6 0L7.60839 4.91827H12L8.39161 7.41346L9.91608 12L6 9.07212L2.11189 12Z"
                fill="#97999E"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.11189 12L3.60839 7.41346L0 4.91827H4.41958L6 0L7.60839 4.91827H12L8.39161 7.41346L9.91608 12L6 9.07212L2.11189 12Z"
                fill="#97999E"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.11189 12L3.60839 7.41346L0 4.91827H4.41958L6 0L7.60839 4.91827H12L8.39161 7.41346L9.91608 12L6 9.07212L2.11189 12Z"
                fill="#97999E"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.11189 12L3.60839 7.41346L0 4.91827H4.41958L6 0L7.60839 4.91827H12L8.39161 7.41346L9.91608 12L6 9.07212L2.11189 12Z"
                fill="#97999E"
              />
            </svg>
            <span>0.0</span>
          </div>

          <div className="new-all-inputs">
            <TextField
              sx={{
                width: "100%",
              }}
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="Feedback"
              rows={8}
              multiline
              placeholder="Enter text"
            />
          </div>

          <div className="feedbackmodal_btn">
            <div className="cancel">
              <button>Cancel</button>
            </div>

            <div className="submit">
              <button>Submit</button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EducatorFeesbackModal;
