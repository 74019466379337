import React, { useState } from "react";
import { errorMsg, images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { NavLink } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useParams } from "react-router-dom/dist";
import { asyncCourseWishlist } from "../../actions/courseAction";

const CoursesDetailBanner = (props) => {
  const bannerdetails = props.bannerdetails;

  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };
  const { id } = useParams();
  const [addwishlist, setAddWishlist] = useState(
    bannerdetails?.course_wishlist
  );

  const handlecoursewishlist = async () => {
    setAddWishlist(!addwishlist);
    try {
      asyncCourseWishlist(id, addwishlist, setAddWishlist);
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  const objectStyle = {
    backgroundColor: "#ff0098",
  };
  const objectStyleValid = {
    backgroundColor: "transparent",
  };

  return (
    <div
      className="courses_detail_banner new_banner_image"
      style={{
        backgroundImage: `url(${bannerdetails.image})`,
      }}
    >
      <div className="courses_detail_overlay position-relative">
        <button
          className="Add_wishlist_btn p-2 position-absolute top-0 right-0 m-2"
          onClick={() => {
            handlecoursewishlist();
          }}
          style={addwishlist == true ? objectStyle : objectStyleValid}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_407_637)">
                <path
                  d="M14.5832 1.59741C13.6448 1.61201 12.7269 1.87396 11.9222 2.3568C11.1174 2.83965 10.4544 3.5263 9.99988 4.34741C9.54542 3.5263 8.88233 2.83965 8.07758 2.3568C7.27283 1.87396 6.35493 1.61201 5.41655 1.59741C3.92067 1.6624 2.51131 2.31679 1.49637 3.41761C0.481433 4.51842 -0.0565749 5.97618 -0.000116138 7.47241C-0.000116138 11.2616 3.98822 15.3999 7.33322 18.2057C8.08007 18.8334 9.02435 19.1774 9.99988 19.1774C10.9754 19.1774 11.9197 18.8334 12.6665 18.2057C16.0115 15.3999 19.9999 11.2616 19.9999 7.47241C20.0563 5.97618 19.5183 4.51842 18.5034 3.41761C17.4885 2.31679 16.0791 1.6624 14.5832 1.59741Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_407_637">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </button>
        <div className="latestblogs_right_profile1_main">
          <div className="courses_banner_profile">
            <div className="latestblogs_right_profile1_left">
              <div className="right_profile_left_bg">
                <img src={bannerdetails.author_image} alt="" />
              </div>
              <span>{bannerdetails.author_name}</span>
            </div>

            {bannerdetails.total_students !== undefined && (
              <div className="coures_total_student">
                <img src={images["coures_school_icon.png"]} alt="" />
                <div className="coures_total_student_text">
                  ({bannerdetails.total_students}) <span> Students</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <h1>{bannerdetails.title}</h1>
        <p>{bannerdetails.sort_description}</p>

        <div className="courses_detail_rating">
          <div className="prd_star_rating ">
            <ul>
              {/* <li> */}
              <ReactStars
                {...reactRatings}
                size={14}
                value={Number(bannerdetails.rating)}
              />
              {/* </li> */}
              <li className="star_point">{bannerdetails.rating}</li>
            </ul>
          </div>

          {/* <h3>13 Lectures</h3> */}
        </div>
      </div>
    </div>
  );
};

export default CoursesDetailBanner;
