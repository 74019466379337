import { createSlice } from "@reduxjs/toolkit";
import { getGroupList } from "../actions/chatAction";



const initialState = {
  loading    : false,
  groups_list: [],
  error      : null,
  success    : false,
};

const chatSlice = createSlice({
  name: "group_list",
  initialState,
  reducers: {},
  extraReducers: {
    // Friends List
    [getGroupList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGroupList.fulfilled]: (state, { payload }) => {
      state.loading     = false;
      state.groups_list = payload;
    },
    [getGroupList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});


export default chatSlice.reducer;
