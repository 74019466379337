import React from "react";
import { images } from "../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { reactIcons } from "../utils/icons";

const WishListItems = (props) => {
  const { value, wishlistID, handleDelete } = props;
  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  const navigate = useNavigate();

  const handleRoute = (url) => {
    navigate(url);
  };
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 courses_card">
        <div className="inner_list_main">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              {value.image === "" || value.image === undefined ? (
                <div
                  onClick={() => handleRoute(`/courses-detail/${value.id}`)}
                  className="product_img "
                  style={{
                    backgroundImage: `url(${images["timepost8.png"]})`,
                  }}
                ></div>
              ) : (
                <div
                  className="product_img "
                  onClick={() => handleRoute(`/courses-detail/${value.id}`)}
                  style={{
                    backgroundImage: `url(${value.image})`,
                  }}
                ></div>
              )}
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 ">
              <div className="wish_lsit_info">
                <div className="latestblogs_right_main_div">
                  <div className="latestblogs_right_profile1">
                    <div className="right_profile_left_bg">
                      <img src={value.author_image} alt="" />
                    </div>
                    <div>
                      <span>{value.author_name}</span>
                      <div className="coures_total_student">
                        <img src={images["coures_school_icon.png"]} alt="" />
                        <div className="coures_total_student_text">
                          (105) <span> Students</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wish_lsit_info_top_btn">
                    {value.tag_title !== undefined && (
                      <button>
                        {value.tag_title ? value.tag_title : "3D Animation"}{" "}
                      </button>
                    )}
                    <button
                      className="delete-btn"
                      onClick={() => {
                        handleDelete(wishlistID);
                      }}
                    >
                      {reactIcons.delete}
                    </button>
                  </div>
                </div>

                <h2>{value.title}</h2>
                <h6
                  dangerouslySetInnerHTML={{
                    __html: value.description,
                  }}
                ></h6>

                <div className="prd_star_rating d-flex align-items-end justify-content-between">
                  <div className="new_btn_all course-buy-btn">
                    <button
                      onClick={() => {
                        navigate(`/courses-detail/${value.course_id}`);
                      }}
                    >
                      Buy now
                    </button>
                  </div>
                  <ul>
                    <li className="star_point">
                      <ReactStars
                        {...reactRatings}
                        size={16}
                        value={Number(value.rating)}
                      />
                    </li>
                    <li className="star_point">{value.rating}</li>
                  </ul>
                  <div className="div_last_main w-auto">
                    <span className="lecture-span">25 Lectures</span>
                    <div className="product_price">
                      <div className="price_main_tex">
                        <p>
                          <span>$</span>
                          {value.price}
                        </p>
                        {/* <h3 className="per_person">13 Lectures</h3> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishListItems;
