import React, { useState, useEffect } from "react";
import {
  FilterAll,
  FooterBig,
  FooterMobile,
  Header,
  MobileFilter,
  ProductSearch,
  RecordNotfound,
  SearchInput,
} from "../components";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfiniteScroll from "react-infinite-scroll-component";

import { images } from "../actions/customFn";

import { NavLink } from "react-router-dom";

// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ProductFilteMobile from "../components/ProductListing/ProductFilteMobile";
import { motion } from "framer-motion";
import {
  asyncAllProductList,
  getProductCategory,
} from "../actions/productAction";
import Loader from "../hooks/Loader";

const ProductListing = () => {
  const [isDataLoad, setIsDataLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [page_count, setPagecount] = useState(0);

  const [searchproduct, setSearchProduct] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const [searchForm, setSearchForm] = useState({
    min_price: "",
    max_price: "",
    category_name: "",
  });

  useEffect(() => {
    setTimeout(function() {
      setIsDataLoading(false);
    }, 3000);
  }, [isDataLoad]);

  

  

  const refresh = (setItems) => {
    setCurrentPage(0);
  };

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 1000);
  };

  // ----------- product category ------------
  const [productcategory, setProductCategory] = useState();
  const [Selectchecked, setSelectchecked] = useState([]);

  useEffect(() => {
    getProductCategory(setProductCategory);
  }, []);

  const handleCheckCategory = (e, id) => {
    if (e.target.checked === true) {
      setSelectchecked([...Selectchecked, id]);
      // console.log("Selectchecked=====",e);
    } else {
      const result = Selectchecked.filter((e) => e !== id);
      setSelectchecked(result);
    }
  };

  const [minprice, setMinPRice] = useState();
  const [maxprice, setMaxPrice] = useState();

  

  const applySearchproduct = (e) => {
    if (minprice != "" || maxprice != "" || Selectchecked != "") {
      setCurrentPage(0);
      setSearchForm({
        ...searchForm,
        min_price: minprice,
        max_price: maxprice,
        category_name: Selectchecked,
      });
    }
  };
  // console.log("searchForm=", searchForm); // -------------------------


  useEffect(() => {
    if (currentPage == 0) {
      setIsLoader(true);
    }
    asyncAllProductList(
      setProductList,
      setIsDataLoading,
      setPagecount,
      currentPage,
      setIsLoader,
      setHasMore,
      searchForm,
      searchproduct
    );
  }, [currentPage, searchForm, searchproduct]);
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {/* {console.log("currentPage=", currentPage)} */}
      <div className="all_page_bg nft-main new-inner-pages">
        <Header />
        {/* {console.log("productList=", productList)} */}
        <div className="page-all-data list_margins">
          <div className="page-container">
            <div className="paga-data-dis">
              <div className="page_left_side_main">
                <div className="page_left_product_heding ">
                  <h1>Filter by Products</h1>
                  <p></p>
                </div>

                <div className="filter_desktop_main">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Category</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {/* <div> */}
                        <FormGroup className="nft-filter-sides">
                          {productcategory != undefined &&
                            productcategory.length > 0 &&
                            productcategory.map((value, index) => {
                              return (
                                <div className="all-input-filter">
                                  {/* <FormControlLabel control={<Checkbox />} label={value.title} /> */}
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleCheckCategory(e, value.id)
                                    }
                                    id={"category" + index}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"category" + index}
                                  >
                                    {value.name}
                                  </label>
                                </div>
                              );
                            })}

                          {/* <FormControlLabel
                            control={<Checkbox />}
                            label="Game Toys"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Game Accessories"
                          /> */}

                          <div className="rang_heading">
                            <h3>Price</h3>

                            <div className="price_rang">
                              <div className="minimum_rang">
                                <input
                                  type="number"
                                  name="min_price"
                                  placeholder="Minimum"
                                  onChange={(e) => setMinPRice(e.target.value)}
                                />
                              </div>
                              <div className="minimum_rang">
                                <input
                                  type="number"
                                  name="max_price"
                                  placeholder="Maximum"
                                  onChange={(e) => setMaxPrice(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="apply_prodsearch">
                            <button
                              type="button"
                              onClick={(e) => applySearchproduct(e)}
                            >
                              Apply
                            </button>
                          </div>
                        </FormGroup>
                        {/* </div> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Currency</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormGroup className="nft-filter-sides">
                          <FormControlLabel
                            control={<Checkbox />}
                            label="New"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Trending"
                          />
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Highly Rated"
                          />
                        </FormGroup>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
{console.log("isLoader",isLoader)}
              <div className="page_data_mid_new">
                <ProductSearch setSearchProduct={setSearchProduct} setCurrentPage={setCurrentPage}/>
                <div className="product_listing_section">
                  {isLoader == true ? (
                    <Loader />
                  ) : (
                    <>
                      {productList != undefined && productList.length > 0 ? (
                        <div
                          className="product_listing_data"
                          id="scrollableDiv"
                        >
                          <InfiniteScroll
                            dataLength={productList.length}
                            next={handleLoadMore}
                            hasMore={hasMore}
                            loader={<Loader />}
                            scrollableTarget="scrollableDiv"
                            refreshFunction={refresh}
                            endMessage={
                              <p
                                className="end-message-loader"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                            pullDownToRefresh
                            pullDownToRefreshThreshold={50}
                            pullDownToRefreshContent={
                              <h3 style={{ textAlign: "center" }}>
                                Pull down to refresh
                              </h3>
                            }
                            releaseToRefreshContent={
                              <h3 style={{ textAlign: "center" }}>
                                Release to refresh
                              </h3>
                            }
                          >
                            <div className="row">
                              {productList.map((value, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 "
                                      key={index}
                                    >
                                      <NavLink
                                        to={`/product-details/${value.id}`}
                                      >
                                        <div className="product_inner productlist_img">
                                          <div className="card_img">
                                            {value.product_image != undefined &&
                                            value.product_image.length > 0 ? (
                                              <img
                                                src={
                                                  value.product_image[0]
                                                    .image != ""
                                                    ? value.product_image[0]
                                                        .image
                                                    : images[
                                                        "product_listing3.png"
                                                      ]
                                                }
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  images["product_listing3.png"]
                                                }
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="product_text">
                                            <div className="product_inner_hedding">
                                              <h2>{value.name}</h2>
                                              <h3>{value.category}</h3>
                                            </div>
                                            <div className="product_number">
                                              <h4>
                                                <span>$</span> {value.price}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </NavLink>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </InfiniteScroll>
                        </div>
                      ) : (
                        <div className="product_listing_data justify-content-center text-light">
                          <RecordNotfound />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="filter_mobile">
                  <ProductFilteMobile productcategory={productcategory} handleCheckCategory={handleCheckCategory} setMinPRice={setMinPRice} setMaxPrice={setMaxPrice} applySearchproduct={applySearchproduct} />
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-footer-new">
            <FooterMobile />
          </div>
        </div>

        <FooterBig />
      </div>
    </motion.div>
  );
};

export default ProductListing;
