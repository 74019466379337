import React from "react";
import { images } from "../actions/customFn";
import {
  AddCampaigns,
  CampaignsCard,
  ClimbSlider,
  CopyrightFooter,
  DonateCard,
  DonatenowPopup,
  FooterBig,
  FooterMobile,
  Header,
  Mountain,
  YourGiftSlidre,
} from "../components";
import { motion } from "framer-motion";
import { TextField, IconButton, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { asyncGetDonatePagesData } from "../actions/donatepageAction";
import Loader from "../hooks/Loader";
import { useNavigate } from "react-router-dom";

const Donate = () => {
  const navigate = useNavigate();
  const [searchCampaign, setSearchCampagin] = useState(); //  -----as31 ----

  const [DonateDetails, setDonateDetails] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  // Modal
  const [show, setShow] = useState(false);
  const [dontateID, setDonateId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    setIsLoader(true);
    asyncGetDonatePagesData(setDonateDetails, setIsLoader);
  }, []);

  const searchItem = (e, option) => {
    const { id } = option;

    setTimeout(() => {
      navigate(`/campaign-detail/${id}`);
    }, 1000);
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg donate-page">
        <div className="container-fluid">
          <Header />

          {isLoader == true ? (
            <Loader />
          ) : (
            <div className="page-all-data">
              <div>
                <div className="donate_slider_top">
                  {DonateDetails.campaign_slider !== undefined &&
                    DonateDetails.campaign_slider.length > 0 && (
                      <div className="donate_search">
                        <div className="input_search_center ">
                          <div className="input_search_new">
                            <Autocomplete
                              open={
                                searchCampaign != undefined &&
                                searchCampaign.length >= 3
                              }
                              options={DonateDetails.campaign_target}
                              // value="Search by name or attribute"
                              getOptionLabel={(option) => option.title}
                              onChange={(e, option) => searchItem(e, option)}
                              className="search-all"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) =>
                                    setSearchCampagin(e.target.value)
                                  }
                                  placeholder="Search Campaign"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <IconButton
                                        type="submit"
                                        aria-label="search"
                                      >
                                        <SearchIcon />
                                      </IconButton>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {DonateDetails.campaign_slider !== undefined &&
                    DonateDetails.campaign_slider.length > 0 && (
                      <YourGiftSlidre
                        banner_slider={DonateDetails.campaign_slider}
                        modal_show={handleShow}
                        setDonateId={setDonateId}
                      />
                    )}

                  <AddCampaigns
                    data={DonateDetails.add_compaign_description}
                    title={DonateDetails.campaign_title}
                    setIsLoader={setIsLoader}
                  />

                  {DonateDetails.campaigns_types !== undefined &&
                    DonateDetails.campaigns_types.length > 0 && (
                      <DonateCard data={DonateDetails.campaigns_types} />
                    )}

                  {DonateDetails.campaign_target !== undefined &&
                    DonateDetails.campaign_target.length > 0 && (
                      <Mountain
                        data={DonateDetails.campaign_target}
                        modal_show={handleShow}
                        setDonateId={setDonateId}
                      />
                    )}
                </div>
              </div>
              {/* <ClimbSlider /> */}
              <div>
                <div className="donate_slider_top">
                  <div className="campaigns_card_new">
                    {DonateDetails.featured_campaigns !== undefined &&
                      DonateDetails.featured_campaigns.length > 0 && (
                        <CampaignsCard
                          data={DonateDetails.featured_campaigns}
                        />
                      )}
                  </div>
                </div>
              </div>

              {/* <CopyrightFooter /> */}
              <FooterBig />
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
          )}
        </div>
      </div>

      <DonatenowPopup
        show={show}
        close={handleClose}
        Donate_data={dontateID}
        setShow={setShow}
      />
    </motion.div>
  );
};

export default Donate;
