import React, { useState } from "react";
import { images } from "../../actions/customFn";

const CourseChapterList = (props) => {
  const { updatePlaylist, getAllPlayList } = props;

  const [activeItem, setActiveItem] = useState(null);

  const handleActive = (value) => {
    setActiveItem(value);
  };

  return (
    // className="course_chapterscroll  "
    <>
      <div className="course_chapter_section_main">
        <div className="course_chapter_section">
          <h5>Chapter List</h5>
        </div>
        <div
          className={` ${
            getAllPlayList && getAllPlayList.length > 1
              ? "course_chapterscroll"
              : "course_chapterscroll_new"
          }`}
        >
          {getAllPlayList && getAllPlayList.length > 0 ? (
            getAllPlayList.map((value, index) => (
              <div
                className={`course_chapter_lists   ${
                  (activeItem === null && index == 0) || activeItem === index
                    ? "active"
                    : ""
                }`}
                key={index}
                onClick={() => handleActive(index)}
              >
                <div className="course_chapter_img">
                  <img
                    src={images["chapter_img1.png"]}
                    alt=""
                    onClick={() =>
                      updatePlaylist({
                        ...value,
                        chapter: String(index + 1).padStart(2, "0"),
                      })
                    }
                  />
                </div>

                <div className="course_chapter_content">
                  <h6>Chapter {String(index + 1).padStart(2, "0")}</h6>
                  <h2>{value.playlist_title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: value.playlist_description,
                    }}
                  ></p>
                </div>
              </div>
            ))
          ) : (
            <div>No playlist found</div>
          )}
        </div>
      </div>
    </>
  );
};
export default CourseChapterList;
