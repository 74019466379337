import React, { useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const MobileFilter = (props) => {
 
  const { window,  } = props;
  const [open, setOpen] = useState(false);

  const { coursedetail, setSelectchecked, Selectchecked , setCurrentPage} = props;

  const handleCheckCategory = (e, id) => {

    if (e.target.checked === true) {
      setSelectchecked([...Selectchecked, id]);
      setCurrentPage(0);
    
    } else {
      const result = Selectchecked.filter((e) => e !== id);
      setSelectchecked(result);
    }
  };


  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Root className="new_filter_drawer">
        <CssBaseline />
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              height: `calc(50% - ${drawerBleeding}px)`,
              overflow: "visible",
            },
          }}
        />

        <SwipeableDrawer
          // container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: "absolute",
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography
              sx={{ p: 2, color: "text.secondary" }}
              className="filter-text"
            >
              {/* <span> 1 Applied</span> */}
              <p>Filter</p>
              {/* <button className="btn_clear">Clear</button> */}
            </Typography>
          </StyledBox>

          <div className="filter_button_all new_filter_mob filter_desktop_main">
            <div className="padge_left_side_main">
              {/* <div className="page_left_side_main_hediing ">
                <h1>Filter</h1>
                <p></p>
              </div> */}

              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="new-acc-main"
                  >
                    <Typography>Course Categories</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                      {/* <div> */}
                      <FormGroup className="nft-filter-sides">
                        {/* <FormControlLabel
                          control={<Checkbox />}
                          label="Business"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Technology"
                        />
                        <FormControlLabel control={<Checkbox />} label="Art" />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Wellness"
                        /> */}
                        {coursedetail != undefined &&
                          coursedetail.length > 0 &&
                          coursedetail.map((value, index) => {
                            return (
                              <div className="all-input-filter" key={index} onClick={(e)=>{setOpen(false)}} >
                                {/* <FormControlLabel control={<Checkbox />} label={value.title} /> */}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleCheckCategory(e, value.id);
                                    // setOpen(false);
                                    // toggleDrawer(false)
                                  }}
                                  // onClick={()=>{setOpen(false); toggleDrawer(false); }}
                                  id={"category" + index}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"category" + index}
                                >
                                  {value.title}
                                </label>
                              </div>
                            );
                          })}
                      </FormGroup>
                      {/* </div> */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="new-acc-main"
                  >
                    <Typography>Filters</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                      <FormGroup className="nft-filter-sides">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Beginner"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Intermediate"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="advanced"
                        />
                      </FormGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="new-acc-main"
                  >
                    <Typography>Featured Courses</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                      <FormGroup className="nft-filter-sides">
                        <FormControlLabel control={<Checkbox />} label="New" />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Trending"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Highly Rated"
                        />
                      </FormGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="new-acc-main"
                  >
                    <Typography>Course Cards</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                      <FormGroup className="nft-filter-sides">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Required"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Required"
                        />
                      </FormGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="new-acc-main"
                  >
                    <Typography>Subtitles</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                      <FormGroup className="nft-filter-sides">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Required"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Required"
                        />
                      </FormGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="new-acc-main"
                  >
                    <Typography>Price</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                      <FormGroup className="nft-filter-sides">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Required"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Required"
                        />
                      </FormGroup>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </Root>
    </div>
  );
};

MobileFilter.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MobileFilter;
