import { images } from "../actions/customFn";

export const freshlyData = {
  freshly_minted_title: "Freshly Minted",
  freshly_minted_forYou: [
    {
      id: 200,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description:
        "<p>A comma separated list of videos to play (in addition to the original URL).</p>",
    },
    {
      id: 199,
      image: images["Top_Courses_card6.png"],
      title: "PROTECTA",
      description: "<p>TEST</p>",
    },
    {
      id: 198,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description: "<p>TEST</p>",
    },
    {
      id: 194,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description: "<p>TEST</p>",
    },
    {
      id: 193,
      image: images["Top_Courses_card6.png"],
      title: "PROTECTA",
      description: "<p><br></p>",
    },
    {
      id: 190,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description:
        '<p><span style="color: rgb(255, 255, 255);">It is&nbsp;a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</span></p>',
    },
    {
      id: 183,
      image: images["Top_Courses_card6.png"],
      title: "PROTECTA",
      description:
        "<p>Don't waste your time with 60+ hour behemoth courses that you never finish!&nbsp;Instead, try this&nbsp;<strong>quick and effective pathway to Python</strong>&nbsp;that was designed with your sanity in mind.&nbsp;&nbsp;This course is the p1erfect first step into the world of data science, web dev, machine learning, or any other discipline that requires Python knowledge.</p><p>As an-person coding bootcamp instructor, I created this course to keep you engaged the entire way through.&nbsp;It's full of exercises, quizzes, challenges, and projects.&nbsp;The slides, diagrams, and cheatsheets were painstakingly designed to help you visualize the tricky concepts.&nbsp;There are no 20-minute monster videos in this course; the average video is only 4 minutes long.&nbsp;&nbsp;<strong>Everything about this course has been designed to make it actually completable!</strong></p><p>This course covers&nbsp;<strong>all the Python essentials you need</strong>: everything from variables to data structures to object oriented programming and modules. You’ll fill up your Python toolbox so you can go on and tackle libraries like pandas, flask, scikitlearn, django, and more.</p><p><strong>What this course is not:&nbsp;&nbsp;</strong>This course is not a complete guide to every single possible feature in the Python language.&nbsp;It focuses on the 80% that is absolutely critical and worth your time, but there are other (much longer) courses that are more akin to Python textbooks that take the time to cover every feature.&nbsp;In fact, I created one of those courses, and it happens to be 40 hours long!&nbsp;</p>",
    },
    {
      id: 182,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description:
        "<h2><strong>What you'll learn</strong></h2>\r\n<ul>\r\n<li>&nbsp;</li>\r\n<li>Master modern Python fundamentals as quickly as possible</li>\r\n<li>&nbsp;</li>\r\n<li>Learn the Python you need to move on to Data Science or Web Development</li>\r\n<li>&nbsp;</li>\r\n<li>Work with 3rd party Python libraries and modules</li>\r\n<li>&nbsp;</li>\r\n<li>Complete dozens of exercises, quizzes, and projects</li>\r\n<li>&nbsp;</li>\r\n<li>You'll learn to code with Python while staying sane!</li>\r\n</ul>\r\n<p>&nbsp;</p>",
    },
    {
      id: 180,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description: "<p>description</p>",
    },
    {
      id: 179,
      image: images["Top_Courses_card6.png"],
      title: "PROTECTA",
      description:
        '<p><span style="color: #ffffff;">It refers to&nbsp;the process of developing, enhancing, and changing one\'s personality over time. Such development occurs naturally throughout life, but it can also be modified through intentional efforts.</span></p>',
    },
  ],
  freshly_minted_following: [
    {
      id: 180,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description: "<p>description</p>",
    },
    {
      id: 182,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description:
        "<h2><strong>What you'll learn</strong></h2>\r\n<ul>\r\n<li>&nbsp;</li>\r\n<li>Master modern Python fundamentals as quickly as possible</li>\r\n<li>&nbsp;</li>\r\n<li>Learn the Python you need to move on to Data Science or Web Development</li>\r\n<li>&nbsp;</li>\r\n<li>Work with 3rd party Python libraries and modules</li>\r\n<li>&nbsp;</li>\r\n<li>Complete dozens of exercises, quizzes, and projects</li>\r\n<li>&nbsp;</li>\r\n<li>You'll learn to code with Python while staying sane!</li>\r\n</ul>\r\n<p>&nbsp;</p>",
    },
    {
      id: 183,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description:
        "<p>Don't waste your time with 60+ hour behemoth courses that you never finish!&nbsp;Instead, try this&nbsp;<strong>quick and effective pathway to Python</strong>&nbsp;that was designed with your sanity in mind.&nbsp;&nbsp;This course is the p1erfect first step into the world of data science, web dev, machine learning, or any other discipline that requires Python knowledge.</p><p>As an-person coding bootcamp instructor, I created this course to keep you engaged the entire way through.&nbsp;It's full of exercises, quizzes, challenges, and projects.&nbsp;The slides, diagrams, and cheatsheets were painstakingly designed to help you visualize the tricky concepts.&nbsp;There are no 20-minute monster videos in this course; the average video is only 4 minutes long.&nbsp;&nbsp;<strong>Everything about this course has been designed to make it actually completable!</strong></p><p>This course covers&nbsp;<strong>all the Python essentials you need</strong>: everything from variables to data structures to object oriented programming and modules. You’ll fill up your Python toolbox so you can go on and tackle libraries like pandas, flask, scikitlearn, django, and more.</p><p><strong>What this course is not:&nbsp;&nbsp;</strong>This course is not a complete guide to every single possible feature in the Python language.&nbsp;It focuses on the 80% that is absolutely critical and worth your time, but there are other (much longer) courses that are more akin to Python textbooks that take the time to cover every feature.&nbsp;In fact, I created one of those courses, and it happens to be 40 hours long!&nbsp;</p>",
    },
    {
      id: 190,
      image: images["Top_Courses_card6.png"],
      title: "PROTECTA",
      description:
        '<p><span style="color: rgb(255, 255, 255);">It is&nbsp;a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</span></p>',
    },
    {
      id: 193,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description: "<p><br></p>",
    },
    {
      id: 194,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description: "<p>TEST</p>",
    },
    {
      id: 198,
      image: images["Top_Courses_card6.png"],
      title: "PROTECTA",
      description: "<p>TEST</p>",
    },
    {
      id: 199,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description: "<p>TEST</p>",
    },
    {
      id: 200,
      image: images["Top_Courses_card6.png"],
      title: "BETTER LOVE",
      description:
        "<p>A comma separated list of videos to play (in addition to the original URL).</p>",
    },
    {
      id: 179,
      image: images["Top_Courses_card6.png"],
      title: "Fairies and Feather okay",
      description:
        '<p><span style="color: #ffffff;">It refers to&nbsp;the process of developing, enhancing, and changing one\'s personality over time. Such development occurs naturally throughout life, but it can also be modified through intentional efforts.</span></p>',
    },
  ],
};
export const viralVideoData = {
  viral_video_title: "Viral Sounds This Week",
  viral_video_list: [
    {
      id: 200,
      img: images["Top_Courses_card6.png"],
      title: "Claim to $FRAME",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
    {
      id: 199,
      img: images["Top_Courses_card6.png"],
      title: "BLINDED",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
    {
      id: 198,
      img: images["Top_Courses_card6.png"],
      title: "Citadel (RŌHKI Theme)",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
    {
      id: 194,
      img: images["Top_Courses_card6.png"],
      title: "SILVER LINEN",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
    {
      id: 193,
      img: images["Top_Courses_card6.png"],
      title: "Mean World Syndrome // The Sample Tape.",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
    {
      id: 190,
      img: images["Top_Courses_card6.png"],
      title: "State of Mind",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
    {
      id: 183,
      img: images["Top_Courses_card6.png"],
      title: "Claim to $FRAME",
      artist: "Grey Seymour",
      mint: "3.4K mints",
      endsIn: "Ends in 3d",
    },
  ],
};
export const questChest = [
  {
    id: 1,
    img: images["Top_Courses_card6.png"],
  },
  {
    id: 2,
    img: images["Top_Courses_card6.png"],
  },
  {
    id: 3,
    img: images["Top_Courses_card6.png"],
  },
  {
    id: 4,
    img: images["Top_Courses_card6.png"],
  },
];
export const constData = [
  {
    id: 1,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },
  {
    id: 2,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },
  {
    id: 3,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },
  {
    id: 4,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },
  {
    id: 5,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },
  {
    id: 6,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },

  {
    id: 7,
    image: images["course-math1.png"],
    playlist_title: "Maths",
    heading: "Lorem Ipusum",
  },
];
export const courseRating = [
  {
    id: 1,
    image: images["courses_list_profile.png"],
    avg_course_retings: 4,
    first_name: "John",
    last_name: "Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
    ratings: 4,
  },
  {
    id: 2,
    image: images["courses_list_profile.png"],
    avg_course_retings: 4,
    first_name: "John",
    last_name: "Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
    ratings: 4,
  },
  {
    id: 3,
    image: images["courses_list_profile.png"],
    avg_course_retings: 4,
    first_name: "John",
    last_name: "Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
    ratings: 4,
  },
  {
    id: 4,
    image: images["courses_list_profile.png"],
    avg_course_retings: 4,
    first_name: "John",
    last_name: "Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
    ratings: 4,
  },
];

export const myCourse = [
  {
    id: 1,
    image: images["my-course-banner.png"],
    tag_title: "Math's",
    title: "Lorem Ipsum is simply dummy text of the printing",
    sort_description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    author_image: images["courses_list_profile.png"],
    author_name: "John Doe",
  },
  {
    id: 2,
    image: images["my-course-banner.png"],
    tag_title: "Math's",
    title: "Lorem Ipsum is simply dummy text of the printing",
    sort_description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    author_image: images["courses_list_profile.png"],
    author_name: "John Doe",
  },
  {
    id: 3,
    image: images["my-course-banner.png"],
    tag_title: "Math's",
    title: "Lorem Ipsum is simply dummy text of the printing",
    sort_description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    author_image: images["courses_list_profile.png"],
    author_name: "John Doe",
  },
  {
    id: 4,
    image: images["my-course-banner.png"],
    tag_title: "Math's",
    title: "Lorem Ipsum is simply dummy text of the printing",
    sort_description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    author_image: images["courses_list_profile.png"],
    author_name: "John Doe",
  },
  {
    id: 5,
    image: images["my-course-banner.png"],
    tag_title: "Math's",
    title: "Lorem Ipsum is simply dummy text of the printing",
    sort_description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    author_image: images["courses_list_profile.png"],
    author_name: "John Doe",
  },
];
export const sponseredData = {
  sponser_data_title: "Related sponsored items",
  sponseredData_data: [
    {
      id: 200,
      image: images["related1.png"],
      title: "Fairies and Feather okay",
      description:
        "<p>A comma separated list of videos to play (in addition to the original URL).</p>",
    },
    {
      id: 199,
      image: images["related2.png"],
      title: "PROTECTA",
      description: "<p>TEST</p>",
    },
    {
      id: 198,
      image: images["related3.png"],
      title: "BETTER LOVE",
      description: "<p>TEST</p>",
    },
    {
      id: 194,
      image: images["related1.png"],
      title: "Fairies and Feather okay",
      description: "<p>TEST</p>",
    },
    {
      id: 193,
      image: images["related3.png"],
      title: "PROTECTA",
      description: "<p><br></p>",
    },
    {
      id: 190,
      image: images["related2.png"],
      title: "BETTER LOVE",
      description:
        '<p><span style="color: rgb(255, 255, 255);">It is&nbsp;a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</span></p>',
    },
    {
      id: 183,
      image: images["related1.png"],
      title: "PROTECTA",
      description:
        "<p>Don't waste your time with 60+ hour behemoth courses that you never finish!&nbsp;Instead, try this&nbsp;<strong>quick and effective pathway to Python</strong>&nbsp;that was designed with your sanity in mind.&nbsp;&nbsp;This course is the p1erfect first step into the world of data science, web dev, machine learning, or any other discipline that requires Python knowledge.</p><p>As an-person coding bootcamp instructor, I created this course to keep you engaged the entire way through.&nbsp;It's full of exercises, quizzes, challenges, and projects.&nbsp;The slides, diagrams, and cheatsheets were painstakingly designed to help you visualize the tricky concepts.&nbsp;There are no 20-minute monster videos in this course; the average video is only 4 minutes long.&nbsp;&nbsp;<strong>Everything about this course has been designed to make it actually completable!</strong></p><p>This course covers&nbsp;<strong>all the Python essentials you need</strong>: everything from variables to data structures to object oriented programming and modules. You’ll fill up your Python toolbox so you can go on and tackle libraries like pandas, flask, scikitlearn, django, and more.</p><p><strong>What this course is not:&nbsp;&nbsp;</strong>This course is not a complete guide to every single possible feature in the Python language.&nbsp;It focuses on the 80% that is absolutely critical and worth your time, but there are other (much longer) courses that are more akin to Python textbooks that take the time to cover every feature.&nbsp;In fact, I created one of those courses, and it happens to be 40 hours long!&nbsp;</p>",
    },
    {
      id: 182,
      image: images["related2.png"],
      title: "Fairies and Feather okay",
      description:
        "<h2><strong>What you'll learn</strong></h2>\r\n<ul>\r\n<li>&nbsp;</li>\r\n<li>Master modern Python fundamentals as quickly as possible</li>\r\n<li>&nbsp;</li>\r\n<li>Learn the Python you need to move on to Data Science or Web Development</li>\r\n<li>&nbsp;</li>\r\n<li>Work with 3rd party Python libraries and modules</li>\r\n<li>&nbsp;</li>\r\n<li>Complete dozens of exercises, quizzes, and projects</li>\r\n<li>&nbsp;</li>\r\n<li>You'll learn to code with Python while staying sane!</li>\r\n</ul>\r\n<p>&nbsp;</p>",
    },
    {
      id: 180,
      image: images["related1.png"],
      title: "BETTER LOVE",
      description: "<p>description</p>",
    },
    {
      id: 179,
      image: images["related3.png"],
      title: "PROTECTA",
      description:
        '<p><span style="color: #ffffff;">It refers to&nbsp;the process of developing, enhancing, and changing one\'s personality over time. Such development occurs naturally throughout life, but it can also be modified through intentional efforts.</span></p>',
    },
  ],
};
export const courseImages = [
  {
    id: 200,
    image: images["course-image1.png"],
    description: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    id: 199,
    image: images["course-image2.png"],
    description: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    id: 198,
    image: images["course-image3.png"],
    description: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    id: 194,
    image: images["course-image2.png"],
    description: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    id: 193,
    image: images["course-image1.png"],
    description: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    id: 190,
    image: images["course-image3.png"],
    description: "Lorem Ipsum is simply dummy text of the printing",
  },
];
