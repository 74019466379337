import React, { useEffect, useState } from "react";
import { FooterBig, FooterMobile, Header } from "../components";
import { getPrivacyPolicy } from "../actions/privacyPolicyAction";
const PrivacyPolicy = () => {
  const [PrivacyPolicy, setPrivacyCurrency] = useState([]);

  useEffect(() => {
    getPrivacyPolicy(setPrivacyCurrency);
  }, []);

  // console.log("PrivacyPolicy", PrivacyPolicy);
  return (
    <div className="all_page_bg only_bg_color">
      <Header />
      <div className="term_condition_page">
        <div className="">
          <h1>{PrivacyPolicy?.privacy_policy_title}</h1>

          <div
            dangerouslySetInnerHTML={{
              __html: PrivacyPolicy?.privacy_policy_description,
            }}
          ></div>
        </div>
      </div>

      <FooterBig />
      <div className="mobile-footer-new">
        <FooterMobile />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
