import React, { useEffect, useState } from "react";
import { getArray, images } from "../../actions/customFn";

import FsLightbox from "fslightbox-react";
import UploadPhotoModal from "../Modal/UploadPhotoModal";
import { useDispatch, useSelector } from "react-redux";
import { DeletePhotoAPI, getAllPhotosList } from "../../actions/uploadPhoto";
import RecordNotfound from "../Home/RecordNotfound";
import Swal from "sweetalert2";

const data = [
  images["photo-section1.png"],
  images["photo-section1.png"],
  images["photo-section2.png"],
  images["photo-section4.png"],
  images["photo-section5.png"],
  images["photo-section3.png"],
  images["photo-section1.png"],
  images["photo-section2.png"],
  images["photo-section4.png"],
  images["photo-section5.png"],
  images["photo-section3.png"],
];

const AllPhotos = () => {
  const dispatch = useDispatch();
  const { all_photos } = useSelector((state) => state.photosData);

  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);

  const [uploadload, setUploadLoad] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [photo_array, setPhotoArray] = useState([]);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number, image) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number + 1,
    });
  }

  useEffect(() => {
    dispatch(getAllPhotosList());
    setPhotoArray(getArray(all_photos, "photo"));
  }, [dispatch, uploadload]);

  const deletePhoto = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this photo ?",
      type: "warning",
      showCancelButton: true,
      // confirmButtonColor: "#7247e4",
      confirmButtonColor: "#d33",
      cancelButtonColor: "rgb(118 110 110)",
      confirmButtonText: "Delete",
    }).then((res) => {
      if (res.isConfirmed === true) {
        let params = {
          customer_id: localStorage.getItem("star_uId"),
          password: localStorage.getItem("star_upass"),
          photo_id: value.id,
        };
        DeletePhotoAPI(params, dispatch);
      }
    });
  };

  // console.log("all",all_photos);

  return (
    <>
      <div className="allphotos">
        <div className="allphotos-head p-2">
          <h2>All Photos</h2>

          <div className="uploadphoto_btn">
            <button className="upload_btn" onClick={handleShow}>
              Upload Images
            </button>
          </div>
        </div>

        <div className="allphotos-bottom px-2">
          <div className="allphotos-bottom_inner">
            {all_photos.length > 0 ? (
              all_photos.map((value, index) => {
                return (
                  <div className="allphotos-bottom_inner_main" key={index}>
                    <div className="allphoto-one all_images_popup">
                      {/* <div> */}
                      <img
                        src={value.photo}
                        alt=""
                        onClick={() => {
                          openLightboxOnSlide(index, value.photo);
                        }}
                      />
                      <button
                        className="photo-delete-icon-new"
                        onClick={() => deletePhoto(value)}
                      >
                        <img src={images["delete-icon.png"]} alt="" />
                      </button>
                      {/* <svg  onClick={() => deletePhoto(value)} className="photo-delete-icon" fill="red" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg> */}
                      {/* <svg
                          className="photo-delete-icon"
                          fill="red"
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="14"
                          viewBox="0 0 448 512"
                          onClick={() => deletePhoto(value)}
                        >
                          <path
                            d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                        </svg> */}
                      {/* <svg
                          className="photo-delete-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          width="16"
                          viewBox="0 0 512 512"
                          fill="red"
                          onClick={() => deletePhoto(value)}
                        >
                          <path
                            d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z" />
                        </svg> */}
                      {/* <svg
                          className="photo-delete-icon"
                          xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"
                          fill="red"
                          onClick={() => deletePhoto(value)}>
                          <path
                            d="M576 128c0-35.3-28.7-64-64-64H205.3c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7H512c35.3 0 64-28.7 64-64V128zM271 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                        </svg> */}
                      {/* <div className="clearbtn_searchdiv photo-delete-icon-btn">
                          <button
                            type="button"
                            className="upload_btn photo-delete-icon"
                            onClick={() => deletePhoto(value)}>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 32 32"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M29.3331 21.3334V10.6667C29.3331 9.60582 28.9117 8.58841 28.1616 7.83826C27.4114 7.08811 26.394 6.66669 25.3331 6.66669H10.8918C9.7398 6.66669 8.64513 7.16269 7.88513 8.02935L3.20646 13.3627C2.56664 14.0923 2.21387 15.0296 2.21387 16C2.21387 16.9704 2.56664 17.9078 3.20646 18.6374L7.88513 23.9707C8.26036 24.3988 8.72271 24.7419 9.24123 24.9769C9.75976 25.2119 10.3225 25.3334 10.8918 25.3334H25.3331C26.394 25.3334 27.4114 24.9119 28.1616 24.1618C28.9117 23.4116 29.3331 22.3942 29.3331 21.3334ZM26.6665 10.6667V21.3334C26.6665 21.687 26.526 22.0261 26.2759 22.2762C26.0259 22.5262 25.6868 22.6667 25.3331 22.6667H10.8918C10.7019 22.6667 10.5143 22.6261 10.3413 22.5477C10.1684 22.4693 10.0143 22.3548 9.88913 22.212L5.2118 16.8787C4.9985 16.6357 4.88088 16.3234 4.88088 16C4.88088 15.6767 4.9985 15.3644 5.2118 15.1214L9.88913 9.78802C10.0143 9.64522 10.1684 9.53078 10.3413 9.45237C10.5143 9.37396 10.7019 9.33338 10.8918 9.33335H25.3331C25.6868 9.33335 26.0259 9.47383 26.2759 9.72388C26.526 9.97393 26.6665 10.3131 26.6665 10.6667ZM15.4478 16L13.7238 17.724C13.4809 17.9755 13.3465 18.3123 13.3496 18.6619C13.3526 19.0115 13.4928 19.3459 13.74 19.5931C13.9873 19.8403 14.3217 19.9805 14.6713 19.9836C15.0209 19.9866 15.3577 19.8522 15.6091 19.6094L17.3331 17.8854L19.0571 19.6094C19.3086 19.8522 19.6454 19.9866 19.995 19.9836C20.3446 19.9805 20.679 19.8403 20.9262 19.5931C21.1734 19.3459 21.3137 19.0115 21.3167 18.6619C21.3197 18.3123 21.1853 17.9755 20.9425 17.724L19.2185 16L20.9425 14.276C21.1853 14.0246 21.3197 13.6877 21.3167 13.3382C21.3137 12.9886 21.1734 12.6541 20.9262 12.4069C20.679 12.1597 20.3446 12.0195 19.995 12.0165C19.6454 12.0134 19.3086 12.1478 19.0571 12.3907L17.3331 14.1147L15.6091 12.3907C15.3577 12.1478 15.0209 12.0134 14.6713 12.0165C14.3217 12.0195 13.9873 12.1597 13.74 12.4069C13.4928 12.6541 13.3526 12.9886 13.3496 13.3382C13.3465 13.6877 13.4809 14.0246 13.7238 14.276L15.4478 16Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div> */}
                      {/* </div> */}
                    </div>
                    <br /> <br />
                  </div>
                );
              })
            ) : (
              <RecordNotfound />
            )}
          </div>
          <div className="all_images_popup">
            {/* <FsLightbox toggler={toggler} sources={productIndex} type="image" key={productIndex} /> */}
          </div>
        </div>

        <UploadPhotoModal
          show={show}
          setIsLoader={setIsLoader}
          setShow={setShow}
          close={handleClose}
          setUploadLoad={setUploadLoad}
        />
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={photo_array}
        slide={lightboxController.slide}
      />
    </>
  );
};

export default AllPhotos;
