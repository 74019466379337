import React from "react";
import { images } from "../../actions/customFn";

const data = [
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },
  { image: images["friend-img1.png"] },
  { image: images["friend-img2.png"] },

  {
    image: images["friend-img3.png"],
  },
  {
    image: images["friend-img4.png"],
  },
  {
    image: images["friend-img5.png"],
  },
  {
    image: images["friend-img2.png"],
  },
];

const NewPhoto = () => {
  return (
    <>
      <div className="photos-pages">
        <div className="photos-tops">
          <h4>
            Photos <span>( 20 )</span>
          </h4>
        </div>

        <div className="photos-data">
          {/* <div className="photonew">
            {data.map((value, index) => {
              return (
                <div
                  className="collectphoto"
                  key={index}
                >
                 
                    <div
                      className="photos-imgnew"
                  
                    >
                        <img src={value.image} alt="" />

                       
                  </div>
                </div>
              );
            })}
          </div> */}

<div id="gallery">
<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />

<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />
<img src={ images["try_img1.png"]} alt="" />

  </div>
        </div>
      </div>
    </>
  );
};

export default NewPhoto;
