import { Navigate, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../assets/images", false));
const icons = importAll(require.context("../assets/icons", false));

//Get Routs
const GetRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          element={
            route.private === true ? (
              checkLogin() === true ? (
                route.page
              ) : (
                <Navigate to="/login" />
              )
            ) : (
              route.page
            )
          }
          key={route.key}
        />
      );
    }
    return null;
  });

const checkLogin = () => {
  var status = false;
  if (
    localStorage.getItem("star_uId") != null &&
    localStorage.getItem("star_upass") != null
  ) {
    status = true;
  }
  return status;
};

const handleScrollError = (errors) => {
  const errorsvalues = Object.keys(errors);
  if (errorsvalues.length > 0) {
    let firstErrorElement = document.getElementsByName(errorsvalues[0])[0];
    if (
      firstErrorElement != undefined &&
      firstErrorElement !== null &&
      firstErrorElement !== ""
    ) {
      firstErrorElement.scrollIntoView({ behavior: `smooth`, block: "center" });
    }
  }
};

function NumberFormat(number) {
  return number != undefined && number > 0 ? parseFloat(number).toFixed(2) : 0;
}

const successMsg = (msg) => {
  // toast.success(msg);
  toast.success(msg, {
    style: {
      boxShadow: "0px 10px 30px 0px rgba(69, 74, 166, 0.6)",
      border: "4px solid transparent",
      background:
        "linear-gradient(156deg, #2d0066 50%, #db00fe) padding-box,linear-gradient(173deg, #310069, #da00fd, #31006a) border-box",
      padding: "16px",
      color: "#fff",
      fontWeight: "600px",
      borderRadius: "0px 20px",
    },
    iconTheme: {
      primary: "#ad009c",
      secondary: "#FFFAEE",
    },
  });
};

const errorMsg = (msg) => {
  toast.error(msg, {
    style: {
      boxShadow: "0px 10px 30px 0px rgba(69, 74, 166, 0.6)",
      border: "4px solid transparent",
      background:
        "linear-gradient(156deg, #2d0066 50%, #db00fe) padding-box,linear-gradient(173deg, #310069, #da00fd, #31006a) border-box",
      padding: "16px",
      color: "#fff",
      fontWeight: "600px",
      borderRadius: "0px 20px",
    },
    iconTheme: {
      primary: "#ad009c",
      secondary: "#FFFAEE",
    },
  });
};

const getArray = (arr, key) => {
  let return_arr = [""];
  if (arr != undefined && arr.length > 0) {
    return_arr = arr.map((foo) => foo[key]);
  }
  return return_arr;
};

const getappenFormData = (sendformData, arr, key) => {
  arr.map((value, index) => {
    sendformData.append(`${key}[]`, value);
  });
  return sendformData;
};

export {
  images,
  icons,
  GetRoutes,
  handleScrollError,
  successMsg,
  errorMsg,
  NumberFormat,
  getArray,
  getappenFormData,
};
