import React from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";
import { NavLink, useNavigate } from "react-router-dom";

const TopCourses = (props) => {
  const topcourse = props.topcourse;
  const navigate = useNavigate();
  const directpage = (path) => {
    navigate(`/${path}`);
  };
  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };
  // chapter_img3
  return (
    <div className="Top_courses_main">
      {topcourse?.map(function(value, index) {
        return (
          <NavLink to={`/courses-detail/${value.id}`}>
            <div className="Top_courses_main_inner" key={index}>
              <img
                src={value.image ? value.image : images["chapter_img3.png"]}
                alt=""
              />
              <div className="Top_main_inner_text">
                <h2>{value.title}</h2>
                <p>{value.sort_description}</p>

                <div className="course_rating_main_hesing">
                  <div className="prd_star_rating ">
                    <ul>
                      {/* <li> */}
                        <ReactStars
                          {...reactRatings}
                          size={14}
                          value={Number(value.rating)}
                        />
                      {/* </li> */}
                      <li className="star_point">{value.rating}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        );
      })}

      <div className="top_courses_btn">
        <button
          onClick={(e) => {
            directpage("courses");
          }}
        >
          {" "}
          Show More
        </button>
      </div>
    </div>
  );
};

export default TopCourses;
