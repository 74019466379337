import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { NavLink } from "react-router-dom";
import { FooterBig, Header } from "../components";
import { TextField, IconButton, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FooterMobile from "../components/Home/FooterMobile";
import ReactPaginate from "react-paginate";
import { motion } from "framer-motion";
import Loader from "../hooks/Loader";
import { getAllPost } from "../actions/uploadBlogs";
const PostList = () => {
  const options = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
  ];

  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [postlist, setPostList] = useState([]);
  const [page_count, setPagecount] = useState(0);

  useEffect(() => {
    setIsLoader(true);
    getAllPost(setPostList, setPagecount, currentPage, setIsLoader);
  }, [currentPage]);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg courses_detail_bg_color">
        <Header />
        <div className="postlist_section">
          <div className="page-container">
            <div className="row post_list_recent">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="postlist_heading">
                  <h3>Recent Post</h3>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="inpute_postlist">
                  <div className="input_search_new">
                    <Autocomplete
                      options={options}
                      value="Search by name or attribute"
                      className="search-all"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <IconButton type="submit" aria-label="search">
                                <SearchIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            {isLoader == true ? (
              <Loader />
            ) : (
              <>
                <div className="main_post_list_box row">
                  {postlist.length > 0 ? (
                    postlist.map((value, index) => {
                      return (
                        <div className="col-md-3">
                          <NavLink to="">
                            <div className="post_list_item" key={index}>
                              <div
                                className="course-list1 shine"
                                style={{
                                  backgroundImage: `url(${value.image})`,
                                }}
                              >
                                <div className="top_post_bg_img">
                                  <div className="course-cont">
                                    <div className="heading_card">
                                      <div className="latestblogs_right_profile1_left">
                                        <div className="right_profile_left_bg ">
                                          <img
                                            src={
                                              images["courses_list_profile.png"]
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <span>Nicholas K.</span>
                                      </div>
                                    </div>
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry.
                                    </p>
                                    <h1>20-02-2023</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      {" "}
                      <h3>Record Not Found</h3>
                    </div>
                  )}
                </div>
              </>
            )}

            {/* <div className="main_post_list_box">
              {postlist.map((value, index) => {
                return (
                  <NavLink to="">
                    <div className="post_list_item" key={index}>
                      <div
                        className="course-list1 shine"
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}
                      >
                        <div className="top_post_bg_img">
                          <div className="course-cont">
                            <div className="heading_card">
                              <div className="latestblogs_right_profile1_left">
                                <div className="right_profile_left_bg ">
                                  <img src={value.author_image} alt="" />
                                </div>
                                <span>{value.author_name}</span>
                              </div>
                            </div>
                            <p>{value.title}</p>
                            <h1>{value.date}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </div> */}

            {/* <div className="main_post_list_box">
              {currentItems.map((value, index) => {
                return (
                  <NavLink to="">
                    <div className="post_list_item" key={index}>
                      <div
                        className="course-list1 shine"
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}
                      >
                        <div className="top_post_bg_img">
                          <div className="course-cont">
                            <div className="heading_card">
                              <div className="latestblogs_right_profile1_left">
                                <div className="right_profile_left_bg ">
                                  <img
                                    src={images["courses_list_profile.png"]}
                                    alt=""
                                  />
                                </div>
                                <span>Nicholas K.</span>
                              </div>
                            </div>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                            <h1>20-02-2023</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
            <div className="main_post_list_box">
              {currentItems.map((value, index) => {
                return (
                  <NavLink to="">
                    <div className="post_list_item" key={index}>
                      <div
                        className="course-list1 shine"
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}
                      >
                        <div className="top_post_bg_img">
                          <div className="course-cont">
                            <div className="heading_card">
                              <div className="latestblogs_right_profile1_left">
                                <div className="right_profile_left_bg ">
                                  <img
                                    src={images["courses_list_profile.png"]}
                                    alt=""
                                  />
                                </div>
                                <span>Nicholas K.</span>
                              </div>
                            </div>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                            <h1>20-02-2023</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </div> */}

            {/* paginate */}
            {page_count > 1 && (
              <div className="paginate_all">
                <ReactPaginate
                  pageCount={page_count}
                  onPageChange={paginate}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"active"}
                  pageRange={5}
                  marginPagesDisplayed={2}
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                        transform="translate(7.172) rotate(90)"
                        fill="#454AA6"
                      />
                    </svg>
                  }
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                        transform="translate(15.086 -5) rotate(90)"
                        fill="#454AA6"
                      />
                    </svg>
                  }
                />
              </div>
            )}
          </div>
        </div>
        <FooterBig />

        <div className="mobile-footer-new">
          <FooterMobile />
        </div>
      </div>
    </motion.div>
  );
};

export default PostList;
