import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";
import { useDispatch } from "react-redux";

// Add to Friend list
export const asyncAddToFriendList = async (setState, setIsLoader, isSearch) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      searchKeyword: isSearch.search,
    };
    const url = "/add_to_friend_list";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
      }

      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1500);
  }
};

// Add To Friend
export const asyncAddToFriend = async (
  to_customer,
  setFriendsId,
  FriendsId
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      to_customer: to_customer,
    };
    const url = "/add_to_friend";
    await axios.post(url, formData).then((response) => {
      if (response.data) {
        successMsg(response.data.message);
        setFriendsId([...FriendsId, to_customer]);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// cancel friend request
export const asynccancelrequest = async (
  to_customer,
  setFriendsId,
  FriendsId
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      to_customer: to_customer,
    };
    const url = "/cancel_friend_request";
    await axios.post(url, formData).then((response) => {
      if (response.data) {
        successMsg(response.data.message);
        setFriendsId(!FriendsId);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Friend Request
export const asyncfriendrequest = async (setState, setIsLoader, isSearch) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      searchKeyword: isSearch.search,
    };
    const url = "/friend_request";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Accept Friend Request
export const asyncAcceptRequest = async (
  customer_id,
  arrState,
  setState,
  dispatch,
  index,
  type
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      to_customer: customer_id,
      //   from_customer: user_id,
    };
    const url = "/accept_friend_request";
    await axios.post(url, formData).then((response) => {
      if (response.data) {
        successMsg(response.data.message);
        let allfriendsarr = arrState;
        let result = [];
        if (type == "") {
          result = allfriendsarr.filter(
            (val) => val.customer_id != customer_id
          );
        } else {
          let allfriends_arr = [...arrState];
          allfriends_arr[index].recive_request = false;
          allfriends_arr[index].is_friend = true;
          result = allfriends_arr;
        }
        dispatch(getAllFriendList(""));
        setState(result);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Reject Friend Request
export const asyncRejectRequest = async (
  customer_id,
  arrState,
  setState,
  dispatch,
  index,
  type
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      to_customer: customer_id,
      //   from_customer: customer_id,
    };
    const url = "/reject_friend_request";
    await axios.post(url, formData).then((response) => {
      if (response.data) {
        successMsg(response.data.message);
        let allfriendsarr = arrState;
        let result = [];
        if (type == "") {
          result = allfriendsarr.filter(
            (val) => val.customer_id != customer_id
          );
        } else {
          let allfriends_arr = [...arrState];
          allfriends_arr[index].recive_request = false;
          // allfriends_arr[index].is_friend = true;
          result = allfriends_arr;
        }
        setState(result);
        // setRerender(!reRender);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// All Friends
export const asyncallfriend = async (setState, setIsLoader, isSearch) => {
  try {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    let formData = {
      customer_id: user_id,
      password: user_password,
    };
    if (
      isSearch != undefined &&
      isSearch.search != undefined &&
      isSearch.search != ""
    ) {
      formData.searchKeyword = isSearch.search;
    }
    const url = "/all_friends";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
        setState([]);
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Accept Friend Request

export const asyncUnfreind = async (
  customer_id,
  allfriends,
  setallfriends,
  type,
  dispatch
) => {
  // dispatch(getAllFriendList({ isSearch }))
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      to_customer: customer_id,
    };
    const url = "/unfriend";
    await axios.post(url, formData).then((response) => {
      if (response.data) {
        successMsg(response.data.message);
        let allfriendsarr = allfriends;
        let result = [];
        if (type != "") {
          let allfriends_arr = [...allfriends];
          allfriends_arr[type].is_friend = false;
          result = allfriends_arr;
        } else {
          result = allfriendsarr.filter(
            (val) => val.customer_id != customer_id
          );
        }
        setallfriends(result);
        dispatch(getAllFriendList(""));
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getAllFriendList = createAsyncThunk(
  "friend/friend_lists",
  async ({ isSearch }, { rejectWithValue }) => {
    try {
      let requestData = {
        customer_id: localStorage.getItem("star_uId"),
        password: localStorage.getItem("star_upass"),
      };
      if (
        isSearch != undefined &&
        isSearch.search != undefined &&
        isSearch.search != ""
      ) {
        requestData.searchKeyword = isSearch.search;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/all_friends", requestData);
      if (data.status) {
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Freinds Detail
export const asyncUserFriendList = async (
  setState,
  setIsLoader,
  user_id,
  setAddFriendTotal
) => {
  const customer_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: customer_id,
      user_id: user_id,
      password: user_password,
      // searchKeyword: isSearch.search,
    };
    const url = "/get_user_freind_list";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
        setAddFriendTotal(response.data.total_friends);
      } else {
      }

      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
    });
  } catch (error) {
    console.log("error", error);
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1500);
  }
};

//User Freind DEtail
//Freinds Detail
export const asyncUserFriendDetail = async (setState, user_id) => {
  const customer_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: customer_id,
      user_id: user_id,
      password: user_password,
      // searchKeyword: isSearch.search,
    };
    const url = "/get_user_freind_profile";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
      }
    });
  } catch (error) {
    console.log("error", error);
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
