import { useNavigate } from "react-router-dom";
import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const asyncCreateChannel = async (data, navigate) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendData = {
      customer_id: user_id,
      password: user_password,
      to_user_id: data.to_user_id,
    };
    const url = "/createChannel";
    await axios.post(url, sendData).then((response) => {
      const { status, message, channel_name } = response.data;
      if (status) {
        navigate("/chat", { state: channel_name });
      } else {
        errorMsg(message);
        //   setTotalPage(0);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getGroupList = createAsyncThunk(
  "chat/group_list",
  async (_, { rejectWithValue }) => {
    try {
      const requestData = {
        customer_id: localStorage.getItem("star_uId"),
        password: localStorage.getItem("star_upass"),
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/get_chat_groups", requestData);

      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const asyncDeleteChannel = async (id, dispatch) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("cid", id);
    const url = "/chat_delete";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        dispatch(getGroupList());
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    console.log("error", error);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
