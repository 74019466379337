import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { handleScrollError, images } from "../../../actions/customFn";
import { useRef } from "react";
import {
  asyncAddCampaign,
  getCampaignCategory,
} from "../../../actions/addCampaign";
import validator from "validator";

import SmallLoader from "react-js-loader";
import "./CreateGroup.css";
import { reactIcons } from "../../../utils/icons";
const CreateGroup = (props) => {
  const inputRef = useRef(null);
  // const [file, setFile] = useState(null);

  const [checkterm, setCheckTerm] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    text: "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const [CampaignCategory, setCampaignCategory] = useState();

  const [buttonloader, setButtonLoader] = useState(false);

  useEffect(() => {
    getCampaignCategory(setCampaignCategory);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const validateFormData = async () => {
    try {
      if (formData.title === "") {
        errors.title = "Enter title properly ";
      }

      if (formData.text === "") {
        errors.text = "Enter text properly";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCampaign = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        // asyncAddCampaign(
        //   formData,
        //   props.setShow,
        //   props.setIsLoader,
        //   setButtonLoader
        // );
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      // errorMsg(error.response.data.message);
    }
  };
  return (
    <div className="new-modal-Campaigns create-group-modal ">
      <Modal
        show={props.show}
        centered
        size="md"
        className="main_popup_inner donate_popup_main addcampaigns_popup add-campaigns-modal"
        onHide={props.close}
      >
        <FontAwesomeIcon onClick={props.close} icon={faTimes} />

        <Modal.Header>
          <Modal.Title className="popup_title_name">New Group</Modal.Title>
        </Modal.Header>
        {/* <h1>Personal Info</h1> */}
        <div className="group-image-section">
          <img src={images["group.png"]} alt="group" />
          <button>{reactIcons.camera}</button>
        </div>

        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
            {/* <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              First Name
            </FormHelperText> */}
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.title}
              name="title"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your title"
              onChange={(e) => {
                handleChange(e);
              }}
              helperText={formErrors.title}
            />
          </div>

          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
            {/* <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Last Name
            </FormHelperText> */}
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.text}
              name="text"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your text"
              onChange={(e) => handleChange(e)}
              helperText={formErrors.text}
            />
          </div>
        </div>

        <div className="popup_donate_btn">
          {buttonloader == true ? (
            <button type="button">
              <SmallLoader
                type="spinner-default"
                bgColor={"#fff"}
                color={"#fff"}
                size={40}
              />
            </button>
          ) : (
            <button
              onClick={(e) => {
                handleAddCampaign(e);
              }}
            >
              Create Group
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CreateGroup;
