import { createSlice } from "@reduxjs/toolkit";
import { getAllFriendList } from "../actions/friendAction";



const initialState = {
  loading: false,
  all_friends: [],
  error: null,
  success: false,
  total_friends: 0,
};

const friendSlice = createSlice({
  name: "friend",
  initialState,
  reducers: {},
  extraReducers: {
    // Friends List
    [getAllFriendList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllFriendList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.all_friends = payload.data;
      state.total_friends = payload.total_friends;
    },
    [getAllFriendList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    
    
  },
});


export default friendSlice.reducer;
