import React, { useEffect, useState } from "react";
import { asyncgetFriendProfile } from "../../actions/uploadBlogs";
import { useParams } from "react-router-dom";

const FriendProfileBio = () => {
  const { id } = useParams();

  const [friendprofile, setFriendProfile] = useState();

  useEffect(() => {
    asyncgetFriendProfile(id, setFriendProfile);
  }, []);

  return (
    <>
      <div className="aboutus">
        <div className="top-upload timep-upload">
          <h2>Add Your Personal Info</h2>
          {/* <button
              type="button"
              className="upload_btn"
              onClick={(e) => handleUpdatePersonalInfo(e)}
            >
              Update
            </button> */}
        </div>

        {friendprofile != undefined && (
          <div className=" frndprofile_compt ">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="frnd_imgprofile">
                  <img src={friendprofile.image} alt="" />
                  <h5>Profile Image</h5>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="frnd_imgprofile">
                  <img src={friendprofile.myai_image} alt="" />
                  <h5>Game Image</h5>
                </div>
              </div>
            </div>
            <div className="user-profile-info-card">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Full Name:</h1>
                    <p>
                      {" "}
                      {friendprofile.first_name} {friendprofile.last_name}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>UserName: </h1>
                    <p>{friendprofile.username}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Email Id: </h1>
                    <p> {friendprofile.email}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Date Of Birth: </h1>
                    <p> {friendprofile.dob}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Gender: </h1>
                    <p> {friendprofile.gender} </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Work:</h1>
                    <p> {friendprofile.work} </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Skills: </h1>
                    <p className="skills-in-about">{friendprofile.skill}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Study: </h1>
                    <p>{friendprofile.study} </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>City: </h1>
                    <p>{friendprofile.city_name} </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Country: </h1>
                    <p>{friendprofile.country_name}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Address: </h1>
                    <p>{friendprofile.address} </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="frndprofile_parameters">
                    <h1>Bio:</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: friendprofile.bio_info,
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="frndprofile_parameters about_friendprofile">
                    <h1>Description: </h1>
                    <p>{friendprofile.about_me} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FriendProfileBio;
