// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  getUserDetail,
  userSignup,
  getPageSettingData,
  userSocialLogin,
} from "../actions/authActions";
import { useNavigate } from "react-router-dom";

const initialState = {
  loading: false,
  userInfo: {},
  settingData: {},
  isUserLogin: false,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIslogin: (state) => {
      if (localStorage.getItem("star_uId") != null) {
        state.loading = false;
        state.isUserLogin = true;
        state.userToken = null;
        state.error = null;
      }
    },
    setIsLogout: (state) => {
      localStorage.clear();
      state.loading = false;
      state.isUserLogin = false;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isUserLogin = true;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // login user End

    // userSignup user
    [userSignup.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userSignup.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [userSignup.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // userSignup user End

    // get  user
    [getUserDetail.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUserDetail.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.isUserLogin = true;
        state.userInfo = payload;
      }
    },
    [getUserDetail.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // get setting data ------

    [getPageSettingData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPageSettingData.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        // state.isUserLogin = true;
        state.settingData = payload;
      }
    },
    [getPageSettingData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get user End

    // Social login user
    [userSocialLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userSocialLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isUserLogin = true;
    },
    [userSocialLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // Social login user End
  },
});
export const { setIslogin, setIsLogout } = authSlice.actions;
export default authSlice.reducer;
