import React, { useState, useEffect } from "react";
import { FooterBig, FooterMobile, Header, SearchInput } from "../components";

import { images } from "../actions/customFn";

import { NavLink } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import NftFilter from "../components/Nft/NftFilter";
import NftFilterMobile from "../components/Nft/NftFilterMobile";
import { motion } from "framer-motion";
import NftProduct from "../components/Nft/NftProduct";
import { asyncAllProductList } from "../actions/productAction";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  TextField,
  IconButton,
  Autocomplete,
  FormControl,
  InputLabel,
} from "@mui/material";
import { reactIcons } from "../utils/icons";

const Nft = () => {
  const product_list = [
    {
      image: images["Nft_card_1.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_2.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_3.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_4.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_1.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_2.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_3.png"],
      title: "7941",
    },
    {
      image: images["Nft_card_4.png"],
      title: "7941",
    },
  ];

  const [isDataLoad, setIsDataLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [page_count, setPagecount] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const [searchdata, setSearchdata] = useState({
    search_name: "",
    sort_price: "",
  });
  const [searchProduct, setSearchProduct] = useState({});

  useEffect(() => {
    setTimeout(function() {
      setIsDataLoading(false);
    }, 3000);
  }, [isDataLoad]);

  useEffect(() => {
    if (currentPage == 0) {
      setIsLoader(true);
    }
    asyncAllProductList(
      setProductList,
      setIsDataLoading,
      setPagecount,
      currentPage,
      setIsLoader,
      setHasMore,
      "",
      searchProduct,
      setProductTotal
    );
  }, [currentPage, searchProduct]);

  const handleChange = (e) => {
    if (e.target != undefined) {
      let { name, value } = e.target;
      setSearchdata((searchdata) => ({ ...searchdata, [name]: value }));
    }
  };
  const refresh = () => {
    setCurrentPage(0);
  };

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 1000);
  };

  const handleFind = () => {
    if (searchdata.search_name != "" || searchdata.sort_price != "") {
      setSearchProduct(searchdata);
      setCurrentPage(0);
    }
  };

  const handleClear = () => {
    // if (searchdata.search_name != "" || searchdata.sort_price != "") {
    setSearchdata({ search_name: "", sort_price: "" });
    setSearchProduct({});
    setCurrentPage(0);
    // }
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg nft-main new-inner-pages nft-page">
        <div className="container-fluid">
          <Header />
          <div className="page-all-data list_margins">
            <div>
              <div className="paga-data-dis">
                {/* <FilterAll /> */}
                <NftFilter />
                <div className="page_data_mid_new">
                  <div className="page_data_mid_inner_input make_smallsearch">
                    <div className="nft_searc_left">
                      <div className="input_search_new  new-all-inputs searchby_namecourse">
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="search_name"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={searchdata.search_name}
                          placeholder="product name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          val
                        />
                        <button onClick={() => handleFind()}>
                          {reactIcons.search}
                        </button>
                      </div>
                      <div className="prd_Sort_by">
                        <span className="new_sort_title">Sort by </span>
                        <div className="sort_select  new-all-inputs">
                          <FormControl fullWidth>
                            <Select
                              value={searchdata.sort_price}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name="sort_price"
                              inputProps={{ "aria-label": "Without label" }}
                              labelId="demo-simple-select-label"
                              className="new_sort_list new-edit-main-all"
                              id="demo-simple-select"
                              defaultValue="Low to high"
                            >
                              {/* <MenuItem value={10}>Sort by Price</MenuItem> */}
                              <MenuItem value="name_az">Low to high</MenuItem>
                              <MenuItem value="name_za">High to low</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="nft_searc_right">
                      <button
                        className="btn_findcoursenow mx-1"
                        onClick={() => handleFind()}
                      >
                        Find
                      </button>

                      <div className="clearbtn_searchdiv">
                        <button
                          type="button"
                          class="upload_btn"
                          onClick={() => {
                            handleClear();
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="nft_page_card_section">
                    <Tabs>
                      <div className="nft_page_card_tab">
                        <div className="nft_page_left">
                          <TabList>
                            <Tab
                              defaultactivekey="Items"
                              transition="true"
                              id="noanim-tab-example"
                              className="tab_new_inner"
                              onClick={() => {
                                setIsDataLoading(true);
                              }}
                            >
                              {" "}
                              <NavLink className="nft_card_nft_nav">
                                Products
                              </NavLink>{" "}
                            </Tab>
                            {/* <Tab
                              defaultactivekey="Analytics"
                              transition="true"
                              id="noanim-tab-example"
                              className="tab_new_inner"
                              onClick={() => {
                                setIsDataLoading(true);
                              }}
                            >
                              {" "}
                              <NavLink className="nft_card_nft_nav">
                                Analytics
                              </NavLink>{" "}
                            </Tab>
                            <Tab
                              defaultactivekey="Activity"
                              transition="true"
                              id="noanim-tab-example"
                              className="tab_new_inner"
                              onClick={() => {
                                setIsDataLoading(true);
                              }}
                            >
                              {" "}
                              <NavLink className="nft_card_nft_nav">
                                Activity
                              </NavLink>{" "}
                            </Tab> */}
                          </TabList>
                        </div>
                        <div className="nft_page_right">
                          <div className="nft_page_right_update">
                            <button
                              onClick={() => {
                                handleClear();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                              >
                                <path
                                  d="M14 21.2188C10.0299 21.2188 6.78125 17.97 6.78125 14C6.78125 10.0296 10.0299 6.78125 14 6.78125C15.9852 6.78125 17.7898 7.62344 19.0535 8.94693L15.2031 12.7969H23.625V4.375L20.7978 7.20245C19.0535 5.4577 16.6472 4.375 14 4.375C8.70641 4.375 4.375 8.70641 4.375 14C4.375 19.2936 8.64577 23.625 14 23.625C18.4321 23.625 22.1094 20.6645 23.2641 16.625H20.7184C19.6932 19.3354 17.0468 21.2188 14 21.2188Z"
                                  fill="#FF0098
"
                                />
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="27"
                                  height="27"
                                  rx="5.5"
                                  stroke="#FF0098
"
                                />
                              </svg>
                            </button>

                            <h2>Updated 26s ago</h2>
                          </div>

                          <div className="nft_page_right_update_right">
                            <h3>{productTotal} Items</h3>
                          </div>
                        </div>
                      </div>

                      <TabPanel eventkey="castle">
                        <NftProduct
                          isLoader={isLoader}
                          productList={productList}
                          handleLoadMore={handleLoadMore}
                          hasMore={hasMore}
                          refresh={refresh}
                        />
                      </TabPanel>
                      <TabPanel eventkey="Analytics">
                        <div className="nft_card_data">
                          <div className="row m-0">
                            {product_list.map(function(value, index) {
                              return (
                                <div
                                  className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 p-0"
                                  key={index}
                                >
                                  <div className="card_main_inner">
                                    <div className="card_img">
                                      <img src={value.image} alt="" />
                                    </div>

                                    <div className="card_main_inner_hedding">
                                      <h2>7941</h2>
                                      <h3>
                                        {" "}
                                        <span>$</span> 80{" "}
                                      </h3>
                                      <h4>Last sale : 78.100 WETH</h4>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel eventkey="Activity">
                        <div className="nft_card_data">
                          <div className="row m-0">
                            {product_list.map(function(value, index) {
                              return (
                                <div
                                  className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 p-0"
                                  key={index}
                                >
                                  <div className="card_main_inner">
                                    <div className="card_img">
                                      <img src={value.image} alt="" />
                                    </div>

                                    <div className="card_main_inner_hedding">
                                      <h2>7941</h2>
                                      <h3>
                                        {" "}
                                        <span>$</span> 76
                                      </h3>
                                      <h4>Last sale : 78.100 WETH</h4>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_mobile">
              <NftFilterMobile />
            </div>
            <div className="mobile-footer-new">
              <FooterMobile />
            </div>
          </div>

          <FooterBig />
        </div>
      </div>
    </motion.div>
  );
};

export default Nft;
