import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { getCampaignList } from "../../actions/addCampaign";
import Loader from "../../hooks/Loader";
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
const CampaignCommentList = (props) => {
  const { Commentshow } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const [CampaignComment, setCampaignComment] = useState();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (id != undefined) {
      setIsLoader(true);
      getCampaignList(id, setCampaignComment, setIsLoader);
    }
  }, [id, Commentshow]);
  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };
  return (
    <>
      {isLoader == true ? (
        <Loader />
      ) : (
        <>
          <h1>
            {CampaignComment != undefined &&
              CampaignComment.length > 0 &&
              "Reviews and rating "}{" "}
          </h1>

          {CampaignComment != undefined &&
            CampaignComment.map((value, index) => {
              return (
                <div className="product-Reviews-rating-card">
                  <div className="product-Revi-rating-profile mb-3">
                    <div className="latestblogs_right_profile1_left">
                      <div className="right_profile_left_bg">
                        <img src={value.image} alt="" />
                      </div>
                      <div className="right-section-profile-name">
                        <span>{value.name}</span>
                        <p>28 july 2023</p>
                      </div>
                    </div>
                    <div className="rating-sec">
                      <ul>
                        <li className="star_point">
                          <ReactStars
                            {...reactRatings}
                            size={16}
                            value={Number("4")}
                          />
                        </li>
                        <li className="star_point">{4}</li>
                      </ul>
                    </div>
                  </div>
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: value.comment,
                    }}
                  ></h2>
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default CampaignCommentList;
