import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";

import {
  CourseHistory,
  FooterBig,
  FooterMobile,
  Header,
  MyOrder,
  MyProduct,
  NftHistory,
} from "../components";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import {
  asyncgetMyProduct,
  getProductCategory,
} from "../actions/productAction";
import { asycGetMyOrders } from "../actions/orderAction";

const MyStore = () => {
  const [orderList, setOrderList] = useState([]);
  const [myProductList, setMyproductList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isRerender, setisRerender] = useState(false);
  const [value, setValue] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [page_count, setPagecount] = useState(0);

  const [togglepage, setTogglePage] = useState();

  const [searchproduct, setSearchProduct] = useState();
  const [searchorder, setSearchOrder] = useState();

  useEffect(() => {
    setIsLoader(true);
    asycGetMyOrders(setOrderList, setIsLoader, searchorder);
  }, [searchorder]);

  useEffect(() => {
    setIsLoader(true);
    asyncgetMyProduct(
      setMyproductList,
      setIsLoader,
      setPagecount,
      currentPage,
      searchproduct
    );
  }, [isRerender, togglepage, page_count, currentPage, searchproduct]);
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  // console.log("searchproduct=", searchproduct);

  // ----------- product category ------------
  const [productcategory, setProductCategory] = useState();

  useEffect(() => {
    getProductCategory(setProductCategory);
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="all_page_bg my-store-page">
          <div className="container-fluid">
            <Header />
            <div className="page-all-data orderhistory">
              <div>
                <div className="tab_orderhistory">
                  <h1 className="heading">My Store</h1>
                  <div className="orderhistory_tab">
                    <ul>
                      <li>
                        <button
                          className={value === 0 && "active"}
                          onClick={() => handleChange(0)}
                        >
                          My Product
                        </button>
                      </li>
                      <li>
                        <button
                          className={value === 1 && "active"}
                          onClick={() => handleChange(1)}
                        >
                          My Order
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="orderhistory_scrolls">
                    {value === 0 && (
                      <MyProduct
                        myProductList={myProductList}
                        isLoader={isLoader}
                        setIsLoader={setIsLoader}
                        reRender={{ setisRerender, isRerender }}
                        setTogglePage={setTogglePage}
                        togglepage={togglepage}
                        productcategory={productcategory}
                        setSearchProduct={setSearchProduct}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        page_count={page_count}
                      />
                    )}
                    {value === 1 && (
                      <MyOrder
                        orderList={orderList}
                        isLoader={isLoader}
                        productcategory={productcategory}
                        setSearchOrder={setSearchOrder}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
            <FooterBig />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default MyStore;
