import React from "react";
import { errorMsg, images, successMsg } from "../../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import RecordNotfound from "../Home/RecordNotfound";
import { useState } from "react";
import Swal from "sweetalert2";
import Loader from "../../hooks/Loader";
import { useEffect } from "react";
import {
  asyncDeleteProductWishlist,
  asyncGetProductWishlist,
} from "../../actions/productAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/fontawesome-free-solid";
import { reactIcons } from "../../utils/icons";

const ProductWishlist = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const navigate = useNavigate();
  const [productwishlist, setProductWishlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteCourse, setDeleteCourse] = useState(0);
  const [page_count, setPagecount] = useState(0);
  const handleRoute = (url) => {
    navigate(url);
  };
  const paginate = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    setIsLoader(true);
    asyncGetProductWishlist(
      setProductWishlist,
      setPagecount,
      currentPage,
      setIsLoader
    );
  }, [currentPage, deleteCourse]);

  const handleDelete = async (wishListID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this item from your wishlist ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7247e4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        asyncDeleteProductWishlist(wishListID).then((response) => {
          if (response.status == true) {
            setIsRender(!isRender);
            setDeleteCourse(deleteCourse + 1);
            successMsg(response.message);
          } else {
            errorMsg(response.message);
          }
        });
      }
      if (res.isDismissed === true) {
      }
    });
  };

  return (
    <div>
      {isLoader === true ? (
        <Loader />
      ) : (
        <>
          {productwishlist !== undefined && productwishlist.length > 0 ? (
            <div className="">
              <div className="row">
                {productwishlist.map((value, index) => {
                  return (
                    <>
                      <div
                        className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 "
                        key={index}
                      >
                        <NavLink to={"#"}>
                          <div className="product_inner productlist_img">
                            <div className="card_img">
                              {value.image !== undefined &&
                              value.image.length > 0 ? (
                                <img
                                  src={
                                    value.image !== ""
                                      ? value.image
                                      : images["product_listing3.png"]
                                  }
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={images["product_listing3.png"]}
                                  alt=""
                                />
                              )}
                              <div className="overlay-product"></div>
                              <ul className="product-wishlist-sec">
                                <li
                                  onClick={() => {
                                    handleDelete(value.product_id);
                                  }}
                                >
                                  {reactIcons.delete}
                                </li>
                              </ul>
                            </div>
                            <div className="product_text">
                              <div className="product_inner_hedding">
                                <h2>{value.title}</h2>
                                <h3>{value.category_title}</h3>
                              </div>
                              <div className="product_number">
                                <NavLink
                                  to={`/product-details/${value.product_id}`}
                                >
                                  Buy Now
                                </NavLink>
                                <h4>
                                  <span>$</span> {value.price}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="product_listing_data justify-content-center text-light">
              <RecordNotfound />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductWishlist;
