import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";
import validator from "validator";

import Checkbox from "@mui/material/Checkbox";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { TextField } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../actions/authActions";
import { asyncGetPagesData } from "../actions/pageAction";
import GoogleLoginComponent from "../components/social_login/GoogleLoginComponent";

const Login = () => {
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formErrors, setFormError] = useState({});
  const [isChecked, setIsChecked] = useState(true);

  const [homeDetail, setHomeDetail] = useState({});
  const errors = {};

  useEffect(() => {
    asyncGetPagesData(1, setHomeDetail);
  }, []);

  const handleTogglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const validateFormData = async () => {
    try {
      if (formData.email === "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(formData.email)) {
        errors.email = "Enter valid email id";
      }
      if (formData.password === "") {
        errors.password = "Enter Password";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        dispatch(userLogin({ formData, navigate }));
      }
    } catch (error) {
      // errorMsg(error.response.data.message);
    }
  };

  // console.log("homeDetail", homeDetail);
  return (
    <>
      <div className="register-page">
        <div
          className="left-register"
          style={{
            backgroundImage: `url(${
              homeDetail.banner_image != undefined &&
              homeDetail.banner_image != ""
                ? homeDetail.banner_image
                : ""
            })`,
          }}
        >
          <div className="right-div">
            <h1>
              {homeDetail.heading != undefined && homeDetail.heading != ""
                ? homeDetail.heading
                : ""}
            </h1>
            <p>
              {homeDetail.short_description != undefined &&
              homeDetail.short_description != ""
                ? homeDetail.short_description
                : ""}
            </p>
          </div>
        </div>

        <div className="right-register account-activation-page left_row login-main new-all-inputs">
          <div className="login-page_right_inner ">
            {/* <form> */}
            <div className="logo_heding right-top reset-head">
              <NavLink to="/">
                <img src={images["register-R1.png"]} alt="" />
              </NavLink>
              <h2>Login</h2>
            </div>

            <form onSubmit={handleLogin}>
              <div className="input_field">
                <TextField
                  error={formErrors.email}
                  name="email"
                  sx={{
                    width: "100%",
                  }}
                  className={`input_username new-edit-main-all mb-3`}
                  id="outlined-basic"
                  label="Email Address"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  helperText={formErrors.email}
                />
                {/* {formErrors.email && (
                  <div className="invalid-msg">{formErrors.email}</div>
                )} */}
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  name="password"
                  type={passwordVisible ? "text" : "password"}
                  error={formErrors.password}
                  className={`input_username new-edit-main-all mb-3`}
                  id="outlined-textarea"
                  onChange={handleChange}
                  label="Password"
                  placeholder="Enter your Password"
                  helperText={formErrors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          style={{ color: "white" }}
                        >
                          {passwordVisible ? (
                            <VisibilityOff className="white" />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {formErrors.password && (
                  <div className="invalid-msg">{formErrors.password}</div>
                )} */}
              </div>

              <div className="lost_your_password new_btn_inner">
                <NavLink to="/register">Register</NavLink>
                <NavLink to="/forget-password">Lost your password?</NavLink>
              </div>
              <div className="from_chechk_box">
                <Checkbox
                  {...label}
                  checked={isChecked}
                  name="remeber_me"
                  onChange={() => setIsChecked((prev) => !prev)}
                />
                <label htmlFor="Remember Me"> Remember Me</label>
              </div>

              <div className="login_btn  account_activation_btn">
                <button type="submit">Login</button>
              </div>
            </form>

            <div className="google_btn_login">
              {/* <button className="google_btn_login_inner">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M3.63545 9C3.63414 10.2748 4.08832 11.509 4.91761 12.484C5.7469 13.4591 6.89781 14.1122 8.16663 14.3277C9.43546 14.5433 10.7404 14.3073 11.8504 13.6617C12.9605 13.0161 13.804 12.0024 14.2319 10.8H9.08861V7.2H18V10.8H17.9955C17.1529 14.9076 13.4857 18 9.08861 18C4.06897 18 0 13.9707 0 9C0 4.0293 4.06897 2.28092e-06 9.08861 2.28092e-06C10.5742 -0.00104545 12.0373 0.358877 13.3496 1.0482C14.662 1.73753 15.7835 2.73519 16.6158 3.9537L13.6366 6.0192C12.9898 5.05117 12.0453 4.31526 10.943 3.92055C9.84074 3.52584 8.63936 3.49334 7.51696 3.82787C6.39456 4.16239 5.41088 4.84614 4.71173 5.77777C4.01258 6.70939 3.63517 7.83929 3.63545 9Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>Google</span>
              </button> */}
              <div className={"google-login-btn"}>
                <GoogleLoginComponent />
              </div>
            </div>

            {/* <div className="user_request_membership">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1_4511)">
                    <path
                      d="M0.510984 8.21799L2.77373 10.4807C2.88313 10.5901 3.03147 10.6515 3.18615 10.6515C3.34083 10.6515 3.48918 10.5901 3.59857 10.4807C3.70793 10.3714 3.76936 10.223 3.76936 10.0683C3.76936 9.91365 3.70793 9.7653 3.59857 9.65591L1.52482 7.58333H13.4167C13.5714 7.58333 13.7197 7.52187 13.8291 7.41247C13.9385 7.30308 14 7.1547 14 6.99999C14 6.84528 13.9385 6.69691 13.8291 6.58752C13.7197 6.47812 13.5714 6.41666 13.4167 6.41666H1.48923L3.59857 4.30558C3.70793 4.19619 3.76936 4.04784 3.76936 3.89316C3.76936 3.73848 3.70793 3.59014 3.59857 3.48074C3.48918 3.37139 3.34083 3.30995 3.18615 3.30995C3.03147 3.30995 2.88313 3.37139 2.77373 3.48074L0.510401 5.74349C0.1834 6.07227 -0.000109265 6.51716 4.8809e-08 6.98086C0.000109362 7.44457 0.183829 7.88937 0.510984 8.21799Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_4511">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <p> Go to STARBAY.XYZ | Request Membership</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
