import axios from "../axios";
// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");
export const asyncGetPagesData = async (id, setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      page_id: id,
    };

    const url = "/pagedetail";

    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
     
      } else {
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncGetBayPageSearch = async (key, setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      keyword: key,
    };

    const url = "/search_product_course";

    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
     
      } else {
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};