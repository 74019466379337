import React from "react";
import { useEffect } from "react";
import { asyncGetPagesData } from "../actions/pageAction";
import { useState } from "react";
import { FooterBig, FooterMobile, Header } from "../components";
import { motion } from "framer-motion";

const LegalandCompliance = () => {
  const [legalcompliance, setLegalCompliance] = useState();

  useEffect(() => {
    asyncGetPagesData(8, setLegalCompliance);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg legal-compliance-page">
        <div className="container-fluid">
          <Header />
          <div className="page-all-data">
            <div className="main-box">
              {legalcompliance !== undefined && (
                <div className="term_condition_page">
                  <div className="">
                    <h1>{legalcompliance?.legal_and_compliance_title}</h1>

                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          legalcompliance?.legal_and_compliance_description,
                      }}
                    ></p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <FooterBig />
        </div>
        <div className="mobile-footer-new">
          <FooterMobile />
        </div>
      </div>
    </motion.div>
  );
};

export default LegalandCompliance;
