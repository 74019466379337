import React, { useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { FooterBig, FooterMobile, Header, RecordNotfound } from "../components";
import { motion } from "framer-motion";
import { asynccheckout } from "../actions/orderAction";
import validator from "validator";
import ReactQuill from "react-quill";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getCityList, getStateList } from "../actions/CountryAction";
import {
  asyncAddPromoCode,
  asyncRemovePromoCode,
  getAddToCartList,
} from "../actions/cartAction";
import StripeCheckoutForm from "../components/Checkout/StripeCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { errorMsg, getArray, handleScrollError } from "../actions/customFn";
import Loader from "../hooks/Loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const Checkout = () => {
  // CheckOut Api
  const { settingData } = useSelector((state) => state.userAuth);
  const PUBLISHABLE_KEY = settingData.stripe_publish_key;
  const stripePromise = loadStripe(PUBLISHABLE_KEY);

  const { country_list } = useSelector((state) => state.countryData);
  const [stateList, setStateList] = useState([]);
  const [CartList, setCartList] = useState([]);
  const [CartSubTotals, setCartSubTotals] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [editorType, setEditorType] = useState("");
  const [is_reward_point_apply, setIsRewardPointApply] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    phone_number: "",
    email: "",
    additional_info: "",
  });
  const [promoData, setPromoData] = useState({
    promo_code: "",
  });

  const navigate = useNavigate();
  const [formErrors, setFormError] = useState({});
  const errors = {};

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
    } else {
      if (editorType != "") {
        setFormData((formData) => ({ ...formData, [editorType]: e }));
      }
    }
  };

  const handlepromoChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      setPromoData((promoData) => ({ ...promoData, [name]: value }));
    }
  };

  const validatePromoCode = async () => {
    try {
      if (promoData.promo_code == "") {
        errors.promo_code = "Enter promo code properly ";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  // const [promoname, setPromoName] = useState(false);
  const [promoload, setPromoload] = useState(false);
  const [checkterm, setCheckTerm] = useState(false);

  const handleCheckTerm = (e) => {
    if (e.target.checked == true) {
      setCheckTerm(true);
    } else {
      setCheckTerm(false);
    }
  };

  const addPromoCode = (e) => {
    try {
      e.preventDefault();
      validatePromoCode();
      if (Object.keys(errors).length === 0) {
        // setPromoload(true)
        // setIsLoader(true);
        asyncAddPromoCode(promoData, setPromoload, setIsLoader);

        localStorage.setItem(
          "promo_code",
          JSON.stringify(promoData.promo_code)
        );
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const validateFormData = async () => {
    try {
      if (formData.first_name === "") {
        errors.first_name = "Enter First name properly ";
      }
      if (formData.last_name === "") {
        errors.last_name = "Enter Last name properly";
      }

      if (formData.country == "") {
        errors.country = "Select Country";
      }

      if (formData.state == "") {
        errors.state = "Select State";
      }
      if (formData.city == "") {
        errors.city = "Select City";
      }

      if (formData.postal_code === "") {
        errors.postal_code = "Enter Your Postal Code";
      }

      if (formData.phone_number == "") {
        errors.phone_number = "Enter Phone Number";
      }
      if (formData.phone_number.length < 5) {
        errors.phone_number = "Phone no minimum 5 digit";
      }
      if (formData.phone_number.length > 12) {
        errors.phone_number = "Phone no maximum 12 digit";
      }
      if (!validator.isNumeric(formData.phone_number)) {
        errors.phone_number = "Phone must be numeric";
      } else if (formData.phone_number.length === 0) {
        errors.phone_number = "Enter Phone No.";
      }

      if (formData.email === "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(formData.email)) {
        errors.email = "Enter valid email id";
      }

      if (formData.additional_info === "") {
        errors.additional_info = "Enter Additional Info";
      }

      if (checkterm == false) {
        errors.checkterm = "Read and Check term and conditions";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formData.country != undefined && formData.country != "") {
      getStateList(setStateList, formData.country);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state != undefined && formData.state != "") {
      getCityList(setCityList, formData.state);
    }
  }, [formData.state]);

  const [buttonloader, setButtonLoad] = useState(false);

  const handleCheckOut = async (
    e,
    { stripe, elements, CardCvcElement, CardExpiryElement, CardNumberElement }
  ) => {
    e.preventDefault();
    validateFormData();

    if (Object.keys(errors).length === 0) {
      const { token, error } = await stripe.createToken(
        elements.getElement(
          CardCvcElement,
          CardExpiryElement,
          CardNumberElement
        )
      );

      if (error) {
        errorMsg(error.message);
      } else {
        if (token) {
          setButtonLoad(true);
          asynccheckout(
            formData,
            navigate,
            token.id,
            setButtonLoad,
            is_reward_point_apply
          );
        }
      }
    } else {
      handleScrollError(errors);
    }
  };

  useEffect(() => {
    setIsLoader(true);
    getAddToCartList(
      setCartList,
      setCartSubTotals,
      setIsLoader,
      "",
      is_reward_point_apply
    );
  }, [promoload, is_reward_point_apply]);

  const paymentOptions = {
    mode: "payment",
    amount: Math.round(2),
    currency: "usd",
  };

  var promoisApplied = getArray(CartList, "is_promo_code");

  const removePromoCode = (e) => {
    try {
      e.preventDefault();
      const promocode = JSON.parse(localStorage.getItem("promo_code"));
      // setPromoload(true)
      // setIsLoader(true);
      asyncRemovePromoCode(promocode, setPromoload, setIsLoader);
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  console.log("is_reward_point_apply=", is_reward_point_apply);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg checkout-page">
        <div className="container-fluid">
          <Header />

          {/* {isLoader == true ? (
          <Loader />
        ) : (
          <> */}
          <div className="page-all-data">
            <div className="campaignsdetail-main">
              <div className="check-out-main-heading">
                <div className="list_Breadcrumb">
                  <ul>
                    <li>
                      <NavLink className="top_nav_link" to="/">
                        {" "}
                        Home <span>/</span>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink className="top_nav_link" to="/">
                        Shop <span>/</span>
                      </NavLink>
                    </li>
                    {/* <li>
                    {" "}
                    <NavLink className="top_nav_link" to="/product-details">
                      Product Details <span>/</span>
                    </NavLink>
                  </li> */}
                    <li>
                      {" "}
                      <NavLink className="top_nav_link" to="/add-to-cart">
                        Add to Cart <span>/</span>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to="/">Check out</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="checkout-main new-all-inputs">
                  <div className="checkout-main_inner input_field">
                    {/* <form
                    // onSubmit={(e) => {
                    //   handleCheckOut(e);
                    // }}
                  > */}
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="billing-shipping-main">
                          <h1>Billing & Shipping</h1>
                          <hr className="border_line2" />

                          <div class="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                First Name (required)
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                error={formErrors.first_name}
                                helperText={formErrors.first_name}
                                onChange={handleChange}
                                name="first_name"
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter your first name"
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Last Name (required)
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                error={formErrors.last_name}
                                helperText={formErrors.last_name}
                                onChange={handleChange}
                                name="last_name"
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter your last name"
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Country (required)
                              </FormHelperText>
                              <Select
                                className="input_username new-edit-main-all w-100"
                                id="demo-simple-select-helper-label"
                                name="country"
                                onChange={handleChange}
                                MenuProps={MenuProps}
                                error={formErrors.country}
                                helperText={formErrors.country}
                              >
                                {country_list &&
                                  country_list.length > 0 &&
                                  country_list.map((value, index) => {
                                    return (
                                      <MenuItem key={index} value={value.id}>
                                        {value.label}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                State (required)
                              </FormHelperText>
                              <Select
                                sx={{
                                  color: "#fff",
                                }}
                                error={formErrors.state}
                                helperText={formErrors.state}
                                className="input_username new-edit-main-all w-100"
                                id="demo-simple-select-helper-label"
                                name="state"
                                onChange={handleChange}
                                MenuProps={MenuProps}
                              >
                                {stateList && stateList.length > 0 ? (
                                  stateList.map((value, index) => {
                                    return (
                                      <MenuItem key={index} value={value.id}>
                                        {value.label}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem value="">Select State</MenuItem>
                                )}
                              </Select>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                City (required)
                              </FormHelperText>
                              <Select
                                sx={{
                                  color: "#fff",
                                }}
                                error={formErrors.city}
                                helperText={formErrors.city}
                                className="input_username new-edit-main-all w-100"
                                id="demo-simple-select-helper-label"
                                name="city"
                                onChange={handleChange}
                              >
                                {cityList && cityList.length > 0 ? (
                                  cityList.map((value, index) => {
                                    return (
                                      <MenuItem key={index} value={value.id}>
                                        {value.label}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem value="">Select City</MenuItem>
                                )}
                              </Select>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Postal Code
                              </FormHelperText>

                              <TextField
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                  },
                                  "& input[type=number]": {
                                    MozAppearance: "textfield",
                                  },
                                  width: "100%",
                                }}
                                type="number"
                                error={formErrors.postal_code}
                                helperText={formErrors.postal_code}
                                onChange={handleChange}
                                name="postal_code"
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter your postal_code"
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                My Phone Number
                              </FormHelperText>
                              <TextField
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                  },
                                  "& input[type=number]": {
                                    MozAppearance: "textfield",
                                  },
                                  width: "100%",
                                }}
                                type="number"
                                error={formErrors.phone_number}
                                helperText={formErrors.phone_number}
                                onChange={handleChange}
                                name="phone_number"
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter phone number"
                              />
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Email Address (required)
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                onChange={handleChange}
                                name="email"
                                error={formErrors.email}
                                helperText={formErrors.email}
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter email address"
                              />
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Additional Information ( order Notes Optional )
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                onChange={handleChange}
                                name="additional_info"
                                error={formErrors.additional_info}
                                helperText={formErrors.additional_info}
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                placeholder="Enter your Text"
                                multiline
                                rows={4}
                              />
                              {/* <div className="account_txteditor_main checkout_discrpt">
                                <h6>description</h6>
                                <div
                                  style={{
                                    display: "grid",
                                    color: "#fff",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ReactQuill
                                    theme="snow"
                                    onFocus={(e) => {
                                      setEditorType("additional_info");
                                    }}
                                    modules={modules}
                                    formats={formats}
                                    name="additional_info"
                                    onChange={handleChange}
                                    style={{ height: "80px", color: "#fff" }}
                                  ></ReactQuill>
                                </div>
                              </div>
                              {formErrors.additional_info && (
                                <div className="invalid-msg">
                                  {formErrors.additional_info}
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="order-summary-main">
                          <h1>Order Summary</h1>
                          <hr className="border_line2" />
                          <table class="table table-new-checkout">
                            <tr>
                              <th>Product</th>
                              <th className="text-center">Qty</th>
                              <th className="text-right">Amount</th>
                            </tr>
                            {isLoader || promoload == true ? (
                              <tr>
                                <td colSpan={3}>
                                  <Loader />
                                </td>
                              </tr>
                            ) : CartList.length > 0 ? (
                              <>
                                {CartList != undefined &&
                                  CartList.length > 0 &&
                                  CartList.map((value, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{value.name}</td>
                                        <td className="text-center">
                                          {value.quantity}
                                        </td>
                                        <td className="text-right">
                                          <span>$</span> {value.price}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {CartSubTotals != undefined &&
                                  CartSubTotals.length > 0 &&
                                  CartSubTotals.map((value, index) => {
                                    return (
                                      <>
                                        <tr
                                          key={index}
                                          className={
                                            index == 0 ? "table_Subtotal" : ""
                                          }
                                        >
                                          <td>
                                            <div
                                              className={
                                                value.type == "minus"
                                                  ? "minus_price"
                                                  : ""
                                              }
                                            >
                                              {value.code}
                                            </div>
                                          </td>
                                          <td></td>
                                          <td className="text-right">
                                            <div
                                              className={
                                                value.type == "minus"
                                                  ? "minus_price"
                                                  : ""
                                              }
                                            >
                                              {value.type == "minus" ? "-" : ""}{" "}
                                              <span>$</span> {value.value}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={4}>
                                  <RecordNotfound />
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>

                        <div className="promocode_section">
                          {promoisApplied.includes(true) == false ? (
                            <div className="row">
                              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                                <TextField
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={(e) => handlepromoChange(e)}
                                  name="promo_code"
                                  error={formErrors.promo_code}
                                  helperText={formErrors.promo_code}
                                  className="input_username new-edit-main-all promocode"
                                  id="outlined-basic"
                                  label="Promo Code"
                                  placeholder="Enter promo code"
                                />
                              </div>
                              {/* {console.log("CartList", CartSubTotals[1].value)} */}
                              <div className="col-4">
                                <div className="promocode_button">
                                  <button
                                    type="button"
                                    onClick={(e) => addPromoCode(e)}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row remove_promocode">
                              <div className="col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12">
                                <h5>
                                  Promo Code :{" "}
                                  <span>
                                    {" "}
                                    {JSON.parse(
                                      localStorage.getItem("promo_code")
                                    )}
                                  </span>{" "}
                                </h5>
                              </div>

                              <div className="col-3">
                                <div className="promoremove_button">
                                  <button
                                    type="button"
                                    onClick={(e) => removePromoCode(e)}
                                  >
                                    {/* Remove PromoCode */}
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              </div>
                            </div>
                            // ------------
                          )}

                          <div className="term_condition_camp">
                            <div className="all-input-filter">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkterm"
                                onChange={(e) =>
                                  setIsRewardPointApply(!is_reward_point_apply)
                                }
                                id={"rewards_points_apply"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"rewards_points_apply"}
                              >
                                {" "}
                                Reward Point apply{" "}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="Payment-details-main">
                          <h1>Payment details</h1>
                          <hr className="border_line2" />
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Sed cum at
                            ut sit nunc ornare ac sem vulputa
                          </p>
                          <div className="row">
                            <div className="col-md-12">
                              <Elements
                                stripe={stripePromise}
                                options={paymentOptions}
                              >
                                <StripeCheckoutForm
                                  validateFormData={validateFormData}
                                  formData={formData}
                                  errors={errors}
                                  handleCheckOut={handleCheckOut}
                                  buttonloader={buttonloader}
                                  formErrors={formErrors}
                                  handleCheckTerm={handleCheckTerm}
                                />
                              </Elements>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FooterBig />
          <div className="mobile-footer-new">
            <FooterMobile />
          </div>
        </div>
        {/* </> */}
        {/* )} */}
      </div>
    </motion.div>
  );
};

export default Checkout;
