import React, { useEffect, useState } from "react";
import { DonatenowPopup, FooterBig, FooterMobile, Header } from "../components";
import { images } from "../actions/customFn";
import ProgressBar from "react-bootstrap/ProgressBar";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { getCampaignDetail } from "../actions/addCampaign";
import Loader from "../hooks/Loader";
import CampaignCommentModal from "../components/Modal/CampaignCommentModal";
import CampaignCommentList from "../components/Campaign/CampaignCommentList";

const CampaignsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    // setDonateId(id);
    setShow(true);
  };

  const [isAddComment, setIsAddComment] = useState(false);

  const [Commentshow, setCommentshow] = useState(false);
  const handleCommentClose = () => setCommentshow(false);
  const handleCommentShow = () => setCommentshow(true);

  const [campaigndetail, setCampaignDetail] = useState();
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    if (id != undefined) {
      setIsLoader(true);
      getCampaignDetail(id, setCampaignDetail, setIsLoader, navigate);
    }
  }, [id]);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg only_bg_color campaign-details-page">
        <div className="container-fluid">
          <Header />

          {isLoader == true ? (
            <Loader />
          ) : (
            <div className="page-all-data">
              {campaigndetail != undefined && (
                <div className="campaignsdetail-main">
                  <div className="campaignsdetail-main_inner">
                    <img src={campaigndetail.image} alt="" />
                  </div>

                  <div className="children_shelter">
                    <h2>{campaigndetail.title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: campaigndetail.sort_description,
                      }}
                    ></p>
                  </div>

                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="campig_targets">
                        <h4>Target</h4>

                        <h3>
                          <span>$</span>
                          {campaigndetail.target}
                        </h3>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="campig_targets">
                        <h4>Current</h4>

                        <h3>
                          <span>$</span>
                          {campaigndetail.current_donate}
                        </h3>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="campig_targets">
                        <h4>Donors</h4>
                        <h3>{campaigndetail.donors}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="progress_bar_mountain target_progess">
                    <ProgressBar
                      now={campaigndetail.percentage}
                      label={`${campaigndetail.percentage}%`}
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="donate_btn mr-3">
                      <button
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        Donate Now
                      </button>

                      {
                        <DonatenowPopup
                          Donate_data={id}
                          show={show}
                          close={handleClose}
                          setShow={setShow}
                        />
                      }
                    </div>
                    {campaigndetail.is_donate != undefined &&
                    campaigndetail.is_donate == true ? (
                      <div className="donate_btn">
                        <button
                          onClick={() => {
                            handleCommentShow();
                          }}
                        >
                          Add Comment
                        </button>

                        <CampaignCommentModal
                          Commentshow={Commentshow}
                          close={handleCommentClose}
                          setCommentshow={setCommentshow}
                          setIsAddComment={setIsAddComment}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <CampaignCommentList Commentshow={Commentshow} />
                </div>
              )}

              <FooterBig />
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CampaignsDetail;
