import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";

const ScoreBoard = () => {
  const { userInfo } = useSelector((state) => state.userAuth);

  const [userDetail, setUserDetail] = useState({});
  useEffect(() => {
    setUserDetail(userInfo);
  }, [userInfo]);

  const range = 30;

  console.log("userDetail", userDetail);
  return (
    <>
      <div className="ScoreBoard_main">
        <div className="ScoreBoard_top_box">
          <div className="player_profile">
            <div className="icon_king">
              <img src={images["icon_king.png"]} alt="" />
            </div>
            {/* <img src={images["player-1.png"]} alt="" /> */}
            {userDetail && <img src={userDetail.myai_image} alt="" />}
          </div>
          <div className="player_info_card">
            <div className="player_range">
              <img src={images["shield-1.png"]} alt="" />
              <div className="progress_div_inner">
                <ProgressBar now={range} />
              </div>
            </div>

            <div className="player_dim_main">
              <div className="player_icon">
                <img src={images["star.png"]} alt="" />
                <span>
                  {userDetail != "" &&
                  userDetail.customer_reward_point != undefined
                    ? userDetail.customer_reward_point
                    : ""}
                </span>
              </div>

              <div className="player_icon">
                <img src={images["role.png"]} alt="" />
                <span>14342</span>
              </div>
            </div>
          </div>
        </div>

        <div className="ScoreBoard_bottom_box">
          {userDetail != "" &&
          userDetail.topThreeRewardUsers != undefined &&
          userDetail.topThreeRewardUsers.length > 1 ? (
            <div className="user_one user_all_new user_same first-big-name">
              <img src={userDetail.topThreeRewardUsers[1].image} alt="" />
              <span className="user-name">
                {userDetail.topThreeRewardUsers[1].name}
              </span>
              <p className="user-price">
           
                {userDetail.topThreeRewardUsers[1].total_point}
              </p>
            </div>
          ) : (
            ""
          )}

          {userDetail != "" &&
          userDetail.topThreeRewardUsers != undefined &&
          userDetail.topThreeRewardUsers.length > 0 ? (
            <div className="user_two user_all_new mid-big-name">
              <img src={userDetail.topThreeRewardUsers[0].image} alt="" />
              <span className="user-name big-user-name">
                {userDetail.topThreeRewardUsers[0].name}
              </span>
              <p className="user-price">
             
                {userDetail.topThreeRewardUsers[0].total_point}
              </p>
            </div>
          ) : (
            ""
          )}

          {userDetail != "" &&
          userDetail.topThreeRewardUsers != undefined &&
          userDetail.topThreeRewardUsers.length > 2 ? (
            <div className="user_three user_all_new user_same third-big-name">
              <img src={userDetail.topThreeRewardUsers[2].image} alt="" />
              <span className="user-name">
                {userDetail.topThreeRewardUsers[2].name}
              </span>
              <p className="user-price">
             
                {userDetail.topThreeRewardUsers[2].total_point}
              </p>
            </div>
          ) : (
            ""
          )}

          {/* <div className="user_one user_all_new user_same first-big-name">
            <img src={images["player-2.png"]} alt="" />
            <span className="user-name">Maria</span>
            <p className="user-price"> $254</p>
          </div>
          <div className="user_two user_all_new mid-big-name">
            <img src={images["player-1.png"]} alt="" />
            <span className="user-name big-user-name">Rachel</span>
            <p className="user-price"> $254</p>
          </div>
          <div className="user_three user_all_new user_same third-big-name">
            <img src={images["player-3.png"]} alt="" />
            <span className="user-name">Andrew</span>
            <p className="user-price"> $254</p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ScoreBoard;
