import React, { useState } from "react";
import { images } from "../../actions/customFn";
import { useEffect } from "react";
import { asyncgetNotificatioList } from "../../actions/notification";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";

const data = [
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
  { name: "Rewards Point", date: "20 - 02 - 2023" },
];

const Notification = () => {
  const [userNotification, setUserNotification] = useState([]);
  const [isLoadPage, setLoadPage] = useState(false);

  useEffect(() => {
    setLoadPage(true);
    asyncgetNotificatioList(setUserNotification, setLoadPage);
  }, []);
  return (
    <>
      <div className="notification-page">
        <div className="top-heading p-2">
          <div className="row">
            <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-8 ">
              <h2>Notification</h2>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"></div>
          </div>
        </div>

        <div className="notify-botm p-2">
          {isLoadPage == true ? (
            <Loader />
          ) : userNotification.length > 0 ? (
            userNotification.map((value, index) => {
              return (
                <div className="notify-msg" key={index}>
                  <div className="notif-img">
                    <img src={value.image} alt="" />
                  </div>
                  <div className="noti-cont">
                    <h4>{value.message}</h4>
                    <p>{value.date}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <RecordNotfound />
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
