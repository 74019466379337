import React from "react";
import { images } from "../actions/customFn";
import {
  EarnRewardsPoint,
  FooterBig,
  Friends,
  Header,
  ScoreBoard,
  SliderBox,
  VideoPlayer,
} from "../components";
import { motion } from "framer-motion";

import FooterMobile from "../components/Home/FooterMobile";

const EarnRewards = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="Earn_rewards_section">
          <div className="all_page_bg">
            <div className="container-fluid">
              <Header />

              <div className="page-all-data">
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <ScoreBoard />
                      {/* <ChatGroup /> */}
                      <Friends handleChange={handleChange} />
                    </div>

                    <div className="col-md-6 px-3 px-lg-0">
                      <EarnRewardsPoint />
                    </div>

                    <div className="col-md-3">
                      <SliderBox />
                      <VideoPlayer />

                      <div className="header_right_main btn-right-side mt-3">
                        <button>MYSTERY BOX</button>
                      </div>

                      <div className="friend_chat_button mt-3">
                        <button>PLAY</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
              <FooterBig />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
export default EarnRewards;
