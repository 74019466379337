import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import SmallLoader from "react-js-loader";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  //   PaymentElement,
  //   CardNumberElement,
  //   CardExpiryElement,
  //   CardCvcElement,
} from "@stripe/react-stripe-js";
import { asynccheckout } from "../../actions/orderAction";
import { NavLink, useNavigate } from "react-router-dom";
import { handleScrollError } from "../../actions/customFn";

const StripeCheckoutForm = (props) => {
  const {
    handleCheckOut,
    button_text,
    buttonloader,
    formErrors,
    handleCheckTerm,
  } = props;
  // const setToken = props.setToken;

  const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "#000",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    handleCheckOut(event, {
      stripe,
      elements,
      CardCvcElement,
      CardExpiryElement,
      CardNumberElement,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <h2>Card Number</h2>
          <CardNumberElement
            className="card_number-payment"
            options={CARD_OPTIONS}
          />
        </div> */}

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <h2>Card Number</h2>
          <CardNumberElement
            className="card_number-payment"
            options={CARD_OPTIONS}
          />
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <h2>Expiry Date</h2>
          <CardExpiryElement
            className="card_number-payment"
            options={CARD_OPTIONS}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <h2>CVV</h2>
          <CardCvcElement
            className="card_number-payment"
            options={CARD_OPTIONS}
          />
        </div>
      </div>

      <div className="term_condition_camp">
        <div className="all-input-filter">
          <input
            className="form-check-input"
            type="checkbox"
            name="checkterm"
            onChange={(e) => handleCheckTerm(e)}
            id={"terms"}
          />
          <label className="form-check-label" htmlFor={"terms"}></label>
          <p>
            By continuing you are Agreeing to Starbay's Terms of Service and
            acknowledge receipt of out
            <span>
              <NavLink to={"/privacy-policy"} target="_blank">
                {" "}
                Privacy policy{" "}
              </NavLink>
            </span>
          </p>
        </div>

        <div className="check_termerror">
          <p> {formErrors.checkterm} </p>
        </div>
      </div>

      <hr className="popup_link_btn_hr" />
      <div className="popup_donate_btn">
        {buttonloader == true ? (
          <button type="button">
            <SmallLoader
              type="spinner-default"
              bgColor={"#fff"}
              color={"#fff"}
              size={40}
            />
          </button>
        ) : (
          <button
            className="make_odr_btn"
            type="submit"
            disabled={!stripe || !elements}
          >
            {button_text != undefined && button_text != ""
              ? button_text
              : "Place Order"}
          </button>
        )}
      </div>
    </form>
  );
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

export function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

export default StripeCheckoutForm;
