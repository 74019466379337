import React from "react";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { useState } from "react";
import validator from "validator";
import { asyncAskQuestion } from "../../actions/courseAction";
import { useParams } from "react-router-dom";
import { errorMsg, handleScrollError } from "../../actions/customFn";

const AskEducatorQuestionModal = (props) => {
  const { id } = useParams();

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    myquestion: "",
  });

  const validateFormData = async () => {
    try {
      if (formData.username === "") {
        errors.username = "Enter Your name properly";
      }
      if (formData.email === "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(formData.email)) {
        errors.email = "Enter valid email id";
      }
      if (formData.myquestion === "") {
        errors.myquestion = "Enter description";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
  };

  const handleaskQuestion = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncAskQuestion(id, formData, props.setShow);
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <Modal
      show={props.show}
      centered
      className="main_popup_inner donate_popup_main Ask-Educator-Question educator-question-modal"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <Modal.Title className="popup_title_name">
          Ask the Educator a Question
        </Modal.Title>
      </Modal.Header>
      <div className="row main_popup_inner1 new-all-inputs">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Name
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.username}
            name="username"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="Enter your name"
            onChange={handleChange}
            helperText={formErrors.username}
          />
        </div>

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Email Address
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.email}
            name="email"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="Enter your email address"
            onChange={handleChange}
            helperText={formErrors.email}
          />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Question
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            name="myquestion"
            error={formErrors.myquestion}
            className="input_username new-edit-main-all"
            id="outlined-basic"
            multiline
            rows={5}
            placeholder="Enter your text"
            onChange={handleChange}
            helperText={formErrors.myquestion}
          />
        </div>
      </div>

      <div className="popup_donate_btn mb-0">
        <button type="button" onClick={(e) => handleaskQuestion(e)}>
          Send
        </button>
      </div>
    </Modal>
  );
};

export default AskEducatorQuestionModal;
