import React, { useState } from "react";
import { images } from "../../actions/customFn";
import Modal from "react-bootstrap/Modal";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import UploadCourseModal from "../Modal/UploadCourseModal";
import { NavLink } from "react-router-dom";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};
var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const UploadCourse = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="uploadcourse">
        <img src={images["courseupload-img3.png"]} alt="" />

        <h2>Upload New Courses</h2>
        <p>Lorem Ipsum is simply dummy text of</p>

        {/* <button className="uploadcours_btn" type="button" onClick={handleShow}>
          Upload
        </button> */}

        <NavLink className="uploadcours_btn" to="/upload-course">
          Upload
        </NavLink>

        <div className="upload_course_modal">
          {<UploadCourseModal show={show} close={handleClose} />}
        </div>
      </div>
    </>
  );
};

export default UploadCourse;
