import React, { useState } from "react";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import "flatpickr/dist/flatpickr.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { errorMsg } from "../../actions/customFn";
import { asyncChangepassword } from "../../actions/authActions";

const MyAccountTapModal = (props) => {
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateFormData = async () => {
    try {
      if (formData.current_password === "") {
        errors.current_password = "Enter Old Password";
      }

      if (formData.new_password === "") {
        errors.new_password = "Enter New Password";
      } else if (formData.new_password.length < 8) {
        errors.new_password = "Password must have atleast 8 character";
      }
      if (formData.confirm_password === "") {
        errors.confirm_password = "Enter Confirm Password";
      } else if (formData.new_password !== formData.confirm_password) {
        errors.confirm_password = "Confirm Password does not match";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncChangepassword(formData, props.close);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <Modal
      centered
      show={props.show}
      className="main_popup_inner"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <h4>Change Password</h4>
      </Modal.Header>

      <div>
        <form className="password-change-form" onSubmit={handleChangePassword}>
          <div className="row main_popup_inner1 new-all-inputs">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Old Password
              </FormHelperText>
              <TextField
                name="current_password"
                error={formErrors.current_password}
                sx={{
                  width: "100%",
                }}
                type="password"
                className={`input_username new-edit-main-all `}
                id="outlined-basic"
                placeholder="Enter your old password"
                onChange={handleChange}
                helperText={formErrors.current_password}
              />
            </div>
          </div>

          <div className="row main_popup_inner1 new-all-inputs">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                New Password
              </FormHelperText>
              <TextField
                name="new_password"
                type="password"
                error={formErrors.new_password}
                sx={{
                  width: "100%",
                }}
                className={`input_username new-edit-main-all `}
                id="outlined-basic"
                placeholder="Enter your new password"
                onChange={handleChange}
                helperText={formErrors.new_password}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Confirm Password
              </FormHelperText>
              <TextField
                name="confirm_password"
                error={formErrors.confirm_password}
                sx={{
                  width: "100%",
                }}
                type="password"
                className={`input_username new-edit-main-all `}
                id="outlined-basic"
                placeholder="Enter your confirm password"
                onChange={handleChange}
                helperText={formErrors.confirm_password}
              />
            </div>
          </div>

          <div className="popup_save_btn">
            <button>Save</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MyAccountTapModal;
