import axios from "../axios";
import { errorMsg, getappenFormData, successMsg } from "./customFn";
// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

export const getCourseList = async (
  setState,
  setIsLoader,
  setcoursecount,
  setPagecount,
  currentPage,
  setHasMore,
  Selectchecked,
  searchCourse
) => {
  var offpage = currentPage;
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  var byname = "";
  var bysorting = "";

  if (searchCourse != undefined) {
    if (
      searchCourse.search_name != undefined &&
      searchCourse.search_name != ""
    ) {
     
      byname = searchCourse.search_name;
    }
    if (searchCourse.sort_price != undefined && searchCourse.sort_price != "") {
      bysorting = searchCourse.sort_price;
    }
  }

  try {
    const params = {
      offset: offpage,
      customer_id: user_id,
      password: user_password,
      category: Selectchecked,
      course_name: byname,
      sort: bysorting,
    };
    const response = await axios.post("/courses", params);
    if (response.data.status == true) {
      setcoursecount(response.data.course_count);
      if (response.data.data.length > 0) {
        if (offpage == 0) {
          setState(response.data.data);
        } else {
          setState((prevState) => [...prevState, ...response.data.data]);
        }

        setPagecount(response.data.page_count);
        setHasMore(true);
      } else {
        setHasMore(false);
        // setState([]);
      }
    } else {
      setHasMore(false);
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response.status === 401) {
    } else if (error.response.status === 402) {
    }
  }
};

export const getCourseDetail = async (
  id,
  setState,
  setIsLoader,
  setPlaylist,
  navigate
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      course_id: id,
    };

    const url = "/course_details";

    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        
        if (setPlaylist != undefined) {
          if (response.data.course_details.is_purchased == false) {
            navigate("/");
            errorMsg('Please purchase course')
          }
          if (
            response.data.course_details != undefined &&
            response.data.course_details.course_playlist != undefined &&
            response.data.course_details.course_playlist.length > 0
          ) {
            setPlaylist({
              chapter: "01",
              playlist_id:
                response.data.course_details.course_playlist[0].playlist_id,
              is_complete:
                response.data.course_details.course_playlist[0].is_complete,
              image: response.data.course_details.course_playlist[0].image,
              midea_type:
                response.data.course_details.course_playlist[0].midea_type,
              playlist_description:
                response.data.course_details.course_playlist[0].midea_type,
              playlist_title:
                response.data.course_details.course_playlist[0].playlist_title,
              video: response.data.course_details.course_playlist[0].video,
            });
          }
        }
        setState(response.data);
        
      } else {
       
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 2000);

    });

  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }
};

//Add Courses
export const addCourse = async (
  formData,
  navigate,
  setButtonLoader,
  cors_id
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData = getappenFormData(
      sendformData,
      formData.access_on_device,
      "access_on_device"
    );
    sendformData = getappenFormData(
      sendformData,
      formData.add_what_you_learn,
      "add_what_you_learn"
    );
    sendformData = getappenFormData(
      sendformData,
      formData.playlist_description,
      "playlist_description"
    );

    sendformData = getappenFormData(
      sendformData,
      formData.playlist_title,
      "playlist_title"
    );
    console.log("formData.image.name", formData.image.name);
    sendformData.append("description", formData.description);
    if (formData.image.name != undefined && formData.image.name != "") {
      sendformData.append("course_image", formData.image);
    }
    sendformData.append("sort_description", formData.sort_description);
    sendformData.append("tag_title", formData.tag_title);
    sendformData.append("title", formData.title);
    sendformData.append("price", formData.price);
    sendformData.append("category", formData.course_category);
    sendformData.append("course_duration", formData.courseduration);
    // sendformData.append(
    //   "playlist_title[]",
    //   JSON.stringify(formData.playlist_title)
    // );
    // sendformData.append(
    //   "playlist_description[]",
    //   JSON.stringify(formData.playlist_description)
    // );

    if (formData.playlist_image.length > 0) {
      for (let i = 0; i < formData.playlist_image.length; i++) {
        // console.log("formData.playlist_image[i].file",formData.playlist_image[i]);
        if (formData.playlist_image[i] != undefined) {
          sendformData.append("playlist_image[]", formData.playlist_image[i]);
        }
      }
    }

    if (formData.course_image.length > 0) {
      for (let i = 0; i < formData.course_image.length; i++) {
        if (formData.course_image[i] != undefined) {
          sendformData.append("course_media[]", formData.course_image[i]);
        }
      }
    }
    // console.log("JSON.stringify(formData.course_imgtitle)",JSON.stringify(formData.course_imgtitle))
    // console.log("JSON.stringify(formData.course_imgtitle)",formData.course_imgtitle)
    // sendformData.append("media_description[]", JSON.stringify(formData.course_imgtitle));
    sendformData = getappenFormData(
      sendformData,
      formData.course_imgtitle,
      "media_description"
    );

    if (cors_id != undefined && cors_id != "") {
      sendformData.append("id", cors_id);
    }

    // return
    const url = "/add/course";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        setTimeout(() => {
          setButtonLoader(false);
          navigate("/create-course");
        }, 1000);
      } else {
        errorMsg(response.data.message);
        setTimeout(() => {
          setButtonLoader(false);
        }, 1000);
      }
      // close();
    });
  } catch (error) {
    setTimeout(() => {
      setButtonLoader(false);
    }, 1000);
    // console.log("error", error);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg(error.response.message);
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncAskQuestion = async (id, formdata, setShow, setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const addformData = {
      customer_id: user_id,
      password: user_password,
      course_id: id,
      email: formdata.email,
      question: formdata.myquestion,
    };
    const response = await axios.post("/add_ask_question", addformData);

    if (response.data.status === true) {
      successMsg(response.data.message);
      setShow(false);

      // setPagecount(response.data.page_count);
    } else {
      errorMsg(response.data.message);
    }
  } catch (error) {
    if (error.response.status === 401) {
    } else if (error.response.status === 402) {
    }
  }
};

//Review Feedback
export const asyncEducatorFeedback = async (formdata, close) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      course_id: formdata.course_id,
      ratings: formdata.ratings,
      description: formdata.description,
    };
    const url = "/add_educator_feedback";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        close();
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Cousre Like
export const asyncCourseLike = async (formdata) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      course_id: formdata.course_id,
    };
    const url = "/course_like";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Add Cousre wishlist
export const asyncCourseWishlist = async (course_id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      course_id: course_id,
    };
    const url = "/course_wishlist";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//----------- my course list ------------
export const asyncgetMyCourse = async (setState, setIsLoad) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
    };

    const url = "/my_course";

    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
      }

      setTimeout(() => {
        setIsLoad(false);
      }, 1000);
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ---------- course include dropdown -------------
export const asyncdevicecourseInculde = async (setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
    };

    const url = "/course_includes";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Course Buy it noe
// checkout_course
export const asyncCourseBuyItnow = async (course_id, token, close, obj) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      course_id: course_id,
      token: token,
    };
    const url = "/checkout_course";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        successMsg(response.data.message);
        obj.setIsRender(!obj.isRender);
        setTimeout(() => {
          obj.setButtonLoader(false);
          close();
          obj.navigate("/buy_successfull/course");
          // obj.setLoadPage(true);
        }, 1000);
      } else {
        errorMsg(response.data.message);
      }
      setTimeout(() => {
        obj.setButtonLoader(false);
        obj.setLoadPage(false);
      }, 1000);
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
          setTimeout(() => {
            obj.setButtonLoader(false);
          }, 1000);
        } else {
          errorMsg("Somthing went wrong...");
          console.log(`${error.message}-${error.response.status}`);
          setTimeout(() => {
            obj.setButtonLoader(false);
          }, 1000);
        }
      }
    }
  }
};

// ---------- course category -------------
export const asyncgetcourseCategory = async (setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
    };

    const url = "/course_category";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Cousre Playlist Complete
export const asyncCoursePlayListComplete = async (
  course_id,
  playlistId,
  setPlayListID
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      course_id: course_id,
      playlist_id: playlistId,
    };

    const url = "/course_complete";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        setPlayListID((state) => [...state, response.data.playListID]);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncGetCourseWishlist = async (
  setWishList,
  setPagecount,
  currentPage,
  setIsLoader
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      offset: currentPage,
    };
    const url = "/my_course_wishlist";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setIsLoader(false);
        setWishList(response.data.data);
        setPagecount(response.data.page_count);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncDeleteCourseWishlist = async (
  wishListID,
  setDeleteCourse
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      id: wishListID,
    };
    const url = "/delete_course_wishlist";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        setDeleteCourse((prev) => (prev === 1 ? 0 : 1));
        // setPagecount(response.data.page_count);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- delete my course -------------
export const asyncDeleteMyCourse = async (id, setLoadPage, reRender) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("course_id", id);

    const url = "/delete_course";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        // reRender.isRerender(!reRender.setisRerender);
        successMsg(response.data.message);
        //   setLoadPage(true);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
