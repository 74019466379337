import React, { useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const ProductFilteMobile = (props) => {

  const {productcategory, handleCheckCategory , setMinPRice,setMaxPrice , applySearchproduct } = props;

  // const [sort, setSort] = useState("");

  // const handleChange = (event) => {
  //   setSort(event.target.value);
  // };

  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Root className="new_filter_drawer">
        <CssBaseline />
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              height: `calc(50% - ${drawerBleeding}px)`,
              overflow: "visible",
            },
          }}
        />

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: "absolute",
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <div className="filter_product">
              <Typography
                sx={{ p: 2, color: "text.secondary" }}
                className="filter-text"
              >
                {/* <span> 1 Applied</span> */}
                <p>Filter</p>
                {/* <button className="btn_clear">Clear</button> */}
              </Typography>
            </div>
          </StyledBox>

          <div className="filter_button_all new_filter_mob filter_desktop_main">
            <div className="filter_product_mobile">
              <div className="filter_product_accordion">
              
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="new-acc-main"
                  >
                    <Typography>Category</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="filter-details">
                    <Typography>
                       

                    <FormGroup className="nft-filter-sides">
                          {productcategory != undefined &&
                            productcategory.length > 0 &&
                            productcategory.map((value, index) => {
                              return (
                                <div className="all-input-filter">
                                  {/* <FormControlLabel control={<Checkbox />} label={value.title} /> */}
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleCheckCategory(e, value.id)
                                    }
                                    id={"category" + index}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"category" + index}
                                  >
                                    {value.name}
                                  </label>
                                </div>
                              );
                            })}
 

                          <div className="rang_heading">
                            <h3>Price</h3>

                            <div className="price_rang">
                              <div className="minimum_rang">
                                <input
                                  type="number"
                                  name="min_price"
                                  placeholder="Minimum"
                                  onChange={(e) => setMinPRice(e.target.value)}
                                />
                              </div>
                              <div className="minimum_rang">
                                <input
                                  type="number"
                                  name="max_price"
                                  placeholder="Maximum"
                                  onChange={(e) => setMaxPrice(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="apply_prodsearch">
                            <button
                              type="button"
                              onClick={(e) => {applySearchproduct(e); setOpen(false); } }
                            >
                              Apply
                            </button>
                          </div>
                        </FormGroup>

                      {/* <FormGroup className="nft-filter-sides">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Game Toys"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Game Accessories"
                        />

                        <div className="rang_heading">
                          <h3>Price</h3>

                          <div className="price_rang">
                            <div className="minimum_rang">
                              <input type="text" placeholder="minimum_rang" />
                            </div>
                            <div className="minimum_rang">
                              <input type="text" placeholder="minimum_rang" />
                            </div>
                          </div>
                        </div>
                      </FormGroup> */}
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
             
            </div>
          </div>
        </SwipeableDrawer>
      </Root>
    </div>
  );
};

ProductFilteMobile.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ProductFilteMobile;
