import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TabProfile from './TabProfile';

function FriendMobile(props) {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);



  const [isOpened, setIsOpened] = useState(false);
  // const handleShow = () => setIsOpened((wasOpened) => !wasOpened);
  
//   const handleShow = () => setShow(true);

  return (
    <>
    <div >
     <Modal
      {...props}
      size="lg"
      className='model-friendbox '
      aria-labelledby="contained-modal-title-vcenter"
      center
    >
     
        <TabProfile   onClose={props.onHide} />
  
    </Modal>
    </div>
    </>
  );
}

export default FriendMobile;