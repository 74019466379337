import React from "react";
import { images } from "../../actions/customFn";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

const Friends = (props) => {
  const { handleChange } = props;

  const { all_friends, total_friends } = useSelector(
    (state) => state.friendData
  );

  const navigate = useNavigate();

  const directpage = (path) => {
    navigate(`/${path}`);
  };

  return (
    <>
      <div className="friends-page">
        <div className="friend-top">
          <h4>
            Friends <span>({total_friends})</span>
          </h4>
          <div className="friend_view_all">
            <button onClick={() => directpage("profile/friends")}>
              View All
            </button>
          </div>
        </div>

        <div className="friend-data">
          <div className="friends_flexes">
            {all_friends && all_friends.length > 0 ? (
              all_friends.map((value, index) => (
                <div className="imag-friends" key={index}>
                  <NavLink to={`/friend_user_profile/${value.profile_id}`}>
                    <div
                      className="friend-img"
                      style={{
                        backgroundImage: `url(${value.image})`,
                      }}
                    ></div>
                  </NavLink>
                </div>
              ))
            ) : (
              <div className="not-found-message">No friends found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Friends;
