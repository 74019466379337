import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

export const asyncUserSettingUpdate = async (valueData) => {
  try {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    const formData = {
      customer_id: user_id,
      password: user_password,
      setting_title: valueData.setting_title,
      setting_value: valueData.setting_value,
    };
    const url = "/user_settings_update";
    const response  = await axios.post(url, formData);
    if(response){
      if (response.data.status == true) {
        successMsg(response.data.message);
        return true;
      } else {
        errorMsg(response.data.message);
        return false;
      }
    }
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
