import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { useSelector } from "react-redux";
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  LoadingIndicator,
  MessageInput,
  VirtualizedMessageList,
  Thread,
  Window,
} from "stream-chat-react";

import "stream-chat-react/dist/css/index.css";

// const userToken =
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoibGl0dGxlLXdvb2QtOSJ9.CqE75Jdcw_Gv2CySKWsgkGd2ECVe7dR3_Knxap5mlZg";

// const filters = { type: "messaging", members: { $in: ["little-wood-9"] } };
// TS tweak No1
const sort = { last_message_at: -1 } as const;

const App = () => {
  const { userInfo } = useSelector((state) => state.userAuth);
  // TS tweak No2
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [user_id, setUserId] = useState("");
  useEffect(() => {
    const client = StreamChat.getInstance("e4c9482xh76z");

    if (userInfo != null && userInfo.user_id !== undefined) {
      setUserId(userInfo.user_id);
      // console.log("userInfo", userInfo.user_id.toString());
      const userToken = userInfo.chat_stream_token;
      const initChat = async () => {
        await client.connectUser(
          {
            id: userInfo.user_id.toString(),
            name: userInfo.username,
            image: userInfo.image,
          },
          userToken
        );
        setChatClient(client);
      };

      initChat();
    }
  }, [userInfo]);

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <Chat client={chatClient}>
      <ChannelList
        filters={{ type: "messaging", members: { $in: [user_id.toString()] } }}
        sort={sort}
      />
      
      <Channel>
        <Window>
          <ChannelHeader />
          <VirtualizedMessageList
            additionalVirtuosoProps={{
              increaseViewportBy: { top: 400, bottom: 200 },
            }}
          />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default App;
