import React, { useEffect } from "react";
import { images, successMsg, icons } from "../../actions/customFn";
import { OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { useState } from "react";
import { asyncdoPostLike, getAllPost } from "../../actions/uploadBlogs";
import UploadPostModal from "../Modal/UploadPostModal";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom/dist";
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";

const TimelineAllpost = (props) => {
  const { allposts, setAllposts, currentPage, setCurrentPage } = props;
  const [show, setShow] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadPage, setLoadPage] = useState(false);

  const [uploadload, setUploadLoad] = useState(false);

  const [page_count, setPagecount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);

  const [emojicolor, setEmojiColor] = useState();

  const [likecount, setLikeCount] = useState([]);
  // const [likeshow, setLikeShow] = useState(false);
  useEffect(() => {
    if (currentPage == 0) {
      setIsLoader(true);
    }
    getAllPost(setAllposts, setPagecount, currentPage, setIsLoader, setHasMore);
  }, [currentPage, uploadload]);

  // const popoverClickRootClose = (
  //   <Popover id="popover-trigger-click-root-close" title="Popover bottom">
  //     <span>😄</span> <span>🥰</span> <span>😍</span>
  //     <span>😎</span>
  //   </Popover>
  // );

  const refresh = (setItems) => {
    getAllPost(setAllposts, setPagecount, 0, setIsLoader, setHasMore);
  };

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);

      getAllPost(
        setAllposts,
        setPagecount,
        currentPage + 1,
        setIsLoader,
        setHasMore
      );
    }, 2000);
  };

  const handlelikePost = (id, index) => {
    asyncdoPostLike(id).then((res) => {
      if (res) {
        console.log("res", res);

        let like_arr = [...allposts];

        like_arr[index].is_blog_like = !like_arr[index].is_blog_like;
        setEmojiColor(!emojicolor);

        console.log(
          "like_arr[index].is_blog_like",
          like_arr[index].is_blog_like
        );
        // if (likecount.includes(id)) {
        if (like_arr[index].is_blog_like == false) {
          like_arr[index].count_blog_like = like_arr[index].count_blog_like - 1;
          setLikeCount(likecount.filter((value) => value !== id));
        }

        // } else {
        if (like_arr[index].is_blog_like == true) {
          like_arr[index].count_blog_like = like_arr[index].count_blog_like + 1;
          setLikeCount((state) => [...state, id]);
        }

        // }
        setAllposts(like_arr);
      }
    });
  };

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    successMsg("Link copied to Clipboard");
  }

  const reactRatings = {
    edit: false,
    activeColor: "#faaf00",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {isLoader == true ? (
        <Loader />
      ) : (
        <>
          <div className="timelinepost my_post">
            <div className="timep-upload">
              <div className=" ">
                <h2>Upload Post</h2>
              </div>
              <div className="">
                <button
                  type="button"
                  className="upload_btn"
                  onClick={handleShow}
                >
                  Upload
                </button>
              </div>
            </div>
            <div className="tab_showscroll p-2" id="scrollableDiv">
              {allposts != undefined && allposts.length > 0 ? (
                <InfiniteScroll
                  dataLength={allposts.length}
                  next={handleLoadMore}
                  hasMore={hasMore}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv"
                  refreshFunction={refresh}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      Pull down to refresh
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
                  }
                >
                  <div className="row">
                    {allposts.map((value, index) => {
                      return (
                        <>
                          <div className="col-md-6 mypost_one" key={index}>
                            <NavLink
                              to={`/friend_user_profile/${value.customer_id}`}
                            >
                              <div className="timeline_post">
                                <img src={value.author_image} alt="" />
                                <h6>{value.author_name}</h6>
                              </div>
                            </NavLink>
                            <div className="post_mincont">
                              <h2>{value.title}</h2>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></span>{" "}
                                {/* <button>Read More</button>{" "} */}
                              </p>
                            </div>
                            <div className="timepost_main">
                              {value.media_type === "video" ? (
                                <ReactPlayer
                                  url={value.video}
                                  controls
                                  width="100%"
                                  height="auto"
                                />
                              ) : (
                                <img src={value.image} alt="" />
                              )}
                              <ul className="tap-icons">
                                <li>
                                  <button
                                    onClick={() => {
                                      handlelikePost(value.id, index);
                                    }}
                                  >
                                    <img
                                      src={
                                        value.is_blog_like == true
                                          ? icons["like.svg"]
                                          : icons["like-outline.svg"]
                                      }
                                      alt="heart"
                                    />
                                  </button>
                                  <span>{value.count_blog_like}</span>
                                </li>
                                <li>
                                  <button>
                                    <img
                                      src={icons["message.svg"]}
                                      alt="message"
                                    />
                                  </button>
                                  <span>500</span>
                                </li>
                                <li>
                                  <button>
                                    <img
                                      src={icons["save-outline.svg"]}
                                      alt="save"
                                    />
                                  </button>
                                  <span>200</span>
                                </li>
                                <li>
                                  <button onClick={copy}>
                                    <img src={icons["share.svg"]} alt="share" />
                                  </button>
                                  <span>300</span>
                                </li>
                                <li>
                                  <button>
                                    <img
                                      src={icons["backet.svg"]}
                                      alt="backet"
                                    />
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="tab-bottom">
                              <div>
                                <ReactStars size={12} value={5} />
                              </div>
                              <div className="right-sec">
                                <div className="tap-price">
                                  <span>$</span>175
                                </div>
                                <button className="tap-buy-btn">Buy now</button>
                              </div>
                            </div>
                            {/* <div className="post_mincont">
                              <div className="timepost-likes">
                                <h4>{value.date}</h4>
                                <div className="likes_butn">
                                  <h6>{value.count_blog_like} </h6>{" "}
                                  <Button
                                    onClick={() => {
                                      handlelikePost(value.id, index);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        style={{
                                          fill: `${
                                            value.is_blog_like == true
                                              ? "#FF0098"
                                              : "#fff"
                                          }`,
                                        }}
                                        d="M3.33334 17.5001H4.16667V6.66675H3.33334C2.89131 6.66675 2.46739 6.84234 2.15483 7.1549C1.84227 7.46747 1.66667 7.89139 1.66667 8.33342V15.8334C1.66667 16.2754 1.84227 16.6994 2.15483 17.0119C2.46739 17.3245 2.89131 17.5001 3.33334 17.5001ZM16.6667 6.66675H10.8333L11.7683 3.86008C11.8518 3.60959 11.8745 3.34287 11.8346 3.08188C11.7948 2.82089 11.6935 2.57309 11.5392 2.35891C11.3848 2.14473 11.1817 1.97028 10.9467 1.84994C10.7117 1.72961 10.4515 1.66682 10.1875 1.66675H10L5.83334 6.19842V17.5001H15L18.26 10.3367L18.3333 10.0001V8.33342C18.3333 7.89139 18.1577 7.46747 17.8452 7.1549C17.5326 6.84234 17.1087 6.66675 16.6667 6.66675Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Button>
                                  <button
                                    type="button"
                                    className="sharepost_butn"
                                    onClick={copy}
                                  >
                                    <img
                                      src={images["share_post.png"]}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              ) : (
                <RecordNotfound />
              )}
            </div>
            {/* ------------------------- */}

            {/* <div className="load_btn">
              {allposts.page_count > 1 ? (
                <button
                  type="button"
                  onClick={() => {
                    allposts.loadMore();
                  }}
                  className="btn_load_more"
                  data-loading-text="<i class='fa fa-spinner fa-spin '></i> Processing Order"
                >
                  {allposts.page_loading == true ? (
                    <>
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Load More
                    </>
                  ) : (
                    "Load More"
                  )}
                </button>
              ) : (
                <button
                  onClick={() => {
                    allposts.loadMore();
                  }}
                  className="btn_load_more"
                >
                  No More Product
                </button>
              )}
            </div> */}
            <UploadPostModal
              show={show}
              setLoadPage={setLoadPage}
              setShow={setShow}
              close={handleClose}
              setUploadLoad={setUploadLoad}
            />
          </div>
        </>
      )}
    </motion.div>
  );
};

export default TimelineAllpost;
