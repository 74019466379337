import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";

import {
  CourseHistory,
  FooterBig,
  FooterMobile,
  Header,
  NftHistory,
} from "../components";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import {
  asycCourseOrderHistory,
  asycOrderHistory,
  asycProductOrderHistory,
} from "../actions/orderAction";
import ProductHistory from "../components/orderhistory/ProductHistory";
import { getProductCategory } from "../actions/productAction";

const OrderHistory = () => {
  // const [isLoader, setIsLoader] = useState(false);
  const [value, setValue] = useState(0);
  // const [orderslist, setOrdersList] = useState({
  //   nft: [],
  //   products: [],
  //   courses: [],
  // });

  const [courseorder, setCourseOrder] = useState();
  // const [ courseload,setCourseload] = useState(false);
  const [productorder, setProductOrder] = useState();
  // const [productloader, setProductLoader] = useState();

  const [searchcourse, setSearchCourse] = useState();
  const [searchproduct, setSearchProduct] = useState();

  // ----------- product category ------------
  const [productcategory, setProductCategory] = useState();
  const [isLoader, setIsLoader] = useState(false);

  const [coursecurrentPage, setCourseCurrentPage] = useState(0);
  const [coursepage_count, setCoursePagecount] = useState(0);

  const [productcurrentPage, setProductCurrentPage] = useState(0);
  const [productpage_count, setProductPagecount] = useState(0);

  useEffect(() => {
    setIsLoader(true);
    asycCourseOrderHistory(
      setCourseOrder,
      setIsLoader,
      searchcourse,
      coursecurrentPage,
      setCoursePagecount
    );
  }, [searchcourse, coursepage_count, coursecurrentPage]);

  useEffect(() => {
    setIsLoader(true);
    asycProductOrderHistory(
      setProductOrder,
      setIsLoader,
      searchproduct,
      productcurrentPage,
      setProductPagecount
    );
  }, [searchproduct, productpage_count, productcurrentPage]);

  // ----------- product category ------------
  useEffect(() => {
    getProductCategory(setProductCategory);
  }, []);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // console.log("searchproduct#=", searchproduct);
  const handleClick = (index) => {
    setValue(index);
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="all_page_bg order-history-page">
          <div className="container-fluid">
            <Header />
            <div className="page-all-data orderhistory">
              <div>
                <div className="tab_orderhistory">
                  <h1 className="heading">Order History</h1>
                  <div className="orderhistory_tab">
                    <ul>
                      <li>
                        <button
                          className={value === 0 && "active"}
                          onClick={() => handleClick(0)}
                        >
                          Courses
                        </button>
                      </li>
                      <li>
                        <button
                          className={value === 1 && "active"}
                          onClick={() => handleClick(1)}
                        >
                          NFT
                        </button>
                      </li>
                      <li>
                        <button
                          className={value === 2 && "active"}
                          onClick={() => handleClick(2)}
                        >
                          Products
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="orderhistory_scrolls coursehist_scroll">
                    {value === 0 && (
                      <CourseHistory
                        coursesList={
                          courseorder != undefined && courseorder.courses
                        }
                        isLoader={isLoader}
                        page_count={coursepage_count}
                        setCurrentPage={setCourseCurrentPage}
                        currentPage={coursecurrentPage}
                        setSearchCourse={setSearchCourse}
                      />
                    )}
                    {value === 1 && <NftHistory />}
                    {value === 2 && (
                      <ProductHistory
                        orderProductList={
                          productorder != undefined && productorder
                        }
                        isLoader={isLoader}
                        page_count={productpage_count}
                        setCurrentPage={setProductCurrentPage}
                        setSearchProduct={setSearchProduct}
                        currentPage={productcurrentPage}
                        productcategory={productcategory}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
            <FooterBig />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default OrderHistory;
