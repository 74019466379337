import React from "react";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillLike,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  IoChevronDown,
  IoSearch,
  IoChevronUp,
  IoImagesOutline,
} from "react-icons/io5";
import {
  FaGraduationCap,
  FaPlay,
  FaRegQuestionCircle,
  FaUsers,
  FaBell,
  FaDownload,
  FaUserCircle,
} from "react-icons/fa";
import {
  FaPause,
  FaRepeat,
  FaShuffle,
  FaCheck,
  FaRegTrashCan,
  FaArrowLeft,
} from "react-icons/fa6";
import { SlArrowUpCircle } from "react-icons/sl";
import { LuRepeat2 } from "react-icons/lu";
import { BiMessageRounded } from "react-icons/bi";
import {
  MdDateRange,
  MdSkipNext,
  MdSkipPrevious,
  MdOutlinePlaylistAdd,
  MdOutlineKeyboardArrowLeft,
  MdDeleteOutline,
} from "react-icons/md";
import {
  PiDotsThreeOutlineVerticalFill,
  PiDotsThreeOutlineFill,
  PiWechatLogoFill,
} from "react-icons/pi";
import {
  IoMdClose,
  IoIosHeartEmpty,
  IoMdHeart,
  IoMdShare,
  IoMdStar,
} from "react-icons/io";
import { RiMusicFill } from "react-icons/ri";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { HiUserAdd } from "react-icons/hi";
import { CiCamera } from "react-icons/ci";
import { GoHeart } from "react-icons/go";

export const reactIcons = {
  eyeslash: <AiFillEyeInvisible />,
  eyes: <AiFillEye />,
  chevronDown: <IoChevronDown />,
  chevronUp: <IoChevronUp />,
  search: <IoSearch />,
  graduationCap: <FaGraduationCap />,
  circleUp: <SlArrowUpCircle />,
  repeat: <LuRepeat2 />,
  message: <BiMessageRounded />,
  menuVertical: <PiDotsThreeOutlineVerticalFill />,
  menuHorizontal: <PiDotsThreeOutlineFill />,
  calender: <MdDateRange />,
  play: <FaPlay />,
  pause: <FaPause />,
  close: <IoMdClose />,
  music: <RiMusicFill />,
  prev: <MdSkipPrevious />,
  next: <MdSkipNext />,
  repeat2: <FaRepeat />,
  shuffle: <FaShuffle />,
  speaker: <HiMiniSpeakerWave />,
  check: <FaCheck />,
  emptyHeart: <IoIosHeartEmpty />,
  heart: <IoMdHeart />,
  share: <IoMdShare />,
  thumbsUp: <AiFillLike />,
  ques: <FaRegQuestionCircle />,
  users: <FaUsers />,
  bell: <FaBell />,
  addUser: <HiUserAdd />,
  delete: <FaRegTrashCan />,
  camera: <CiCamera />,
  leftArr: <FaArrowLeft />,
  download: <FaDownload />,
  addPlaylist: <MdOutlinePlaylistAdd />,
  images: <IoImagesOutline />,
  star: <IoMdStar />,
  chat: <PiWechatLogoFill />,
  user: <FaUserCircle />,
  // search: <IoSearchOutline />,
  add: <AiOutlinePlus />,
  heartOutline: <GoHeart />,
  arrowLeft: <MdOutlineKeyboardArrowLeft />,
  deleteNew: <MdDeleteOutline />,
};
