import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

export const getPrivacyPolicy = async (setPrivacyCurrency) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
    };

    const url = "/privacy-policy";

    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setPrivacyCurrency(response.data.data);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};


 
