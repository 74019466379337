import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

export const asyncUploadBlog = async (
  formdata,
  setShow,
  setIsLoader,
  setButtonLoader,
  isRenderState
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    // const formData = {
    //   customer_id: user_id,
    //   password: user_password,
    //   image: formdata.image,
    //   title: formdata.title,
    //   description: formdata.description,
    // };

    const formData = new FormData();
    formData.append("image", formdata.image);
    formData.append("customer_id", user_id);
    formData.append("password", user_password);
    formData.append("title", formdata.title);
    formData.append("description", formdata.description);
    const url = "/upload/blog";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        // setState(response.data);
        successMsg(response.data.message);
        setTimeout(() => {
          setButtonLoader(false);
          setShow(false);
          setIsLoader(true);
        }, 1000);
        isRenderState.setIsRender(!isRenderState.isRender)
        // setLoadPage(true);
      } else {
        errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getMyPostList = async (
  setState,
  setIsLoader,
  currentPage,
  setHasMore,
  setBlogcount
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      offset: currentPage,
    };
    const response = await axios.post("/my_blogs", params);
    if (response.data.status === true) {
      // setState(response.data.data);
      if (response.data.data.length > 0) {
        setState((prevState) => [...prevState, ...response.data.data]);
        setBlogcount(response.data.count)
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getAllPost = async (
  setState,
  setPagecount,
  currentPage,
  setIsLoader,
  setHasMore
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      offset: currentPage,
    };
    const response = await axios.post("/all_blogs", params);
    if (response.data.status) {
      if (response.data.data.length > 0) {
        setState((prevState) => [...prevState, ...response.data.data]);
        setPagecount(response.data.page_count);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------ like post -----
export const asyncdoPostLike = async (post_id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      blog_id: post_id,
    };
    const response = await axios.post("/blog_like", params);
    if (response.data.status === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Freind Detail
// Freind POst

export const getFreindPostList = async (
  setState,
  setIsLoader,
  currentPage,
  setHasMore,
  user_id
) => {
  const customer_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: customer_id,
      user_id    : user_id,
      password   : user_password,
      offset     : currentPage,
    };
    const response = await axios.post("/get_user_blogs_list", params);
    if (response.data.status === true) {
      // setState(response.data.data);
      if (response.data.data.length > 0) {
        setState((prevState) => [...prevState, ...response.data.data]);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------ friend profile -----
export const asyncgetFriendProfile = async (id, setFriendProfile) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      user_id: id,
    };
    const response = await axios.post("/get_user_freind_profile", params);
    if (response.data.status === true) {
      setFriendProfile(response.data.data);

      return true;
    } else {
      // return false;
    }
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- delete my post ---------
export const asyncDeleteMyPost = async (id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("blog_id", id);
    const url = "/delete_blog";

    const response = await axios.post(url, sendformData)
      if (response.data.status === true) {
        successMsg(response.data.message);
        // reRender.setIsRender(!reRender.isRerender);
        // setCurrentPage(0);
        //   setLoadPage(true);
        return true;
      } else {
        errorMsg(response.data.message);
        return false;
      }
    // });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
