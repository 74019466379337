import React, { useEffect } from "react";
import { images } from "../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import { getAllPhotosList } from "../../actions/uploadPhoto";

const data = [
  { image: images["photo_pic1.png"] },
  { image: images["photo_pic2.png"] },
  { image: images["photo_pic3.png"] },
  { image: images["photo_pic4.png"] },
  { image: images["photo_pic5.png"] },
  { image: images["photo_pic6.png"] },

  { image: images["photo_pic1.png"] },
  { image: images["photo_pic2.png"] },
  { image: images["photo_pic3.png"] },
  { image: images["photo_pic4.png"] },
  { image: images["photo_pic5.png"] },
  { image: images["photo_pic6.png"] },

];

const Photos = () => {
  
  const dispatch = useDispatch();
  const { all_photos } = useSelector((state) => state.photosData);

  return (
    <>
      <div className="photos-page">
        <div className="photos-top">
          <h4>
            Photos <span>( {all_photos.length} )</span>
          </h4>
        </div>

        <div className="photos-data">
          <div className="photodiss-new">
            {all_photos.length > 0 ?  all_photos.map((value, index) => {
              return (
                // <div
                //   className="photoflex-dis-main"
                //   key={index}
                // >
                  <div className="photoimg-out" key={index}>
                    <div
                      className="photos-img"
                      style={{
                        backgroundImage: `url(${value.photo})`,
                      }}
                    ></div>
                  </div>
                // {/* </div> */}
              );
            })  : <h2 >Record Not Found</h2> }
          </div>
        </div>
      </div>
    </>
  );
};

export default Photos;
