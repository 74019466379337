import React from "react";
import RecordNotfound from "../Home/RecordNotfound";

const NftHistory = () => {
  return (
    <>
      <div className="nfthistory">
        <RecordNotfound/>
      </div>
    </>
  );
};

export default NftHistory;
