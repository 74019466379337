import React from "react";

export const SendIcon = () => (
  // <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //   <path
  //     fillRule='evenodd'
  //     clipRule='evenodd'
  //     d='M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10ZM6 9H10V6L14 10L10 14V11H6V9Z'
  //     fill='white'
  //   />
  // </svg>
  <div className="search_chat_butonarrow">
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6534 19.3622L0.347656 3.24414L24.1407 0.25258L9.6534 19.3622ZM9.92332 15.1768L18.9527 3.24787L4.10724 5.10305L6.14287 8.62887L13.0595 6.65028L7.88769 11.651L9.92332 15.1768Z"
        fill="white"
      />
    </svg>
  </div>
);
