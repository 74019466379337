import React from "react";
import OwlCarousel from "react-owl-carousel";
import { images } from "../../actions/customFn";

const SliderBox = () => {
  const slider_list = [
    {
      image: images["slider-1.png"],
    },
    {
      image: images["slider-1.png"],
    },
    {
      image: images["slider-1.png"],
    },
  ];

  const slider = {
    loop: true,
    margin: 10,
    nav: false,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="slider_box_main new_bevel">
        <div className=" slider_inner">
          <OwlCarousel {...slider} className="owl-theme slider_main">
            {slider_list.map(function(value, index) {
              return <img src={images["slider-1.png"]} alt="" key={index} />;
            })}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default SliderBox;
