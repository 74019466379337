import React from 'react';
import { images } from "../../actions/customFn";

const picsdata = [
{  pics: images["chapter_pics1.png"] },
{  pics: images["chapter_pics2.png"] },
{  pics: images["chapter_pics3.png"] },
{  pics: images["chapter_pics4.png"] },
]

const CourseImages = () => {
  return (
    <>
        
        <div className='chapter_images'>

<h5> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_2628_181)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0021 6.99903H4.50012C4.1686 6.99903 3.85065 7.13072 3.61623 7.36514C3.38181 7.59957 3.25012 7.91751 3.25012 8.24903V18.249C3.25012 18.4132 3.28245 18.5757 3.34527 18.7274C3.40809 18.879 3.50016 19.0168 3.61623 19.1329C3.73231 19.249 3.87011 19.3411 4.02176 19.4039C4.17342 19.4667 4.33596 19.499 4.50012 19.499H17.0001C17.1643 19.499 17.3268 19.4667 17.4785 19.4039C17.6301 19.3411 17.7679 19.249 17.884 19.1329C18.0001 19.0168 18.0921 18.879 18.155 18.7274C18.2178 18.5757 18.2501 18.4132 18.2501 18.249V8.24903C18.2501 7.91785 18.1187 7.60021 17.8847 7.36585C17.6507 7.13149 17.3333 6.99956 17.0021 6.99903ZM4.50012 5.74903C4.17139 5.74837 3.84576 5.81255 3.54187 5.93789C3.23798 6.06324 2.96179 6.24728 2.72912 6.47949C2.49644 6.7117 2.31184 6.98752 2.1859 7.29116C2.05995 7.5948 1.99512 7.9203 1.99512 8.24903L1.99512 18.249C1.99512 18.5773 2.05978 18.9024 2.18542 19.2057C2.31106 19.5091 2.4952 19.7846 2.72735 20.0168C2.9595 20.2489 3.23509 20.4331 3.53841 20.5587C3.84172 20.6844 4.16681 20.749 4.49512 20.749H16.9951C17.3234 20.749 17.6485 20.6844 17.9518 20.5587C18.2551 20.4331 18.5307 20.2489 18.7629 20.0168C18.995 19.7846 19.1792 19.5091 19.3048 19.2057C19.4305 18.9024 19.4951 18.5773 19.4951 18.249V8.24903C19.4951 7.58599 19.2317 6.9501 18.7629 6.48126C18.294 6.01242 17.6582 5.74903 16.9951 5.74903H4.50012Z" fill="white"/>
    <path d="M15.309 12.8082C15.4018 12.7157 15.5214 12.6547 15.6508 12.6339C15.7802 12.6131 15.9128 12.6335 16.03 12.6922L18.251 15.1262V19.5002H3.25098V18.2502L6.55898 15.3072C6.66105 15.2054 6.79515 15.1421 6.93857 15.1279C7.08199 15.1136 7.22592 15.1494 7.34598 15.2292L10.671 17.4452L15.309 12.8072V12.8082Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.62605 13.25C7.99689 13.25 8.3594 13.1401 8.66774 12.934C8.97608 12.728 9.21641 12.4352 9.35832 12.0926C9.50024 11.7499 9.53737 11.3729 9.46502 11.0092C9.39267 10.6455 9.2141 10.3114 8.95187 10.0492C8.68965 9.78697 8.35556 9.6084 7.99184 9.53605C7.62813 9.4637 7.25113 9.50084 6.90852 9.64275C6.5659 9.78466 6.27307 10.025 6.06704 10.3333C5.86101 10.6417 5.75105 11.0042 5.75105 11.375C5.75105 11.8723 5.94859 12.3492 6.30022 12.7008C6.65185 13.0525 7.12877 13.25 7.62605 13.25ZM6.99805 4.49902H19.498C19.8296 4.49902 20.1475 4.63072 20.3819 4.86514C20.6164 5.09956 20.748 5.4175 20.748 5.74902V15.749C20.748 16.0805 20.6164 16.3985 20.3819 16.6329C20.1475 16.8673 19.8296 16.999 19.498 16.999V18.249C20.1611 18.249 20.797 17.9856 21.2658 17.5168C21.7347 17.0479 21.998 16.4121 21.998 15.749V5.74902C21.998 5.08598 21.7347 4.4501 21.2658 3.98126C20.797 3.51242 20.1611 3.24902 19.498 3.24902H6.99805C6.33501 3.24902 5.69912 3.51242 5.23028 3.98126C4.76144 4.4501 4.49805 5.08598 4.49805 5.74902H5.74805C5.74805 5.4175 5.87974 5.09956 6.11416 4.86514C6.34858 4.63072 6.66653 4.49902 6.99805 4.49902Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2628_181">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg> Images</h5>

<div className='chapter_pics'>
    <div className='row'>
{
picsdata.map((value,index)=>{
    return(
        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12' key={index}>
        <img src={value.pics} alt="" />
        <p>Lorem Ipsum is simply dummy text of the printing</p>
    </div>
    )
})
}
       

    </div>
</div>

</div>

    </>
  )
}

export default CourseImages;