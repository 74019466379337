import React, { useEffect, useState } from "react";

import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Apps from "@mui/icons-material/Apps";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const FooterMobile = () => {
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [groupList, setGroupList] = useState([]);
  // const directpage = (path) => {
  //   navigate(`/${path}`);
  // };
  const { groups_list } = useSelector((state) => state.chatData);

  useEffect(() => {
    if (groups_list.length > 0) {
      setGroupList(groups_list);
    }
  }, [groups_list]);

  return (
    <div className="footer-mobile-new">
      <Box>
        <IconButton
          ref={buttonRef}
          id="apps-menu-demo"
          aria-label="Applications"
          aria-controls={"apps-menu"}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="plain"
          color="neutral"
          onClick={() => {
            setOpen(!open);
          }}
          sx={{ borderRadius: 40 }}
        >
          <Apps />
        </IconButton>
        <Menu
          id="apps-menu"
          variant="solid"
          invertedColors
          anchorEl={buttonRef.current}
          open={open}
          onClose={handleClose}
          aria-labelledby="apps-menu-demo"
          className="footer-inner-menu"
          sx={{
            "--List-padding": "0.5rem",
            "--ListItemDecorator-size": "3rem",
            display: "grid",
            gridTemplateColumns: "repeat(3, 100px)",
            gridAutoRows: "100px",
            gap: 1,
          }}
        >
          <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="/challenges" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>C</Avatar>
              </ListItemDecorator>
              Challenges
            </NavLink>
          </MenuItem>
          <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="#" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>C</Avatar>
              </ListItemDecorator>
              Creative Corner
            </NavLink>
          </MenuItem>
          <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="#" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>G</Avatar>
              </ListItemDecorator>
              Global Village
            </NavLink>
          </MenuItem>
          <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="#" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>T</Avatar>
              </ListItemDecorator>
              The Spa
            </NavLink>
          </MenuItem>
          <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="#" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>E</Avatar>
              </ListItemDecorator>
              Eco Hub
            </NavLink>
          </MenuItem>
          <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="#" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>T</Avatar>
              </ListItemDecorator>
              TalntX
            </NavLink>
          </MenuItem>

          {/* <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>C</Avatar>
              </ListItemDecorator>
              Creative Corner
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="" className="footer-new-menu">
            
              <ListItemDecorator>
                <Avatar>I</Avatar>
              </ListItemDecorator>
              {groupList.length > 0
              ? groupList.slice(0, 5).map((value, index) => {
                  return (
                    <div key={index} >
                      <NavLink to="/chat">{value.name}</NavLink>
                    </div>
                  );
                })
              : ""}
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>M</Avatar>
              </ListItemDecorator>
              Global Village
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>I</Avatar>
              </ListItemDecorator>
              The Spa
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="" className="footer-new-menu">
              <ListItemDecorator>
                <Avatar>C</Avatar>
              </ListItemDecorator>
              Eco Hub
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem orientation="vertical" onClick={handleClose}>
            <NavLink to="" className="footer-new-menu">
              {" "}
              <ListItemDecorator>
                <Avatar>S</Avatar>
              </ListItemDecorator>
              TalntX
            </NavLink>
          </MenuItem> */}
        </Menu>
      </Box>
    </div>
  );
};

export default FooterMobile;
