import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { NavLink, useNavigate } from "react-router-dom";
import { FooterBig, Header } from "../components";
import { TextField, IconButton, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import nextId from "react-id-generator";

// import  { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchIcon from "@mui/icons-material/Search";
import OwlCarousel from "react-owl-carousel";
import FooterMobile from "../components/Home/FooterMobile";
import { motion } from "framer-motion";
import {
  asyncGetBayPageSearch,
  asyncGetPagesData,
} from "../actions/pageAction";
import ReactStars from "react-rating-stars-component";
import { reactIcons } from "../utils/icons";
import { isVideo } from "../utils/formatter";
import { Button } from "react-bootstrap";
import { freshlyData, viralVideoData } from "../utils/data";

const BayPage = () => {
  const [HomeDetail, setHomeDetail] = useState({});
  const [searchBay, setSearchBay] = useState("");
  const [baydata, setBaydata] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const searchItem = (e, option) => {
    const { id, type } = option;
    setTimeout(() => {
      if (type == "product") {
        navigate(`/product-details/${id}`);
      } else if (type == "course") {
        navigate(`/courses-detail/${id}`, { state: { type: type } });
      }
    }, 1000);
  };

  const navigate = useNavigate();
  const directpage = (path) => {
    navigate(`/${path}`);
  };

  // Home Page Api
  useEffect(() => {
    asyncGetPagesData(6, setHomeDetail);
  }, []);

  const reactRatings = {
    edit: false,
    activeColor: "#faaf00",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  const experience = {
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },

      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    if (searchBay != undefined && searchBay.length >= 3) {
      asyncGetBayPageSearch(searchBay, setBaydata);
    }
  }, [searchBay]);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg courses_detail_bg_color ">
        <div className="container-fluid">
          <Header />

          {/* ---banner--- */}

          <div className="page-all-data">
            <div className="thebay_main_page">
              {HomeDetail.home_slider !== undefined && (
                <div
                  className="courses_detail_banner"
                  // style={{
                  //   backgroundImage: `url(${HomeDetail.home_slider.course_image})`,
                  // }}
                >
                  <div className="main_bg_overlay thebay_search">
                    <div className="input_search_center bay_searchpart">
                      <div className="input_search_new">
                        <Autocomplete
                          open={searchBay.length >= 3}
                          options={baydata}
                          // value={searchBay}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => searchItem(e, option)}
                          className="search-all"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // value={searchBay}
                              clearOnBlur={true}
                              onChange={(e) => setSearchBay(e.target.value)}
                              placeholder="Search Product and Course"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <IconButton type="submit" aria-label="search">
                                    <SearchIcon />
                                  </IconButton>
                                ),
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="the_bay_banner">
                      {HomeDetail.home_slider.category_title != undefined &&
                      HomeDetail.home_slider.category_title != "" ? (
                        <NavLink
                          to={`/courses-category/${HomeDetail.home_slider.category_id}`}
                        >
                          <button className="main_banner_d3_btn">
                            {HomeDetail.home_slider.category_title}
                          </button>
                        </NavLink>
                      ) : (
                        ""
                      )}

                      <h1>{HomeDetail.home_slider.course_title}</h1>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: HomeDetail.home_slider.course_description,
                        }}
                      ></span>

                      <div className="courses_detail_rating">
                        <div className="prd_star_rating ">
                          <ul>
                            <ReactStars
                              {...reactRatings}
                              size={12}
                              value={Number(HomeDetail.home_slider.rating)}
                            />
                            <li className="star_point">
                              {HomeDetail.home_slider.rating}
                            </li>
                          </ul>
                        </div>

                        {/* <h3>13 Lectures</h3> */}
                      </div>

                      <div className="latestblogs_right_profile1_main">
                        <div className="courses_banner_profile">
                          <div className="latestblogs_right_profile1_left">
                            <div className="right_profile_left_bg">
                              <img
                                src={HomeDetail.home_slider.author_image}
                                alt=""
                              />
                            </div>
                            <span>{HomeDetail.home_slider.author_name} </span>
                          </div>

                          {HomeDetail.home_slider.total_students !=
                            undefined && (
                            <div className="coures_total_student">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M21 18.2188C21 18.3626 20.9443 18.4871 20.833 18.5923C20.7217 18.6974 20.5898 18.75 20.4375 18.75C20.2852 18.75 20.1533 18.6974 20.042 18.5923C19.9307 18.4871 19.875 18.3626 19.875 18.2188V11.2295L13.6699 14.168C13.2012 14.3893 12.6445 14.5 12 14.5C11.3555 14.5 10.7988 14.3893 10.3301 14.168L3.68555 11.0303C3.22852 10.8089 3 10.5461 3 10.2417C3 9.93734 3.22852 9.68001 3.68555 9.46973L10.3301 6.33203C10.7988 6.11068 11.3555 6 12 6C12.6445 6 13.2012 6.11068 13.6699 6.33203L20.3145 9.46973C20.7129 9.65788 20.9355 9.8903 20.9824 10.167C20.9941 10.1781 21 10.2002 21 10.2334V18.2188ZM14.2324 15.2305L17.625 13.6201V15.9609C17.625 16.4368 17.0771 16.8436 15.9814 17.1812C14.8857 17.5187 13.5586 17.6875 12 17.6875C10.4414 17.6875 9.11426 17.5187 8.01855 17.1812C6.92285 16.8436 6.375 16.4368 6.375 15.9609V13.6201L9.76758 15.2305C10.2363 15.4518 10.9805 15.5625 12 15.5625C13.0195 15.5625 13.7637 15.4518 14.2324 15.2305Z"
                                    fill="#FF0098"
                                  />
                                </svg>
                              </span>
                              <div className="coures_total_student_text">
                                ({HomeDetail.home_slider.total_students}){" "}
                                <span> Students</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="latestblogs_right_profile1_right">
                          <NavLink className="Add_wishlist_btn">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_407_637)">
                                  <path
                                    d="M14.5832 1.59741C13.6448 1.61201 12.7269 1.87396 11.9222 2.3568C11.1174 2.83965 10.4544 3.5263 9.99988 4.34741C9.54542 3.5263 8.88233 2.83965 8.07758 2.3568C7.27283 1.87396 6.35493 1.61201 5.41655 1.59741C3.92067 1.6624 2.51131 2.31679 1.49637 3.41761C0.481433 4.51842 -0.0565749 5.97618 -0.000116138 7.47241C-0.000116138 11.2616 3.98822 15.3999 7.33322 18.2057C8.08007 18.8334 9.02435 19.1774 9.99988 19.1774C10.9754 19.1774 11.9197 18.8334 12.6665 18.2057C16.0115 15.3999 19.9999 11.2616 19.9999 7.47241C20.0563 5.97618 19.5183 4.51842 18.5034 3.41761C17.4885 2.31679 16.0791 1.6624 14.5832 1.59741Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_407_637">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>

                            <h2>Add to wishlist</h2>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="thebay_slider_section">
            {/* ********* Recent Post Start******** */}
            {/* {HomeDetail.recent_posts != undefined &&
            HomeDetail.recent_posts.length > 0 ? (
              <div className="top_post_section_card">
                <div className="many-courses">
                  {HomeDetail.recent_post_title !== undefined && (
                    <div className="many-courses_heading">
                      <h5>{HomeDetail.recent_post_title}</h5>

                      <button onClick={() => directpage("profile")}>
                        View All
                      </button>
                    </div>
                  )}

                  {HomeDetail.recent_posts !== undefined && (
                    <OwlCarousel
                      key={`carousel_top_post_${Date.now()}`}
                      {...experience}
                      className="owl-theme"
                      nav
                      loop
                      margin={10}
                    >
                      {HomeDetail.recent_posts?.map((value, index) => {
                        return (
                          <NavLink to="/profile" key={index}>
                            <div className="item new-slider-post" key={index}>
                              <div
                                className="course-list1"
                                style={{
                                  backgroundImage: `url(${value.image})`,
                                }}
                              >
                                <div className="top_post_bg_img">
                                  <div className="course-cont">
                                    <div className="heading_card">
                                      <div className="latestblogs_right_profile1_left">
                                        <div className="right_profile_left_bg">
                                          <img
                                            src={value.author_image}
                                            alt=""
                                          />
                                        </div>
                                        <span>{value.author_name}</span>
                                      </div>
                                    </div>
                                    <span>{value.title}</span>
                                    <h1>{value.date}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        );
                      })}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            ) : (
              ""
            )} */}
            {/* ********* Recent Post End ******** */}

            {/* ********* Top Post ******** */}
            {HomeDetail.top_posts !== undefined &&
              HomeDetail.top_posts.length > 0 && (
                <div className="top_post_section_card py-15">
                  <div className="many-courses">
                    {HomeDetail.top_post_title !== undefined && (
                      <div className="many-courses_heading">
                        <h5>{HomeDetail.top_post_title}</h5>

                        <button onClick={() => directpage("profile")}>
                          View All
                        </button>
                      </div>
                    )}
                    <OwlCarousel
                      key={`carousel_top_post_${Date.now()}`}
                      {...experience}
                      className="owl-theme"
                      nav
                      loop
                      margin={10}
                    >
                      {HomeDetail.top_posts.map((value, index) => {
                        return (
                          <NavLink to="/profile" key={index}>
                            <div className=" trending-post">
                              <div className="d-flex justify-content-between align-items-center gap-2 mb-2 tp-heading">
                                <div className="d-flex align-items-center gap-10">
                                  <img
                                    src={
                                      value.author_image ||
                                      images["card-user-1.png"]
                                    }
                                    className="rounded-circle border border-white"
                                    width={40}
                                    height={40}
                                  />
                                  <h6 className="flex-1 text-16 capitalize font-poppins font-medium truncate popins">
                                    {value.author_name}
                                  </h6>
                                </div>
                                <span className="text-14 text-gray-300 popins font-500">
                                  1d
                                </span>
                              </div>
                              <div className="tp-body">
                                {value.media_type === "image" && (
                                  <div className="tp-type">
                                    <p>{value.title}</p>
                                    <img src={value.image} className="img" />
                                  </div>
                                )}
                                {value.media_type === "video" && (
                                  <div className="tp-type">
                                    <p>{value.title}</p>
                                    <img src={value.image} className="img" />
                                  </div>
                                )}
                                {/* {type === "music" && (
                                  <div>
                                    <div className="mb-2">
                                      <p className="text-muted text-14 truncate">
                                        {title}
                                      </p>
                                    </div>
                                    <div className="bg-dark p-3 d-flex align-items-center gap-3 rounded-10 mb-2">
                                      <div className="w-20 relative">
                                        <div className="overlay bg-transparent grid place-content-center">
                                          <span
                                            className="text-20 cursor-pointer"
                                            onClick={() =>
                                              playerHandler(!playingStatus)
                                            }
                                          >
                                            {playingStatus
                                              ? reactIcons.play
                                              : reactIcons.pause}
                                          </span>
                                        </div>
                                        <Image
                                          src={img}
                                          className="w-100 h-64 rounded-10"
                                        />
                                      </div>
                                      <div className="d-flex gap-2 flex-1 justify-content-between">
                                        <div>
                                          <p className="text-13 truncate">
                                            {album}
                                          </p>
                                          <h6 className="font-poppins font-semibold text-18 my-1 truncate">
                                            {musicName}
                                          </h6>
                                          <p className="text-12 truncate">
                                            {mintStatus}
                                          </p>
                                        </div>
                                        <div>
                                          <span className="cursor-pointer">
                                            {reactIcons.menuHorizontal}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {type === "onlyText" && (
                                  <p className="text-muted">{text}</p>
                                )}
                                {value.media_type === "onlyImage" && (
                                  <img
                                    src={img}
                                    className="w-100 h-100 rounded-10"
                                  />
                                )}
                                {type === "imageNTitle" && (
                                  <div>
                                    <p className="text-muted text-14 truncate mb-2">
                                      {title}
                                    </p>
                                    <img
                                      src={img}
                                      className="w-100 h-100 rounded-10"
                                    />
                                  </div>
                                )} */}
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex flex-1 align-items-center gap-10">
                                  <div className="d-flex align-items-center gap-5">
                                    <span className="text-gray-300 text-20">
                                      {reactIcons.circleUp}
                                    </span>
                                    <span className="text-gray-300 font-poppins text-14 font-medium">
                                      28
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center gap-5">
                                    <span className="text-gray-300 text-20">
                                      {reactIcons.repeat}
                                    </span>
                                    <span className="text-gray-300 font-poppins text-14 font-medium">
                                      11
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center gap-5">
                                    <span className="text-gray-300 text-20">
                                      {reactIcons.message}
                                    </span>
                                    <span className="text-gray-300 font-poppins text-14 font-medium">
                                      6
                                    </span>
                                  </div>
                                </div>
                                <Button variant="dark" className="tp-mint">
                                  Mint 6
                                </Button>
                              </div>
                            </div>
                            {/* <div className="item">
                              <div
                                className="course-list1 "
                                style={{
                                  backgroundImage: `url(${value.image})`,
                                }}
                              >
                                <div className="top_post_bg_img">
                                  <div className="course-cont">
                                    <div className="heading_card">
                                      <div className="latestblogs_right_profile1_left">
                                        <div className="right_profile_left_bg">
                                          <img
                                            src={value.author_image}
                                            alt=""
                                          />
                                        </div>
                                        <span>{value.author_name}</span>
                                      </div>
                                    </div>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: value.description,
                                      }}
                                    ></p>
                                    <h1>{value.date}</h1>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </NavLink>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              )}
            {/* ***************** */}

            {/* ********* Freshly Minted Start  ******** */}
            <div className="freshly_minted_section py-15">
              <div className="many-courses">
                {freshlyData.freshly_minted_title !== undefined && (
                  <div className="many-courses_heading">
                    <h5>{freshlyData.freshly_minted_title} </h5>
                    <button>View All</button>
                  </div>
                )}
                <ul className="custom-tab">
                  <li
                    className={activeTab == 1 ? "active" : ""}
                    onClick={() => setActiveTab(1)}
                  >
                    For You
                  </li>
                  <li
                    className={activeTab == 2 ? "active" : ""}
                    onClick={() => setActiveTab(2)}
                  >
                    Following
                  </li>
                </ul>

                {activeTab === 1 &&
                  freshlyData.freshly_minted_forYou !== undefined && (
                    <OwlCarousel
                      // key={`carousel_tranding_courses_${Date.now()}`}
                      key={nextId()}
                      {...experience}
                      className="owl-theme"
                      nav
                      loop
                      margin={10}
                    >
                      {freshlyData.freshly_minted_forYou?.map(
                        (value, index) => {
                          return (
                            <NavLink
                              to={`/courses-detail/${value.id}`}
                              key={index}
                              className="course-card"
                            >
                              <div className="cc-img-sec">
                                <div className="overlay-bg"></div>
                                {isVideo(value.image) ? (
                                  <video controls>
                                    <source
                                      src={value.image}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img src={value.image} alt={value.title} />
                                )}
                              </div>
                              <div className="cc-text-sec">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center mb-2">
                                    <h6 className="duration-text mb-0">
                                      12 month
                                    </h6>
                                    <span className="duration-calender ml-2">
                                      {reactIcons.calender}
                                    </span>
                                  </div>
                                  <h5 className="title">{value.title}</h5>
                                </div>
                                <div className="d-flex align-items-center gap-1 justify-content-between w-100 cc-profile">
                                  <div className="d-flex w-50">
                                    <img
                                      src={images["fresh-profile-1.png"]}
                                      className="rounded-circle mr-2"
                                      style={{ width: "22px", height: "22px" }}
                                    />
                                    <h3 className="font-medium text-truncate text-capitalize">
                                      Larry doe
                                    </h3>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-end gap-1 flex-fill w-50 text-right">
                                    <p className="text-muted mr-2">minted</p>
                                    <p className="text-muted">2 edition</p>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="item " key={index}>
                              <div className=" item-trading_course">
                                <div
                                  className="course-list1"
                                  style={{
                                    backgroundImage: `url(${value.image})`,
                                  }}
                                >
                                  <div className="top_post_bg_img">
                                    <div className="course-cont">
                                      <p>{value.title} </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            </NavLink>
                          );
                        }
                      )}
                    </OwlCarousel>
                  )}
                {activeTab === 2 &&
                  freshlyData.freshly_minted_following !== undefined && (
                    <OwlCarousel
                      // key={`carousel_tranding_courses_${Date.now()}`}
                      key={nextId()}
                      {...experience}
                      className="owl-theme"
                      nav
                      loop
                      margin={10}
                    >
                      {freshlyData.freshly_minted_forYou?.map(
                        (value, index) => {
                          return (
                            <NavLink
                              to={`/courses-detail/${value.id}`}
                              key={index}
                              className="course-card"
                            >
                              <div className="cc-img-sec">
                                <div className="overlay-bg"></div>
                                {isVideo(value.image) ? (
                                  <video controls>
                                    <source
                                      src={value.image}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img src={value.image} alt={value.title} />
                                )}
                              </div>
                              <div className="cc-text-sec">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center mb-2">
                                    <h6 className="duration-text mb-0">
                                      12 month
                                    </h6>
                                    <span className="duration-calender ml-2">
                                      {reactIcons.calender}
                                    </span>
                                  </div>
                                  <h5 className="title">{value.title}</h5>
                                </div>
                                <div className="d-flex align-items-center gap-1 justify-content-between w-100 cc-profile">
                                  <div className="d-flex w-50">
                                    <img
                                      src={images["fresh-profile-1.png"]}
                                      className="rounded-circle mr-2"
                                      style={{ width: "22px", height: "22px" }}
                                    />
                                    <h3 className="font-medium text-truncate text-capitalize">
                                      Larry doe
                                    </h3>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-end gap-1 flex-fill w-50 text-right">
                                    <p className="text-muted mr-2">minted</p>
                                    <p className="text-muted">2 edition</p>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="item " key={index}>
                              <div className=" item-trading_course">
                                <div
                                  className="course-list1"
                                  style={{
                                    backgroundImage: `url(${value.image})`,
                                  }}
                                >
                                  <div className="top_post_bg_img">
                                    <div className="course-cont">
                                      <p>{value.title} </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            </NavLink>
                          );
                        }
                      )}
                    </OwlCarousel>
                  )}
              </div>
            </div>
            {/* ********* Freshly Minted End  ******** */}

            {/* ********* Viral video Start  ******** */}
            <div className="viral_video_section py-15">
              <div className="many-courses">
                {viralVideoData.viral_video_title !== undefined && (
                  <div className="many-courses_heading">
                    <h5>{viralVideoData.viral_video_title} </h5>
                    <button>View All</button>
                  </div>
                )}
                <div className="vv-card-group">
                  {viralVideoData.viral_video_list !== undefined &&
                    viralVideoData.viral_video_list?.map((item, index) => {
                      return (
                        <div className="vv-card">
                          <h6 className="vv-count">{index + 1}</h6>
                          <img src={item.img} className="vv-img" />
                          <div>
                            <h5 className="vv-title">{item.title}</h5>
                            <div className="vv-bottom">
                              <div>
                                <h6 className="vv-artist">{item.artist}</h6>
                              </div>
                              <div className="position-relative">
                                <div className="vv-point" />
                                <p className="vv-artist">{item.mint}</p>
                              </div>
                              {item.endsIn && (
                                <div className="position-relative">
                                  <div className="vv-point" />
                                  <p className="vv-artist">{item.endsIn}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* ********* Viral video End  ******** */}

            {/* ********* Trending Slider Start  ******** */}
            {HomeDetail.tranding_courses != undefined &&
            HomeDetail.tranding_courses.length > 0 ? (
              <div className="tranding_courses_section py-15">
                <div className="many-courses">
                  {HomeDetail.tranding_courses_title !== undefined && (
                    <div className="many-courses_heading">
                      <h5>{HomeDetail.tranding_courses_title} </h5>
                      <button onClick={() => directpage("courses")}>
                        View All
                      </button>
                    </div>
                  )}
                  {HomeDetail.tranding_courses !== undefined && (
                    <OwlCarousel
                      // key={`carousel_tranding_courses_${Date.now()}`}
                      key={nextId()}
                      {...experience}
                      className="owl-theme"
                      nav
                      loop
                      margin={10}
                    >
                      {HomeDetail.tranding_courses?.map((value, index) => {
                        return (
                          <NavLink
                            to={`/courses-detail/${value.id}`}
                            key={index}
                            className="course-card"
                          >
                            <div className="cc-img-sec">
                              <div className="overlay-bg"></div>
                              {isVideo(value.image) ? (
                                <video controls>
                                  <source src={value.image} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img src={value.image} alt={value.title} />
                              )}
                            </div>
                            <div className="cc-text-sec">
                              <div className="mb-2">
                                <div className="d-flex align-items-center mb-2">
                                  <h6 className="duration-text mb-0">
                                    12 month
                                  </h6>
                                  <span className="duration-calender ml-2">
                                    {reactIcons.calender}
                                  </span>
                                </div>
                                <h5 className="title">{value.title}</h5>
                              </div>
                              <div className="d-flex align-items-center gap-1 justify-content-between w-100 cc-profile">
                                <div className="d-flex w-50">
                                  <img
                                    src={images["fresh-profile-1.png"]}
                                    className="rounded-circle mr-2"
                                    style={{ width: "22px", height: "22px" }}
                                  />
                                  <h3 className="font-medium text-truncate text-capitalize">
                                    Larry doe
                                  </h3>
                                </div>
                                <div className="d-flex align-items-center justify-content-end gap-1 flex-fill w-50 text-right">
                                  <p className="text-muted mr-2">minted</p>
                                  <p className="text-muted">2 edition</p>
                                </div>
                              </div>
                            </div>

                            {/* <div className="item " key={index}>
                              <div className=" item-trading_course">
                                <div
                                  className="course-list1"
                                  style={{
                                    backgroundImage: `url(${value.image})`,
                                  }}
                                >
                                  <div className="top_post_bg_img">
                                    <div className="course-cont">
                                      <p>{value.title} </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </NavLink>
                        );
                      })}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* ********* Trending Slider End  ******** */}

            {/* ********* Best Product Start  ******** */}
            {/* {HomeDetail.best_products !== undefined &&
              HomeDetail.best_products.length > 0 && (
                <div className="thebay_main_section_all">
                  <div className="many-courses">
                    {HomeDetail.best_product_title !== undefined && (
                      <div className="many-courses_heading">
                        <h5>{HomeDetail.best_product_title}</h5>
                        <button onClick={() => directpage("product-listing")}>
                          View All
                        </button>
                      </div>
                    )}
                    {HomeDetail.best_products !== undefined && (
                      <OwlCarousel
                        key={`carousel_best_products_${Date.now()}`}
                        {...experience}
                        className="owl-theme"
                        nav
                        loop
                        margin={10}
                      >
                        {HomeDetail.best_products?.map((value, index) => {
                          return (
                            <NavLink
                              to={`/product-details/${value.id}`}
                              key={index}
                            >
                              <div className="item" key={index}>
                                <div className="course-list1">
                                  <div
                                    className="course-image-inner"
                                    style={{
                                      backgroundImage: `url(${value.image})`,
                                    }}
                                  ></div>

                                  <div className="course-cont">
                                    <div className="heading_card">
                                      <h6>{value.title}</h6>
                                      <h2>
                                        <p>$</p>
                                        {value.price}
                                      </h2>
                                    </div>
                                    <p>Last sale : 78.100 WETH</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          );
                        })}
                      </OwlCarousel>
                    )}
                  </div>
                </div>
              )} */}
            {/* ********* Best Product End  ******** */}
          </div>
          <FooterBig />
          <div className="mobile-footer-new">
            <FooterMobile />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default BayPage;
