import React, { useState } from "react";
import { images } from "../../actions/customFn";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PhotosPopupSlider from "./PhotosPopupSlider";

const data = [
  {
    image: images["timepost1.png"]

  },
  { image: images["timepost2.png"] },
  { image: images["timepost3.png"] },
  { image: images["timepost4.png"] },
  { image: images["timepost5.png"] },
  { image: images["timepost6.png"] },
  { image: images["timepost7.png"] },
  { image: images["timepost8.png"] },
];



const experience = {
  loop: true,
  margin: 0,
  nav: true,
  dots: false,
  autoplay: false,
  autoplaySpeed: 3000,
  // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 4,
    },
  },
};

const PostsSlides = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <div className="PostsSlides">
        <OwlCarousel {...experience} className="owl-theme" loop margin={10}>
          {data.map((value, index) => {
            return (
              <div className="slider_post" key={index}>
                <div className="item" >
                  <img
                    src={value.image}
                    alt=""
                    onClick={() => setModalShow(true)}

                  />
                  <div className="number_timeline_post">
                    <div className="timeline_number">
                    <h4>75</h4>
                   </div>
                  <div className="like_timeline_img">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="bxs-like">
                        <path id="Vector" d="M3.33332 17.4998H4.16666V6.6665H3.33332C2.8913 6.6665 2.46737 6.8421 2.15481 7.15466C1.84225 7.46722 1.66666 7.89114 1.66666 8.33317V15.8332C1.66666 16.2752 1.84225 16.6991 2.15481 17.0117C2.46737 17.3242 2.8913 17.4998 3.33332 17.4998ZM16.6667 6.6665H10.8333L11.7683 3.85984C11.8517 3.60935 11.8745 3.34263 11.8346 3.08163C11.7948 2.82064 11.6935 2.57285 11.5391 2.35867C11.3848 2.14448 11.1817 1.97004 10.9467 1.8497C10.7117 1.72936 10.4515 1.66657 10.1875 1.6665H9.99999L5.83332 6.19817V17.4998H15L18.26 10.3365L18.3333 9.99984V8.33317C18.3333 7.89114 18.1577 7.46722 17.8452 7.15466C17.5326 6.8421 17.1087 6.6665 16.6667 6.6665Z" fill="white" />
                      </g>
                    </svg>
                      {/* <img src={images["like_timeline.png"]} /> */}
                   
                  </div>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>

        <PhotosPopupSlider
          show={modalShow}
          onClick={() => setIsOpened(true)}
          onHide={() => setModalShow(false)}
        />
      </div>
    </>
  );
};

export default PostsSlides;
