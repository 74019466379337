import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";

import {
  ChallengesDay,
  FooterBig,
  FriendChallenges,
  Header,
  ScoreBoard,
  SliderBox,
  VideoPlayer,
} from "../components";

import { motion } from "framer-motion";

import FooterMobile from "../components/Home/FooterMobile";
import { asyncgetChallenges } from "../actions/challengeAction";

const Challenges = () => {
  const [isload, setIsload] = useState();
  const [challenge, setChallenge] = useState();

  useEffect(() => {
    asyncgetChallenges(setChallenge, setIsload);
  }, []);

  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="challenges_section">
          <div className="all_page_bg challenges-page">
            <div className="container-fluid">
              <Header />

              <div className="page-all-data">
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <ScoreBoard />
                      {/* <Friends handleChange={handleChange} /> */}
                      {/* <ChatGroup /> */}
                      <FriendChallenges />
                    </div>

                    <div className="col-md-6 px-3 px-lg-0">
                      <ChallengesDay challenges={challenge} isload={isload} />
                    </div>

                    <div className="col-md-3">
                      <SliderBox />
                      <VideoPlayer />

                      <div className="header_right_main btn-right-side mt-3">
                        <button>MYSTERY BOX</button>
                      </div>

                      <div className="play_ban mt-3">
                        <button>PLAY</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
              <FooterBig />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
export default Challenges;
