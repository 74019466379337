import React, { useRef, useState } from "react";
import { handleScrollError, images } from "../../actions/customFn";
import Modal from "react-bootstrap/Modal";
import { Input, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { addCourse } from "../../actions/courseAction";

const UploadCourseModal = (props) => {
  const [formErrors, setFormError] = useState({});
  const [courseForm, setCourseForm] = useState({
    tag_title: "",
    title: "",
    description: "",
    sort_description: "",
    add_what_you_learn: ["", "", "", ""],
    access_on_device: ["", "", ""],
    image: {},
    price: "",
  });
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e, type) => {
    if (type == "description") {
      setCourseForm((formData) => ({ ...formData, [type]: e }));
    } else {
      const { name, value, id, checked } = e.target;
      if (name == "add_what_you_learn") {
        let newState = [...courseForm[name]];
        if (newState[id] != undefined) {
          newState[id] = value;
        }
        setCourseForm((formData) => ({ ...formData, [name]: newState }));
      } else {
        if (name == "image") {
          setCourseForm((formData) => ({
            ...formData,
            [name]: e.target.files,
          }));
        } else {
          setCourseForm((formData) => ({ ...formData, [name]: value }));
        }
      }
    }
  };

  const handleChecked = (e) => {
    const { name, value, id, checked } = e.target;
    let newState = [...courseForm["access_on_device"]];
    if (checked) {
      newState[id] = name;
    } else {
      newState[id] = "";
    }
    setCourseForm((formData) => ({
      ...formData,
      ["access_on_device"]: newState,
    }));
  };

  const errors = {};
  const validateFormData = async () => {
    try {
      if (courseForm.tag_title == "") {
        errors.tag_title = "Enter Tag Title";
      }
      if (courseForm.title == "") {
        errors.title = "Enter Title";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCourse = (e) => {
    e.preventDefault();

    validateFormData();
    if (Object.keys(errors).length === 0) {
      addCourse(courseForm, props.close);
    } else {
      handleScrollError(errors);
    }
  };

  const handleRemoveImage = (key) => {
    let arr = { ...courseForm.image };
    delete arr[key];
    setCourseForm((formData) => ({ ...formData, image: arr }));
  };

  return (
    <Modal
      show={props.show}
      className="main_popup_inner couse_popup_main"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header className="header_popup">
        <Modal.Title className="popup_title_name_upload ">
          Upload New Courses
        </Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          handleAddCourse(e);
        }}
      >
        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="Tag Title"
              name="tag_title"
              onChange={(e) => {
                handleChange(e);
              }}
              error={formErrors.tag_title}
              helperText={formErrors.tag_title}
              placeholder="Enter tag name"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="Course Price"
              name="price"
              onChange={(e) => {
                handleChange(e);
              }}
              error={formErrors.price}
              helperText={formErrors.price}
              placeholder="Enter course price"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="uplod_course">
              <h4>Courses Details</h4>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"></div>
        </div>

        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              name="title"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="Title"
              onChange={(e) => {
                handleChange(e);
              }}
              error={formErrors.title}
              helperText={formErrors.title}
              placeholder="Enter your course title"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="Sort description"
              name="sort_description"
              onChange={(e) => {
                handleChange(e);
              }}
              error={formErrors.sort_description}
              helperText={formErrors.sort_description}
              placeholder="Enter your sort description"
            />
          </div>
        </div>

        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="uplod_course">
              <h4>What you'll learn Details</h4>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="uplod_course_point">
              <h4>four points fill is mandatory</h4>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              className="input_username new-edit-main-all"
              // id="outlined-basic"
              label="1.Learn Point"
              onChange={(e) => {
                handleChange(e);
              }}
              id="0"
              name="add_what_you_learn"
              placeholder="Enter your Point"
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              id="1"
              onChange={(e) => {
                handleChange(e);
              }}
              className="input_username new-edit-main-all"
              // id="outlined-basic"
              label="2.Learn Point"
              name="add_what_you_learn"
              placeholder="Enter your Point"
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              id="2"
              onChange={(e) => {
                handleChange(e);
              }}
              className="input_username new-edit-main-all"
              label="3.Learn Point"
              name="add_what_you_learn"
              placeholder="Enter your Point"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <TextField
              sx={{
                width: "100%",
              }}
              id="3"
              onChange={(e) => {
                handleChange(e);
              }}
              className="input_username new-edit-main-all ccv_input"
              // id="outlined-basic"
              label="4.Learn Point"
              name="add_what_you_learn"
              placeholder="Enter your Point"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="uplod_course">
              <h4>Includes</h4>

              <TextField
                sx={{
                  width: "100%",
                }}
                className="input_username new-edit-main-all"
                id="outlined-basic"
                label="Courses Duration"
                placeholder="Enter your course duration"
              />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="course_checkbox">
              <h4>Access on device</h4>

              <FormGroup className="nft-filter-sides">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleChecked(e);
                      }}
                      id="0"
                      name="mobile"
                      value="mobile"
                    />
                  }
                  label="Mobile"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleChecked(e);
                      }}
                      id="1"
                      name="laptop"
                      value="laptop"
                    />
                  }
                  label="Laptop"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleChecked(e);
                      }}
                      id="2"
                      name="computer"
                      value="computer"
                    />
                  }
                  label="Computer"
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="account_txteditor_main">
          <h6>Description</h6>

          <div
            style={{
              // display: "grid",
              color: "#fff",
              justifyContent: "center",
            }}
          >
            <ReactQuill
              theme="snow"
              modules={props.modules}
              formats={props.formats}
              placeholder=" "
              name="description"
              onChange={(e) => {
                handleChange(e, "description");
              }}
              style={{ height: "80px", color: "#fff" }}
            ></ReactQuill>
            <div className="enter_text_course">
              <h3>Enter text</h3>
            </div>
          </div>
        </div>
        <div className="post_image_upload">
          <img src={images["upload-post1.png"]} alt="" />
          <Input
            type="file"
            name="image"
            onChange={(e) => {
              handleChange(e);
            }}
            inputProps={{ multiple: true }}
          ></Input>
          <h6>Upload Image and Video</h6>
          <button>Browse files</button>
        </div>

        {courseForm.image != undefined &&
          Object.entries(courseForm.image).length > 0 &&
          Object.entries(courseForm.image).map((value, index) => {
            // console.log("index",index)
            return (
              <div className="creating_upload" key={index}>
                <div className="creating_upload_img">
                  {value[1].type == "image/png" ? (
                    <img src={images["pdf_img1.png"]} alt="" />
                  ) : value[1].type == "image/pdf" ? (
                    <img src={images["pdf2_img2.png"]} alt="" />
                  ) : (
                    <img src={images["pdf3_img3.png"]} alt="" />
                  )}

                  <h5>{value[1].name}</h5>
                </div>
                <div
                  className="creating_upload_close"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    handleRemoveImage(index);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.27916 15.7208C4.45797 15.8996 4.70044 16 4.95327 16C5.2061 16 5.44857 15.8996 5.62737 15.7208L10 11.3482L14.3726 15.7208C14.5514 15.8996 14.7939 16 15.0467 16C15.2996 16 15.542 15.8996 15.7208 15.7208C15.8996 15.542 16 15.2996 16 15.0467C16 14.7939 15.8996 14.5514 15.7208 14.3726L11.3482 10L15.7208 5.62737C15.8996 5.44857 16 5.2061 16 4.95327C16 4.70044 15.8996 4.45797 15.7208 4.27916C15.542 4.10041 15.2996 4 15.0467 4C14.7939 4 14.5514 4.10041 14.3726 4.27916L10 8.65179L5.62737 4.27916C5.44857 4.10041 5.2061 4 4.95327 4C4.70044 4 4.45797 4.10041 4.27916 4.27916C4.10042 4.45796 4 4.70044 4 4.95327C4 5.20609 4.10042 5.44857 4.27916 5.62737L8.65179 10L4.27916 14.3726C4.10041 14.5514 4 14.7939 4 15.0467C4 15.2996 4.10041 15.542 4.27916 15.7208Z"
                      fill="#FB5050"
                      stroke="#FB5050"
                      stroke-width="0.5"
                    />
                  </svg>
                </div>
              </div>
            );
          })}

        <div className="popup_donate_btn">
          <button type="submit">Upload </button>
        </div>
      </form>
    </Modal>
  );
};

export default UploadCourseModal;
