import { createSlice } from "@reduxjs/toolkit";
import { getAllPhotosList } from "../actions/uploadPhoto";



const initialState = {
  loading: false,
  all_photos: [],
  error: null,
  success: false,
};

const photosSlice = createSlice({
  name: "photos",
  initialState,
  reducers: {},
  extraReducers: {
    // Friends List
    [getAllPhotosList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllPhotosList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.all_photos = payload;
    },
    [getAllPhotosList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});


export default photosSlice.reducer;
