import React from "react";

import PropagateLoader from "react-spinners/PropagateLoader";

const LoadingBar = () => {
  return (
    <div className="loading_main">
      {/* <Loading type="circles" width={100} height={300} fill="#f44242" /> */}

      <PropagateLoader color="#fff" />
      {/* <GridLoader color="#36d7b7" /> */}
    </div>
  );
};

export default LoadingBar;
