import React, { useState } from "react";
import AddCampaignsModal from "../Modal/AddCampaignsModal";

const AddCampaigns = (props) => {
  const campaigndata = props.data;
  const title = props.title;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="campaigns_main">
        <h1>{title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: campaigndata,
          }}
        ></p>
        <button variant="primary" onClick={handleShow}>
          Add New Campaigns
        </button>

        <AddCampaignsModal show={show} setIsLoader={props.setIsLoader} setShow={setShow} close={handleClose} />
      </div>
    </>
  );
};

export default AddCampaigns;
