import React from "react";
import VideoPlayer from "../Home/VideoPlayer";

const LiveVideo = () => {
  return (
    <>
      <div className="liveVideo">
        <div className="livevideo_top">
          <h4>Live Video</h4>
          <button className="liveVideo-btn" type="button">
            Go Live
          </button>
        </div>

        <div className="liveVideo_botm">
          <VideoPlayer />
        </div>
      </div>
    </>
  );
};

export default LiveVideo;
