import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { errorMsg, images } from "../actions/customFn";
import OtpInput from "react-otp-input";
import { asyncverifyotp } from "../actions/authActions";
import { asyncforgetpassword } from "../actions/authActions";
const VerifyOtp = () => {
  const [otp, setOtp] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateOtp = async () => {
    try {
      if (otp === "") {
        errors.otp = "Enter 6 digit OTP";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleverifyOpt = async (e) => {
    try {
      e.preventDefault();
      validateOtp();
      if (Object.keys(errors).length === 0) {
        asyncverifyotp(otp, state.email, navigate);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  const resendOtp = async () => {
    try {
      if (state.email !== undefined && state.email !== "" ? state.email : "") {
        asyncforgetpassword(state.email, navigate);
      } else {
        errorMsg("something went wrong!");
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <>
      <div className="register-page reset-password  verify-main">
        <div className="left-register">
          <div className="right-div">
            <h1>Welcome</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </div>

        <div className="right-register new-all-inputs">
          <div className="right-div">
            <div className="logo_heding right-top ">
              <NavLink to="/">
                <img src={images["register-R1.png"]} alt="" />
              </NavLink>
              <h1>Verify OTP</h1>
            </div>

            <form onSubmit={handleverifyOpt}>
              <div className="input_field">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  className="input_otp"
                  numInputs={6}
                  renderInput={(props) => (
                    <input
                      {...props}
                      //   onChange={(e) => checkIsNumber(e.target.value)}
                    />
                  )}
                />
              </div>
              {formErrors.otp && (
                <div className="form_error">{formErrors.otp}</div>
              )}
              <div className=" account_activation_btn">
                <button>Verify</button>
              </div>
            </form>
            <div className="verify-new-button">
              <div className="back-to-home">
                <NavLink to="/">Back To Home</NavLink>
              </div>
              <div className="resnd_btn">
                <button onClick={() => resendOtp()}>Resend OTP</button>
              </div>
            </div>

            <div className="nav_login_register_btn">
              <NavLink className="login_nav_btn" to="/login">
                Login
              </NavLink>
              <span>|</span>
              <NavLink className="login_nav_btn" to="/register">
                Register
              </NavLink>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
