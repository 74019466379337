import React from "react";
import { NavLink } from "react-router-dom";
const CampaignsCard = (props) => {
  const card_all = props.data;

  return (
    <div className="campaigns_card_main">
      <h1>Our Featured Campaigns</h1>

      <div className="campaigns_card_main_inner">
        <div className="row">
          {card_all.map(function(value, index) {
            return (
              <div
                className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 courses_card"
                key={index}
              >
                <NavLink to={`/campaign-detail/${value.id}`}>
                  <div className="inner_list_main">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div
                          className="product_img"
                          style={{
                            backgroundImage: `url(${value.image})`,
                          }}
                        ></div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <div className="wish_lsit_info">
                          <span>{value.category}</span>
                          <h2>{value.title}</h2>
                          <h6
                            dangerouslySetInnerHTML={{
                              __html: value.sort_description,
                            }}
                          ></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CampaignsCard;
