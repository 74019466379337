import { Await } from "react-router-dom";
import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export const asyncUploadPhoto = async (
  formdata,
  setShow,
  setIsLoader,
  setFormData,
  setpreview,
  setButtonLoader,
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    const formData = new FormData();
    formData.append("photo", formdata.image);
    formData.append("customer_id", user_id);
    formData.append("password", user_password);

    const url = "/upload_photos";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        setTimeout(() => {
          setButtonLoader(false);
          setShow(false);
          setIsLoader(true);
        }, 1000);
      } else {
        errorMsg(response.data.message);
      }
      setpreview("");
      setFormData({
        image: "",
      });
    });

    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getAllPhotosList = createAsyncThunk(
  "photos/photoList",
  async (_, { rejectWithValue }) => {
    try {
      const requestData = {
        customer_id: localStorage.getItem("star_uId"),
        password: localStorage.getItem("star_upass"),
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/photo_list", requestData);
      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// UserPhotoo
export const UserPhotoList = async (
  setState,
  setIsLoader,
  currentPage,
  setHasMore,
  user_id,
  setPhotosCount
) => {
  const customer_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: customer_id,
      password: user_password,
      user_id: user_id,
      offset: currentPage,
    };
    const response = await axios.post("/get_user_freind_Photos", params);
    if (response.data.status === true) {
      if (response.data.data.length > 0) {
        setPhotosCount(response.data.total)
        setState((prevState) => [...prevState, ...response.data.data]);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    console.log("error", error)
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const DeletePhotoAPI = async (params, dispatch) => {
  try {
    const response = await axios.post("/delete_photos", params);
    if (response.data.status === true) {
      // console.log();
      successMsg(response.data.message)
      dispatch(getAllPhotosList());
    } else {
    }
    // setTimeout(() => {
    //   setIsLoader(false);
    // }, 1000);
  } catch (error) {
    console.log("error", error)
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
