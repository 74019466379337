import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faStar,
  faStarHalf,
} from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";
import { asyncEducatorFeedback } from "../../actions/courseAction";
import { NavLink, useParams } from "react-router-dom";
import { errorMsg } from "../../actions/customFn";
import { asyncProductRating } from "../../actions/productAction";


const ProductRatingModal = (props) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    product_id: id,
    rating: 0,
    description: "",
  });

  const reactRatings = {
    edit: true,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleRating = (value) => {
    setFormData({ ...formData, rating: value });
  };

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateFormData = async () => {
    try {
      if (formData.description == "") {
        errors.description = "Enter description";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const HandleProductRating = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncProductRating(formData, props.close, props.NewRatingList);
      }
    } catch (error) {
      console.log(error);
      errorMsg("something went wrong!");
    }
  };

  const closePopUP = () => {
    setFormData({});
    props.close();
  }

  return (
    <div className="new-EducatorModal">
      <Modal
        show={props.show}
        className="main_popup_inner donate_popup_main Ask-Educator-Question"
        onHide={props.close}
      >
        <FontAwesomeIcon onClick={props.close} icon={faTimes} />
        <Modal.Header>
          <Modal.Title className="popup_title_name">Product Ratings & Reviews</Modal.Title>
        </Modal.Header>

        <form>
          <div className=" main_popup_inner1 new-all-inputs Ask-Educator-Questio-main">
            <div className="modal-inner-edinfo">
              <h2>Rate Your Experience</h2>
              <p>Are you Satisfied with the service</p>

              <div className="rate-star prd_star_rating">
                <ul>
                  <li>
                    <ReactStars
                      count={5}
                      onChange={handleRating}
                      name="rating"
                      size={24}
                      value={Number(formData.rating)}
                      {...reactRatings}
                    />
                  </li>
                  <li className="star_point">{formData.rating}</li>
                </ul>
              </div>
            </div>

            <div className="text-inner">
              <TextField
                sx={{
                  width: "100%",
                }}
                name="description"
                error={formErrors.description}
                className={`input_username new-edit-main-all `}
                id="outlined-basic"
                label="Feedback"
                multiline
                rows={5}
                onChange={handleChange}
                helperText={formErrors.description}
                placeholder="Enter text"
              />
            </div>
          </div>

          <div className=" pop-EducatorModal-button  popup_donate_btn">
            <button type="button" className="cancel-btn" onClick={() => closePopUP()}>Cancel</button>
            <button type="submit" onClick={(e) => HandleProductRating(e)}>Submit</button>
          </div>
        </form>
      </Modal>
    </div>
  );
};


export default ProductRatingModal;