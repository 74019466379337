import React, { useEffect, useState } from "react";
import {
  errorMsg,
  handleScrollError,
  images,
  successMsg,
} from "../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";
import validator from "validator";

import Flatpickr from "react-flatpickr";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";

import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { getCityList, getStateList } from "../actions/CountryAction";
import { userSignup } from "../actions/authActions";
import { asyncGetPagesData } from "../actions/pageAction";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const Register = () => {
  const navigate = useNavigate();
  const { error } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const { country_list } = useSelector((state) => state.countryData);
  const [stateList, setStateList] = useState([]);
  const [editorType, setEditorType] = useState("");
  const [cityList, setCityList] = useState([]);
  const [homeDetail, setHomeDetail] = useState({});
  const [signupForm, setSignupForm] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    dob: "",
    first_name: "",
    last_name: "",
    address: "",
    phone_number: "",
    country: "",
    state: "",
    city: "",
    // short_info: "",
    // bio_info: "",
  });
  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      setSignupForm((formData) => ({ ...formData, [name]: value }));
    } else {
      if (editorType != "") {
        // console.log("editorType", editorType);
        setSignupForm((formData) => ({ ...formData, [editorType]: e }));
      }
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateFormData = async () => {
    try {
      if (signupForm.username == "") {
        errors.username = "Enter Username";
      }

      if (signupForm.confirm_password == "") {
        errors.confirm_password = "Enter Confirm password";
      }
      if (signupForm.phone_number == "") {
        errors.phone_number = "Enter Phone Number";
      }

      if (signupForm.phone_number.length < 7) {
        errors.phone_number = "Phone no minimum 7 digit";
      }
      if (signupForm.phone_number.length > 20) {
        errors.phone_number = "Phone no maximum 20 digit";
      }
      if (!validator.isNumeric(signupForm.phone_number)) {
        errors.phone_number = "Phone must be numeric";
      } else if (signupForm.phone_number.length === 0) {
        errors.phone_number = "Enter Phone No.";
      }

      if (signupForm.dob == "") {
        errors.dob = "Enter Date of birth";
      }

      if (signupForm.first_name == "") {
        errors.first_name = "Enter First Name";
      }

      if (signupForm.last_name == "") {
        errors.last_name = "Enter Last Name";
      }
      if (signupForm.address == "") {
        errors.address = "Enter Address";
      }

      if (signupForm.country == "") {
        errors.country = "Select Country";
      }

      if (signupForm.state == "") {
        errors.state = "Select State";
      }
      if (signupForm.city == "") {
        errors.city = "Select City";
      }
      if (signupForm.email == "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(signupForm.email)) {
        errors.email = "Enter valid email id";
      }
      // if (signupForm.password == "") {
      //   errors.password = "Enter Password";
      // }

      if (signupForm.password === "") {
        errors.password = "Enter Password";
        // setPasswordError(!passworderror);
      } else if (signupForm.password.length < 8) {
        errors.password = "Password has atleast 8 character";
        // setPasswordError(!passworderror);
      }

      if (signupForm.confirm_password != signupForm.password) {
        errors.confirm_password =
          "Enter Password & Confirm password not matched";
      }

      // if (signupForm.short_info == "") {
      //   errors.short_info = "Enter Short Info";
      // }

      // if (signupForm.bio_info == "") {
      //   errors.bio_info = "Enter Biographical Info";
      // }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (signupForm.country != undefined && signupForm.country != "") {
      getStateList(setStateList, signupForm.country);
    }
  }, [signupForm.country]);

  useEffect(() => {
    if (signupForm.state != undefined && signupForm.state != "") {
      getCityList(setCityList, signupForm.state);
    }
  }, [signupForm.state]);

  const handleSignup = (e) => {
    e.preventDefault();
    validateFormData();
    if (Object.keys(errors).length === 0) {
      dispatch(userSignup({ signupForm, navigate }));
    } else {
      handleScrollError(errors);
    }
  };

  useEffect(() => {
    asyncGetPagesData(2, setHomeDetail);
  }, []);

  return (
    <>
      <div className="register-page register-main">
        <div
          className="left-register"
          style={{
            backgroundImage: `url(${
              homeDetail.banner_image != undefined &&
              homeDetail.banner_image != ""
                ? homeDetail.banner_image
                : ""
            })`,
          }}
        >
          <div className="right-div">
            <h1>
              {homeDetail.heading != undefined && homeDetail.heading != ""
                ? homeDetail.heading
                : ""}
            </h1>
            <p>
              {homeDetail.short_description != undefined &&
              homeDetail.short_description != ""
                ? homeDetail.short_description
                : ""}
            </p>
          </div>
        </div>

        {/* ---------------------------------------------------- */}

        <div className="right-register register-page">
          <div className="right-div">
            <div className="right-top">
              <NavLink to="/">
                <img src={images["register-R1.png"]} alt="" />
              </NavLink>
              <h1>Register</h1>
            </div>

            <div className="regist-scroll new-all-inputs">
              <form
                onSubmit={(e) => {
                  handleSignup(e);
                }}
              >
                <h4>Account Details</h4>
                <div className="input_field">
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="Username (required)"
                    name="username"
                    error={formErrors.username}
                    helperText={formErrors.username}
                    onChange={handleChange}
                    placeholder="Enter user name"
                  />

                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    onChange={handleChange}
                    name="email"
                    error={formErrors.email}
                    helperText={formErrors.email}
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="Email Address (required)"
                    placeholder="Enter email address"
                  />
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    onChange={handleChange}
                    name="password"
                    type="password"
                    error={formErrors.password}
                    helperText={formErrors.password}
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="Choose a Password (required)"
                    placeholder="Enter password"
                  />

                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    type="password"
                    onChange={handleChange}
                    error={formErrors.confirm_password}
                    helperText={formErrors.confirm_password}
                    name="confirm_password"
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="Confirm Password (required)"
                    placeholder="Enter confirm password"
                  />
                </div>

                <h4>Profile Details</h4>
                <div className="input_field">
                  <div className="pic_dates register_datepicker mb-3">
                    {/* <h6>My Birthday </h6> */}
                    {/* <DatePicker name="dob" /> */}
                    <Flatpickr
                      name="dob"
                      placeholder="Date Of Birth (required)"
                      options={{
                        maxDate: "today",
                      }}
                      className="input_username new-edit-main-all"
                      onChange={([date]) => {
                        setSignupForm((formData) => ({
                          ...formData,
                          dob: moment(date).format("yyyy-MM-DD"),
                        }));
                      }}
                    />
                    <span className="date_time">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="22"
                        viewBox="0 0 24 22"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_830_1362)">
                          <path
                            d="M16.5 8.9375C16.5 8.75516 16.579 8.5803 16.7197 8.45136C16.8603 8.32243 17.0511 8.25 17.25 8.25H18.75C18.9489 8.25 19.1397 8.32243 19.2803 8.45136C19.421 8.5803 19.5 8.75516 19.5 8.9375V10.3125C19.5 10.4948 19.421 10.6697 19.2803 10.7986C19.1397 10.9276 18.9489 11 18.75 11H17.25C17.0511 11 16.8603 10.9276 16.7197 10.7986C16.579 10.6697 16.5 10.4948 16.5 10.3125V8.9375ZM12 8.9375C12 8.75516 12.079 8.5803 12.2197 8.45136C12.3603 8.32243 12.5511 8.25 12.75 8.25H14.25C14.4489 8.25 14.6397 8.32243 14.7803 8.45136C14.921 8.5803 15 8.75516 15 8.9375V10.3125C15 10.4948 14.921 10.6697 14.7803 10.7986C14.6397 10.9276 14.4489 11 14.25 11H12.75C12.5511 11 12.3603 10.9276 12.2197 10.7986C12.079 10.6697 12 10.4948 12 10.3125V8.9375ZM4.5 13.0625C4.5 12.8802 4.57902 12.7053 4.71967 12.5764C4.86032 12.4474 5.05109 12.375 5.25 12.375H6.75C6.94891 12.375 7.13968 12.4474 7.28033 12.5764C7.42098 12.7053 7.5 12.8802 7.5 13.0625V14.4375C7.5 14.6198 7.42098 14.7947 7.28033 14.9236C7.13968 15.0526 6.94891 15.125 6.75 15.125H5.25C5.05109 15.125 4.86032 15.0526 4.71967 14.9236C4.57902 14.7947 4.5 14.6198 4.5 14.4375V13.0625ZM9 13.0625C9 12.8802 9.07902 12.7053 9.21967 12.5764C9.36032 12.4474 9.55109 12.375 9.75 12.375H11.25C11.4489 12.375 11.6397 12.4474 11.7803 12.5764C11.921 12.7053 12 12.8802 12 13.0625V14.4375C12 14.6198 11.921 14.7947 11.7803 14.9236C11.6397 15.0526 11.4489 15.125 11.25 15.125H9.75C9.55109 15.125 9.36032 15.0526 9.21967 14.9236C9.07902 14.7947 9 14.6198 9 14.4375V13.0625Z"
                            fill="white"
                          />
                          <path
                            d="M5.25 0C5.44891 0 5.63968 0.0724328 5.78033 0.201364C5.92098 0.330295 6 0.505164 6 0.6875V1.375H18V0.6875C18 0.505164 18.079 0.330295 18.2197 0.201364C18.3603 0.0724328 18.5511 0 18.75 0C18.9489 0 19.1397 0.0724328 19.2803 0.201364C19.421 0.330295 19.5 0.505164 19.5 0.6875V1.375H21C21.7956 1.375 22.5587 1.66473 23.1213 2.18046C23.6839 2.69618 24 3.39565 24 4.125V19.25C24 19.9793 23.6839 20.6788 23.1213 21.1945C22.5587 21.7103 21.7956 22 21 22H3C2.20435 22 1.44129 21.7103 0.87868 21.1945C0.316071 20.6788 0 19.9793 0 19.25V4.125C0 3.39565 0.316071 2.69618 0.87868 2.18046C1.44129 1.66473 2.20435 1.375 3 1.375H4.5V0.6875C4.5 0.505164 4.57902 0.330295 4.71967 0.201364C4.86032 0.0724328 5.05109 0 5.25 0ZM1.5 5.5V19.25C1.5 19.6147 1.65804 19.9644 1.93934 20.2223C2.22064 20.4801 2.60218 20.625 3 20.625H21C21.3978 20.625 21.7794 20.4801 22.0607 20.2223C22.342 19.9644 22.5 19.6147 22.5 19.25V5.5H1.5Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_830_1362">
                            <rect width="24" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {formErrors.dob && (
                      <div className="invalid-msg">{formErrors.dob}</div>
                    )}
                  </div>

                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.first_name}
                    helperText={formErrors.first_name}
                    onChange={handleChange}
                    name="first_name"
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="First Name (required)"
                    placeholder="Enter your first name"
                  />

                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.last_name}
                    helperText={formErrors.last_name}
                    onChange={handleChange}
                    name="last_name"
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="Last Name (required)"
                    placeholder="Enter your last name"
                  />

                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    onChange={handleChange}
                    name="address"
                    error={formErrors.address}
                    helperText={formErrors.address}
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="My Address (required)"
                    placeholder="Enter address"
                  />
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      width: "100%",
                    }}
                    type="number"
                    error={formErrors.phone_number}
                    helperText={formErrors.phone_number}
                    onChange={handleChange}
                    name="phone_number"
                    className="input_username new-edit-main-all mb-3"
                    id="outlined-basic"
                    label="My Phone Number (required)"
                    placeholder="Enter phone number"
                  />

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        color: "#fff",
                      }}
                      id="demo-simple-select-helper-label"
                    >
                      Country (required)
                    </InputLabel>
                    <Select
                      className="input_username new-edit-main-all mb-3"
                      id="demo-simple-select-helper-label"
                      label="Country (required)"
                      name="country"
                      onChange={handleChange}
                      MenuProps={MenuProps}
                      error={formErrors.country}
                      helperText={formErrors.country}
                    >
                      {country_list &&
                        country_list.length > 0 &&
                        country_list.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        color: "#fff",
                      }}
                      id="demo-simple-select-helper-label"
                    >
                      State (required)
                    </InputLabel>
                    <Select
                      sx={{
                        color: "#fff",
                      }}
                      error={formErrors.state}
                      helperText={formErrors.state}
                      className="input_username new-edit-main-all mb-3"
                      id="demo-simple-select-helper-label"
                      label="State (required)"
                      name="state"
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      {stateList && stateList.length > 0 ? (
                        stateList.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.label}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="">Select State</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        color: "#fff",
                      }}
                    >
                      City (required)
                    </InputLabel>
                    <Select
                      sx={{
                        color: "#fff",
                      }}
                      error={formErrors.city}
                      helperText={formErrors.city}
                      className="input_username new-edit-main-all mb-3"
                      id="demo-simple-select-helper-label"
                      label="City (required)"
                      name="city"
                      onChange={handleChange}
                    >
                      {cityList && cityList.length > 0 ? (
                        cityList.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.label}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="">Select City</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  {/* <div className="new-signup-data">
                    <div className="account_txteditor">
                      <h6>Short info (required)</h6>
                      <div>
                        <div
                          style={{
                            display: "grid",
                            color: "#fff",
                            justifyContent: "center",
                          }}
                        >
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            onFocus={(e) => {
                              setEditorType("short_info");
                            }}
                            name="short_info"
                            placeholder=" "
                            onChange={handleChange}
                            style={{ height: "80px", color: "#fff" }}
                          ></ReactQuill>
                        </div>
                      </div>
                    </div>
                    {formErrors.short_info && (
                      <div className="invalid-msg">{formErrors.short_info}</div>
                    )}
                  </div>

                  <div className="new-signup-data">
                    <div className="account_txteditor">
                      <h6>Biographical Info (required)</h6>
                      <div>
                        <div
                          style={{
                            display: "grid",
                            color: "#fff",
                            justifyContent: "center",
                          }}
                        >
                          <ReactQuill
                            theme="snow"
                            onFocus={(e) => {
                              setEditorType("bio_info");
                            }}
                            modules={modules}
                            formats={formats}
                            name="bio_info"
                            placeholder=" "
                            onChange={handleChange}
                            style={{ height: "80px", color: "#fff" }}
                          ></ReactQuill>
                        </div>
                      </div>
                    </div>
                    {formErrors.bio_info && (
                      <div className="invalid-msg">{formErrors.bio_info}</div>
                    )}
                  </div> */}

                  <div className=" account_activation_btn">
                    <button type="submit">Register</button>
                  </div>

                  <div className="nav_login_register_btn">
                    <NavLink className="login_nav_btn" to="/login">
                      Login
                    </NavLink>
                    <span> | </span>
                    <NavLink className="login_nav_btn" to="/account-activation">
                      Activate
                    </NavLink>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
