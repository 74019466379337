import React, { useEffect } from "react";
import { useState } from "react";
import { asyncdoPostLike, getFreindPostList } from "../../actions/uploadBlogs";
import UploadPostModal from "../Modal/UploadPostModal";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPlayer from "react-player";
import { NavLink, useParams } from "react-router-dom/dist";
import { images, successMsg } from "../../actions/customFn";

const Posts = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [emojicolor, setEmojiColor] = useState();
  const [freindposts, setFreindposts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadload, setUploadLoad] = useState(false);
  const [likecount, setLikeCount] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    if (currentPage == 0) {
      setIsLoader(true);
    }
    getFreindPostList(setFreindposts, setIsLoader, currentPage, setHasMore, id);
  }, [currentPage, uploadload]);

  const handlelikePost = (id, index) => {
    asyncdoPostLike(id).then((res) => {
      if (res) {
        let like_arr = [...freindposts];
        like_arr[index].is_blog_like = !like_arr[index].is_blog_like;
        setEmojiColor(!emojicolor);
        if (like_arr[index].is_blog_like == false) {
          like_arr[index].count_blog_like = like_arr[index].count_blog_like - 1;
          setLikeCount(likecount.filter((value) => value !== id));
        }

        if (like_arr[index].is_blog_like == true) {
          like_arr[index].count_blog_like = like_arr[index].count_blog_like + 1;
          setLikeCount((state) => [...state, id]);
        }
        setFreindposts(like_arr);
      }
    });
  };

  const refresh = (setItems) => {
    setTimeout(() => {
      setCurrentPage(0);
    }, 1000);
  };

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 1000);
  };

  function copyLink() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    successMsg("Link copied to Clipboard");
  }

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {isLoader == true ? (
        <Loader />
      ) : (
        <>
          <div className="timelinepost my_post">
            <div className="timep-upload">
              <div className=" ">
                <h2 className="mt-0">Upload Post</h2>
              </div>
            </div>
            <div className="p-2">
              {/* ------------------------- */}
              {freindposts !== undefined && freindposts.length > 0 ? (
                <InfiniteScroll
                  dataLength={freindposts.length}
                  next={handleLoadMore}
                  hasMore={hasMore}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv"
                  refreshFunction={refresh}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      Pull down to refresh
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
                  }
                >
                  {freindposts.map((value, index) => {
                    return (
                      <>
                        <div className="mypost_one" key={index}>
                          <div className="timepost_main">
                            <div className="myself_top_post">
                              <div className="timepost-likes">
                                <div className="date-mint-sec">
                                  <h4 className="date-sec">{value.date}</h4>
                                </div>
                                <div className="likes_butn">
                                  <h6>{value.count_blog_like} </h6>{" "}
                                  <button
                                    onClick={() => {
                                      handlelikePost(value.id, index);
                                    }}
                                    className="lb-like"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      vishal="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        style={{
                                          fill: `${
                                            value.is_blog_like === true
                                              ? "#FF0098"
                                              : "#fff"
                                          }`,
                                        }}
                                        d="M3.33334 17.5001H4.16667V6.66675H3.33334C2.89131 6.66675 2.46739 6.84234 2.15483 7.1549C1.84227 7.46747 1.66667 7.89139 1.66667 8.33342V15.8334C1.66667 16.2754 1.84227 16.6994 2.15483 17.0119C2.46739 17.3245 2.89131 17.5001 3.33334 17.5001ZM16.6667 6.66675H10.8333L11.7683 3.86008C11.8518 3.60959 11.8745 3.34287 11.8346 3.08188C11.7948 2.82089 11.6935 2.57309 11.5392 2.35891C11.3848 2.14473 11.1817 1.97028 10.9467 1.84994C10.7117 1.72961 10.4515 1.66682 10.1875 1.66675H10L5.83334 6.19842V17.5001H15L18.26 10.3367L18.3333 10.0001V8.33342C18.3333 7.89139 18.1577 7.46747 17.8452 7.1549C17.5326 6.84234 17.1087 6.66675 16.6667 6.66675Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    className="lb-share"
                                    onClick={copyLink}
                                  >
                                    <img
                                      src={images["share_post.png"]}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {value.media_type === "video" ? (
                              <ReactPlayer
                                url={value.video}
                                controls
                                width="100%"
                                height="auto"
                              />
                            ) : (
                              <img
                                src={value.image}
                                alt=""
                                className="my_post_img"
                              />
                            )}
                            <div className="myself_post">
                              <div className="pro-sec">
                                <img src={value.author_image} alt="" />
                                <h6>{value.author_name}</h6>
                              </div>
                              <h2>{value.title}</h2>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </InfiniteScroll>
              ) : (
                <RecordNotfound />
              )}

              <UploadPostModal
                show={show}
                setIsLoader={setIsLoader}
                setShow={setShow}
                close={handleClose}
                setUploadLoad={setUploadLoad}
              />
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Posts;
