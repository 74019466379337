import React from "react";
import { FooterBig, FooterMobile, Header } from "../components";
import { motion } from "framer-motion";
import Chat2 from "./Chat2";

const ChatPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg chat_page">
        <div className="container-fluid">
          <Header />
          <div className="chatpage_scroll">
            <div>
              <div className="row chatpage_section">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="postlist_heading">{/* <h3>Chat</h3> */}</div>
                </div>
              </div>
              <Chat2 />
            </div>
          </div>
          <FooterBig />
        </div>
        <div className="mobile-footer-new">
          <FooterMobile />
        </div>
      </div>
    </motion.div>
  );
};

export default ChatPage;
