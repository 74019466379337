import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";
// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

// Donate Page
export const asyncGetDonatePagesData = async (setState, setIsLoader) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const url = "/donate_page";
    await axios.post(url).then((response) => {
      if (response.data.status) {
        setState(response.data);
      } else {
      }

      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1500);
  }
};

// Add Donate
export const asyncAddDonate = async (formdata, dataId, token,setShow,) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let formData = {
      customer_id   : user_id,
      password      : user_password,
      campaign_id   : dataId,
      first_name    : formdata.firstname,
      last_name     : formdata.lastname,
      email         : formdata.email,
      amount        : formdata.amount,
      payment_method: formdata.payment_method,
      comment       : formdata.comment,
      currency      : formdata.currency,
    };

    if (token != undefined && token != "") {
      formData.token = token;
    }

    if (
      formdata.paypal_transation != undefined &&
      formdata.paypal_transation != ""
    ) {
      formData.paypal_transation = formdata.paypal_transation;
    }
    const url = "/add/donate";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setShow(false);        
        successMsg(response.data.message);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response != undefined) {
      if (error.response.status != undefined) {
        if (error.response.status == 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
