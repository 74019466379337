import React, { useState } from "react";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import ReactPaginate from "react-paginate";
import { TextField } from "@mui/material";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { NavLink } from "react-router-dom";

const ProductHistory = (props) => {
  const {
    orderProductList,
    isLoader,
    page_count,
    setCurrentPage,
    currentPage,
    setSearchProduct,
    productcategory,
  } = props;

  // ---------------
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // setProductLoader(true);

  const paginate = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [searchForm, setSearchForm] = useState({
    product_name: "",
    product_category: "",
  });

  const handleChange = (e) => {
    if (e.target != undefined) {
      let { name, value } = e.target;
      setSearchForm((searchForm) => ({ ...searchForm, [name]: value }));
    }
  };

  const findProductOrder = () => {
    if (searchForm.product_name != "" || searchForm.product_category != "") {
      setCurrentPage(0);
      setSearchProduct(searchForm);
    }
  };

  const clearSearch = () => {
    if (searchForm.product_name != "" || searchForm.product_category != "") {
      setCurrentPage(0);
      setSearchForm({
        product_name: "",
        product_category: "",
      });
      setSearchProduct({ product_name: "", product_category: "" });
    }
  };

  return (
    <div className="my-product-main">
      {/* <h1>Product</h1> */}

      <div className="product-input">
        <div className="producthist_left  new-all-inputs my-1">
          <div className="namecategory">
            <TextField
              sx={{
                width: "100%",
              }}
              value={searchForm.product_name}
              name="product_name"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="product name"
              onChange={(e) => {
                handleChange(e);
              }}
            />

            <Select
              className="input_username new-edit-main-all"
              id="demo-simple-select-helper-label"
              name="product_category"
              placeholder="product category"
              value={searchForm.product_category}
              onChange={(e) => {
                handleChange(e);
              }}
              MenuProps={MenuProps}
            >
              {productcategory &&
                productcategory.length > 0 &&
                productcategory.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className="btn_productordersearch">
              <button
                type="button"
                className="btn-find-now"
                onClick={() => findProductOrder()}
              >
                Find
              </button>
              {/* <button className="btn-find-now" onClick={() => clearSearch()}>
              Clear
            </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="product-table table-responsive">
        <table class="table ">
          <tr className="product-list-table ">
            <th>Product Image</th>
            <th>Order ID</th>
            <th>Product Name</th>
            <th>Category Name</th>
            <th>Price</th>
            <th>Date</th>
          </tr>
          {isLoader == true ? (
            <tr>
              <td colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {orderProductList != undefined && orderProductList.length > 0 ? (
                <>
                  {" "}
                  {orderProductList.map((value, index) => {
                    return (
                      <tr className="product_history_image" key={index}>
                        <td>
                          {" "}
                          <NavLink to={`/product-details/${value.product_id}`}>
                            {" "}
                            {value.image != "" ? (
                              <img src={value.image} alt="" />
                            ) : (
                              ""
                            )}{" "}
                          </NavLink>{" "}
                        </td>
                        <td>{value.order_id}</td>
                        <td>{value.product_name}</td>
                        <td>{value.category}</td>
                        <td>
                          <span className="dolar_color">$</span> {value.price}
                        </td>
                        <td className="product-list-btn-all">{value.date}</td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="text-light">
                      <RecordNotfound />
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </table>
      </div>

      {page_count > 1 && (
        <div className="paginate_all">
          <ReactPaginate
            pageCount={page_count}
            forcePage={currentPage}
            onPageChange={paginate}
            containerClassName={"navigationButtons"}
            previousLinkClassName={"previousButton"}
            nextLinkClassName={"nextButton"}
            disabledClassName={"navigationDisabled"}
            activeClassName={"active"}
            pageRange={5}
            marginPagesDisplayed={2}
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.172"
                height="14"
                viewBox="0 0 7.172 14"
              >
                <path
                  id="fi-rr-angle-small-down"
                  d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                  transform="translate(7.172) rotate(90)"
                  fill="#454AA6"
                />
              </svg>
            }
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.172"
                height="14"
                viewBox="0 0 7.172 14"
              >
                <path
                  id="fi-rr-angle-small-down"
                  d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                  transform="translate(15.086 -5) rotate(90)"
                  fill="#454AA6"
                />
              </svg>
            }
          />
        </div>
      )}
    </div>
  );
};

export default ProductHistory;
