import React, { useEffect, useState } from "react";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faStar,
  faStarHalf,
} from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";
import { asyncEducatorFeedback } from "../../actions/courseAction";
import { NavLink, useParams } from "react-router-dom";
import { errorMsg } from "../../actions/customFn";

const EducatorFeedback = (props) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    course_id: id,
    ratings: 0,
    description: "",
  });

  const reactRatings = {
    edit: true,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleRating = (value) => {
    setFormData({ ...formData, ratings: value });
  };

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateFormData = async () => {
    try {
      if (formData.description == "") {
        errors.description = "Enter description";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEducatorFeedback = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncEducatorFeedback(formData, props.close);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <div className="new-EducatorModal">
      <Modal
        show={props.show}
        centered
        className="main_popup_inner donate_popup_main Ask-Educator-Question feedback-modal"
        onHide={props.close}
      >
        <FontAwesomeIcon onClick={props.close} icon={faTimes} />

        <Modal.Header>
          <Modal.Title className="popup_title_name">Feedback</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleEducatorFeedback}>
          <div className=" main_popup_inner1 new-all-inputs Ask-Educator-Questio-main">
            <div className="modal-inner-edinfo">
              <h2>Rate Your Experience</h2>
              <p>Are you Satisfied with the service</p>

              <div className="rate-star prd_star_rating">
                <ul>
                  <li>
                    <ReactStars
                      count={5}
                      onChange={handleRating}
                      name="ratings"
                      size={24}
                      value={Number(formData.ratings)}
                      {...reactRatings}
                    />
                  </li>
                  <li className="star_point">{formData.ratings}</li>
                </ul>
              </div>
            </div>

            <div className="text-inner mb-3">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Feedback
              </FormHelperText>
              <TextField
                sx={{
                  width: "100%",
                }}
                name="description"
                error={formErrors.description}
                className={`input_username new-edit-main-all `}
                id="outlined-basic"
                multiline
                rows={5}
                onChange={handleChange}
                helperText={formErrors.description}
                placeholder="Enter text"
              />
            </div>
          </div>

          <div className=" pop-EducatorModal-button  popup_donate_btn text-center">
            <button>Submit</button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EducatorFeedback;
