import React, { useEffect } from "react";
import { images } from "../../actions/customFn";
import { NavLink } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useState } from "react";
import { TextField, IconButton, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogout } from "../../redux/authSlice";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

const Header = () => {
  const [wallet, setWallet] = useState(null);
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userAuth);

  const { settingData } = useSelector((state) => state.userAuth);

  // const [pagesettingData, setPageSettingData] = useState('');

  // useEffect(()=>{

  //   if(settingData != undefined){
  //     setPageSettingData(settingData);
  //   }
  // }, [ ])

  // console.log("settingData=",settingData);

  const [userData, setuserData] = useState({});

  const [target, setTarget] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setuserData(userInfo);
  }, [userInfo]);

  const directpage = (paths) => {
    navigate(`/${paths}`);
  };

  const [isShow, setIsShow] = useState(false);
  const handleShowDate = () => setIsShow((wasOpened) => !wasOpened);
  const options = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 1",
    "Option 2",
    "Option 3",
  ];

  const loggedOut = () => {
    dispatch(setIsLogout());
    navigate("/login");
  };

  return (
    <>
      <div className="header_main_inner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 profile-header pl-0 pr-0">
              {/* header left dropdwon shivani */}
              <div className="header_left_profile new_left-btn-inner">
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <button
                        variant="contained"
                        {...bindTrigger(popupState)}
                        className={"left_profile_list"}
                      >
                        <div className="left_profile_inner">
                          <div className="pro_dis_main">
                            <div className="pro_img">
                              {userData && (
                                <img
                                  src={
                                    userData.image != ""
                                      ? userData.image
                                      : images["user_profile.png"]
                                  }
                                  alt=""
                                />
                              )}
                            </div>

                            <div className="flex_dis_new">
                              <div className="user_info">
                                {userData && (
                                  <h2>
                                    {userData.first_name} {userData.last_name}
                                  </h2>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="user_info">
                            {userData && <p>{userData.email}</p>}
                          </div>
                        </div>
                        <span>
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.5 17.5H1.30298L19.5 1.12268V17.5Z"
                              stroke="#6AC2FF"
                            />
                            <path d="M17 7V15H9" stroke="#6AC2FF" />
                          </svg>
                        </span>
                      </button>
                      <Menu
                        {...bindMenu(popupState)}
                        className="header_left_menu"
                      >
                        <MenuItem
                          onClick={() => {
                            directpage("profile");
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30909">
                              <path
                                id="Vector"
                                d="M19.3966 17.8945C19.3966 18.7461 19.1541 19.4766 18.6692 20.0859C18.1843 20.6953 17.6004 21 16.9177 21H6.97888C6.29612 21 5.71228 20.6953 5.22737 20.0859C4.74246 19.4766 4.5 18.7461 4.5 17.8945C4.5 17.2305 4.53297 16.6035 4.59892 16.0137C4.66487 15.4238 4.78707 14.8301 4.96552 14.2324C5.14397 13.6348 5.37091 13.123 5.64634 12.6973C5.92177 12.2715 6.28642 11.9238 6.7403 11.6543C7.19418 11.3848 7.71595 11.25 8.3056 11.25C9.32198 12.25 10.5362 12.75 11.9483 12.75C13.3603 12.75 14.5746 12.25 15.5909 11.25C16.1806 11.25 16.7024 11.3848 17.1562 11.6543C17.6101 11.9238 17.9748 12.2715 18.2502 12.6973C18.5256 13.123 18.7526 13.6348 18.931 14.2324C19.1095 14.8301 19.2317 15.4238 19.2976 16.0137C19.3636 16.6035 19.3966 17.2305 19.3966 17.8945ZM16.4172 7.5C16.4172 8.74219 15.9808 9.80273 15.108 10.6816C14.2351 11.5605 13.1819 12 11.9483 12C10.7147 12 9.66142 11.5605 8.78858 10.6816C7.91573 9.80273 7.47931 8.74219 7.47931 7.5C7.47931 6.25781 7.91573 5.19727 8.78858 4.31836C9.66142 3.43945 10.7147 3 11.9483 3C13.1819 3 14.2351 3.43945 15.108 4.31836C15.9808 5.19727 16.4172 6.25781 16.4172 7.5Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                          <p>My Profile</p>
                        </MenuItem>
                        <hr />
                        <MenuItem
                          onClick={() => {
                            directpage("orderhistory");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M14.8462 10.3846C11.9191 10.3846 9.53846 12.7662 9.53846 15.6923C9.53846 18.6185 11.9191 21 14.8462 21C17.7732 21 20.1538 18.6185 20.1538 15.6923C20.1538 12.7662 17.7732 10.3846 14.8462 10.3846ZM16.6508 16.3846H14.8462C14.6625 16.3846 14.4865 16.3117 14.3566 16.1818C14.2268 16.052 14.1538 15.8759 14.1538 15.6923V13.8138C14.1538 13.6302 14.2268 13.4541 14.3566 13.3243C14.4865 13.1945 14.6625 13.1215 14.8462 13.1215C15.0298 13.1215 15.2059 13.1945 15.3357 13.3243C15.4655 13.4541 15.5385 13.6302 15.5385 13.8138V15H16.6508C16.8344 15 17.0105 15.0729 17.1403 15.2028C17.2701 15.3326 17.3431 15.5087 17.3431 15.6923C17.3431 15.8759 17.2701 16.052 17.1403 16.1818C17.0105 16.3117 16.8344 16.3846 16.6508 16.3846ZM15.7692 3H6.53846C5.13538 3 4 4.13538 4 5.53846V17.5385C4 18.9415 5.13538 20.0769 6.53846 20.0769H9.80615C8.73736 18.8668 8.14947 17.3068 8.15385 15.6923C8.15385 12 11.1538 9 14.8462 9C16.12 9 17.3015 9.35077 18.3077 9.97846V5.53846C18.3077 4.13538 17.1723 3 15.7692 3ZM8.38462 12.6923H7.46154C7.08308 12.6923 6.76923 12.3785 6.76923 12C6.76923 11.6215 7.08308 11.3077 7.46154 11.3077H8.38462C8.76308 11.3077 9.07692 11.6215 9.07692 12C9.07692 12.3785 8.76308 12.6923 8.38462 12.6923ZM11.1538 9.92308H7.46154C7.08308 9.92308 6.76923 9.60923 6.76923 9.23077C6.76923 8.85231 7.08308 8.53846 7.46154 8.53846H11.1538C11.5323 8.53846 11.8462 8.85231 11.8462 9.23077C11.8462 9.60923 11.5323 9.92308 11.1538 9.92308ZM14.8462 7.15385H7.46154C7.08308 7.15385 6.76923 6.84 6.76923 6.46154C6.76923 6.08308 7.08308 5.76923 7.46154 5.76923H14.8462C15.2246 5.76923 15.5385 6.08308 15.5385 6.46154C15.5385 6.84 15.2246 7.15385 14.8462 7.15385Z"
                              fill="white"
                            />
                          </svg>
                          <p>Purchase History</p>
                        </MenuItem>
                        <hr />
                        <MenuItem
                          onClick={() => {
                            directpage("mystore");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.8 4H4.2C3.53726 4 3 4.53726 3 5.2V8.2C3 8.86274 3.53726 9.4 4.2 9.4H19.8C20.4627 9.4 21 8.86274 21 8.2V5.2C21 4.53726 20.4627 4 19.8 4Z"
                              fill="white"
                            />
                            <path
                              d="M19.8 9.99997H4.20002C4.09918 10.0022 3.99844 9.99211 3.90002 9.96997V17.8C3.90145 18.2769 4.09155 18.7339 4.42881 19.0712C4.76606 19.4084 5.22307 19.5985 5.70002 19.6H18.3C18.777 19.5985 19.234 19.4084 19.5712 19.0712C19.9085 18.7339 20.0986 18.2769 20.1 17.8V9.96997C20.0016 9.99211 19.9009 10.0022 19.8 9.99997ZM16.8 17.2H14.4C14.2409 17.2 14.0883 17.1368 13.9758 17.0242C13.8632 16.9117 13.8 16.7591 13.8 16.6C13.8 16.4408 13.8632 16.2882 13.9758 16.1757C14.0883 16.0632 14.2409 16 14.4 16H16.8C16.9592 16 17.1118 16.0632 17.2243 16.1757C17.3368 16.2882 17.4 16.4408 17.4 16.6C17.4 16.7591 17.3368 16.9117 17.2243 17.0242C17.1118 17.1368 16.9592 17.2 16.8 17.2Z"
                              fill="white"
                            />
                          </svg>
                          <p>My Store</p>
                        </MenuItem>

                        <hr />

                        <MenuItem
                          onClick={() => {
                            directpage("create-course");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.25 19.492V20.75C16.25 20.9489 16.171 21.1396 16.0303 21.2803C15.8897 21.421 15.6989 21.5 15.5 21.5C15.3011 21.5 15.1103 21.421 14.9697 21.2803C14.829 21.1396 14.75 20.9489 14.75 20.75V19.5H16C16.084 19.5 16.167 19.497 16.25 19.492ZM14.75 19.5H8C7.00544 19.5 6.05161 19.1049 5.34835 18.4016C4.64509 17.6984 4.25 16.7445 4.25 15.75V12.668L9.964 15.825C10.5898 16.1617 11.2894 16.3379 12 16.3379C12.7106 16.3379 13.4102 16.1617 14.036 15.825L14.75 15.431V19.5ZM16.25 14.602L19.75 12.668V15.75C19.7501 16.7013 19.3886 17.6171 18.7387 18.3119C18.0889 19.0067 17.1992 19.4285 16.25 19.492V14.602Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.75 14.2879L13.552 14.9499C13.0751 15.2069 12.5418 15.3414 12 15.3414C11.4582 15.3414 10.9249 15.2069 10.448 14.9499L2.134 10.3559C1.568 10.0429 1.25 9.49488 1.25 8.92188C1.25 8.34788 1.568 7.79988 2.134 7.48788L10.448 2.89288C10.9251 2.63643 11.4583 2.5022 12 2.5022C12.5417 2.5022 13.0749 2.63643 13.552 2.89288L21.866 7.48788C22.432 7.79988 22.75 8.34788 22.75 8.92188C22.75 9.49488 22.432 10.0429 21.866 10.3559L16.25 13.4589V12.7499C16.2501 12.6514 16.2307 12.5539 16.1929 12.4629C16.1552 12.372 16.0998 12.2894 16.03 12.2199L12.53 8.71988C12.4604 8.65028 12.3778 8.59507 12.2868 8.55741C12.1959 8.51974 12.0984 8.50035 12 8.50035C11.9016 8.50035 11.8041 8.51974 11.7132 8.55741C11.6222 8.59507 11.5396 8.65028 11.47 8.71988C11.4004 8.78948 11.3452 8.87211 11.3075 8.96305C11.2699 9.05399 11.2505 9.15145 11.2505 9.24988C11.2505 9.34831 11.2699 9.44578 11.3075 9.53672C11.3452 9.62765 11.4004 9.71028 11.47 9.77988L14.75 13.0609V14.2879Z"
                              fill="white"
                            />
                          </svg>
                          <p>My Course</p>
                        </MenuItem>

                        <hr />
                        <MenuItem
                          onClick={() => {
                            directpage("add-to-cart");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.25 19.492V20.75C16.25 20.9489 16.171 21.1396 16.0303 21.2803C15.8897 21.421 15.6989 21.5 15.5 21.5C15.3011 21.5 15.1103 21.421 14.9697 21.2803C14.829 21.1396 14.75 20.9489 14.75 20.75V19.5H16C16.084 19.5 16.167 19.497 16.25 19.492ZM14.75 19.5H8C7.00544 19.5 6.05161 19.1049 5.34835 18.4016C4.64509 17.6984 4.25 16.7445 4.25 15.75V12.668L9.964 15.825C10.5898 16.1617 11.2894 16.3379 12 16.3379C12.7106 16.3379 13.4102 16.1617 14.036 15.825L14.75 15.431V19.5ZM16.25 14.602L19.75 12.668V15.75C19.7501 16.7013 19.3886 17.6171 18.7387 18.3119C18.0889 19.0067 17.1992 19.4285 16.25 19.492V14.602Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.75 14.2879L13.552 14.9499C13.0751 15.2069 12.5418 15.3414 12 15.3414C11.4582 15.3414 10.9249 15.2069 10.448 14.9499L2.134 10.3559C1.568 10.0429 1.25 9.49488 1.25 8.92188C1.25 8.34788 1.568 7.79988 2.134 7.48788L10.448 2.89288C10.9251 2.63643 11.4583 2.5022 12 2.5022C12.5417 2.5022 13.0749 2.63643 13.552 2.89288L21.866 7.48788C22.432 7.79988 22.75 8.34788 22.75 8.92188C22.75 9.49488 22.432 10.0429 21.866 10.3559L16.25 13.4589V12.7499C16.2501 12.6514 16.2307 12.5539 16.1929 12.4629C16.1552 12.372 16.0998 12.2894 16.03 12.2199L12.53 8.71988C12.4604 8.65028 12.3778 8.59507 12.2868 8.55741C12.1959 8.51974 12.0984 8.50035 12 8.50035C11.9016 8.50035 11.8041 8.51974 11.7132 8.55741C11.6222 8.59507 11.5396 8.65028 11.47 8.71988C11.4004 8.78948 11.3452 8.87211 11.3075 8.96305C11.2699 9.05399 11.2505 9.15145 11.2505 9.24988C11.2505 9.34831 11.2699 9.44578 11.3075 9.53672C11.3452 9.62765 11.4004 9.71028 11.47 9.77988L14.75 13.0609V14.2879Z"
                              fill="white"
                            />
                          </svg>
                          <p>Add To Cart</p>
                        </MenuItem>

                        <hr />
                        <MenuItem
                          onClick={() => {
                            directpage("wishlist");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.25 19.492V20.75C16.25 20.9489 16.171 21.1396 16.0303 21.2803C15.8897 21.421 15.6989 21.5 15.5 21.5C15.3011 21.5 15.1103 21.421 14.9697 21.2803C14.829 21.1396 14.75 20.9489 14.75 20.75V19.5H16C16.084 19.5 16.167 19.497 16.25 19.492ZM14.75 19.5H8C7.00544 19.5 6.05161 19.1049 5.34835 18.4016C4.64509 17.6984 4.25 16.7445 4.25 15.75V12.668L9.964 15.825C10.5898 16.1617 11.2894 16.3379 12 16.3379C12.7106 16.3379 13.4102 16.1617 14.036 15.825L14.75 15.431V19.5ZM16.25 14.602L19.75 12.668V15.75C19.7501 16.7013 19.3886 17.6171 18.7387 18.3119C18.0889 19.0067 17.1992 19.4285 16.25 19.492V14.602Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.75 14.2879L13.552 14.9499C13.0751 15.2069 12.5418 15.3414 12 15.3414C11.4582 15.3414 10.9249 15.2069 10.448 14.9499L2.134 10.3559C1.568 10.0429 1.25 9.49488 1.25 8.92188C1.25 8.34788 1.568 7.79988 2.134 7.48788L10.448 2.89288C10.9251 2.63643 11.4583 2.5022 12 2.5022C12.5417 2.5022 13.0749 2.63643 13.552 2.89288L21.866 7.48788C22.432 7.79988 22.75 8.34788 22.75 8.92188C22.75 9.49488 22.432 10.0429 21.866 10.3559L16.25 13.4589V12.7499C16.2501 12.6514 16.2307 12.5539 16.1929 12.4629C16.1552 12.372 16.0998 12.2894 16.03 12.2199L12.53 8.71988C12.4604 8.65028 12.3778 8.59507 12.2868 8.55741C12.1959 8.51974 12.0984 8.50035 12 8.50035C11.9016 8.50035 11.8041 8.51974 11.7132 8.55741C11.6222 8.59507 11.5396 8.65028 11.47 8.71988C11.4004 8.78948 11.3452 8.87211 11.3075 8.96305C11.2699 9.05399 11.2505 9.15145 11.2505 9.24988C11.2505 9.34831 11.2699 9.44578 11.3075 9.53672C11.3452 9.62765 11.4004 9.71028 11.47 9.77988L14.75 13.0609V14.2879Z"
                              fill="white"
                            />
                          </svg>
                          <p>My Wishlist</p>
                        </MenuItem>
                        <hr />

                        <MenuItem
                          onClick={() => {
                            directpage("earn-rewards");
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30909">
                              <path
                                id="Vector"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM12 8.14286C11.6349 8.14286 11.3906 8.58 10.902 9.45814L10.776 9.68443C10.6371 9.93386 10.5677 10.0573 10.4584 10.1396C10.3504 10.2219 10.2154 10.2527 9.94671 10.3131L9.70114 10.3697C8.75229 10.5844 8.27786 10.6911 8.16471 11.0537C8.05157 11.4163 8.37557 11.7956 9.02229 12.5516L9.18943 12.747C9.37329 12.9617 9.46586 13.0684 9.507 13.2021C9.54814 13.3359 9.534 13.4786 9.507 13.7653L9.48129 14.0263C9.38357 15.0356 9.33471 15.5409 9.63043 15.7646C9.92614 15.9883 10.3697 15.7839 11.2581 15.375L11.487 15.2696C11.7403 15.1539 11.8663 15.0947 12 15.0947C12.1337 15.0947 12.2597 15.1539 12.513 15.2696L12.7419 15.375C13.6303 15.7839 14.0751 15.9883 14.3696 15.7646C14.6653 15.5409 14.6164 15.0356 14.5187 14.0263L14.493 13.7653C14.466 13.4786 14.4519 13.3359 14.493 13.2021C14.5341 13.0697 14.6267 12.9617 14.8106 12.747L14.9777 12.5516C15.6244 11.7956 15.9484 11.4176 15.8353 11.0537C15.7221 10.6899 15.2477 10.5844 14.2989 10.3697L14.0546 10.3131C13.7846 10.2527 13.6496 10.2231 13.5403 10.1396C13.4323 10.0573 13.3629 9.93386 13.224 9.68443L13.098 9.45814C12.6094 8.58 12.3651 8.14286 12 8.14286Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                          <p>Rewards Point</p>
                        </MenuItem>
                        <hr />

                        <MenuItem
                          onClick={() => {
                            directpage("legal-compliance");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.8 4H4.2C3.53726 4 3 4.53726 3 5.2V8.2C3 8.86274 3.53726 9.4 4.2 9.4H19.8C20.4627 9.4 21 8.86274 21 8.2V5.2C21 4.53726 20.4627 4 19.8 4Z"
                              fill="white"
                            />
                            <path
                              d="M19.8 9.99997H4.20002C4.09918 10.0022 3.99844 9.99211 3.90002 9.96997V17.8C3.90145 18.2769 4.09155 18.7339 4.42881 19.0712C4.76606 19.4084 5.22307 19.5985 5.70002 19.6H18.3C18.777 19.5985 19.234 19.4084 19.5712 19.0712C19.9085 18.7339 20.0986 18.2769 20.1 17.8V9.96997C20.0016 9.99211 19.9009 10.0022 19.8 9.99997ZM16.8 17.2H14.4C14.2409 17.2 14.0883 17.1368 13.9758 17.0242C13.8632 16.9117 13.8 16.7591 13.8 16.6C13.8 16.4408 13.8632 16.2882 13.9758 16.1757C14.0883 16.0632 14.2409 16 14.4 16H16.8C16.9592 16 17.1118 16.0632 17.2243 16.1757C17.3368 16.2882 17.4 16.4408 17.4 16.6C17.4 16.7591 17.3368 16.9117 17.2243 17.0242C17.1118 17.1368 16.9592 17.2 16.8 17.2Z"
                              fill="white"
                            />
                          </svg>
                          <p>Legal & Compliance</p>
                        </MenuItem>

                        <hr />

                        <MenuItem onClick={() => loggedOut(popupState.close)}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30911">
                              <g id="Group 30923">
                                <path
                                  id="Vector"
                                  d="M9.23071 12.926H17.4389L15.5523 14.8601L16.8461 16.1539L20.9999 12L16.8461 7.84619L15.5048 9.13994L17.4389 11.0741H9.23071V12.926Z"
                                  fill="#FF2F2F"
                                />
                                <path
                                  id="Vector_2"
                                  d="M11.987 19.1524C10.0788 19.1524 8.2875 18.4082 6.9375 17.0582C5.5875 15.7082 4.8476 13.9125 4.8476 12C4.8476 10.0918 5.59183 8.29183 6.9375 6.94183C8.2875 5.59183 10.0788 4.8476 11.987 4.8476C13.8909 4.8476 15.6779 5.5875 17.0279 6.93317L18.3389 5.62212C17.9712 5.25865 17.5687 4.92115 17.1404 4.61827C15.6216 3.5625 13.8433 3 11.987 3C7.03269 3 3 7.03702 3 12C3 16.963 7.03269 21 11.987 21C13.8433 21 15.6216 20.4375 17.1361 19.3774C17.5688 19.0745 17.9668 18.7413 18.3346 18.3736L17.0279 17.0668C15.6822 18.4125 13.8909 19.1524 11.987 19.1524Z"
                                  fill="#FF2F2F"
                                />
                              </g>
                            </g>
                          </svg>
                          <p className="logout_btn">Logout</p>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 menu-header">
              <div className="header_all_menu">
                <ul>
                  <li>
                    <NavLink to="/">The Bay</NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile">Profile</NavLink>
                  </li>
                  <li>
                    <NavLink to="/courses">Elite Academy</NavLink>
                  </li>
                  <li
                    className={`${
                      settingData.header_logo == undefined
                        ? "logo_li"
                        : "logo_lies"
                    }`}
                  >
                    {/* // logo_lies */}
                    <NavLink to="/">
                      {/* <img src={images["logo2.png"]} alt="" /> */}
                      {/* {console.log("settingData.header_logo",settingData.header_logo)} */}
                      <img
                        src={
                          settingData.header_logo == undefined
                            ? images["logo2.png"]
                            : settingData.header_logo
                        }
                        alt=""
                      />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/donate">Launchpad</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/profile">Profile</NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/nft">Marketplace</NavLink>
                  </li>

                  <li>
                    <NavLink to="/earn-rewards">Earn Rewards</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 btn-header pr-0 pl-0">
              <div className="header_right_main" onClick={() => open()}>
                <button>
                  {address !== undefined ? "Disconnect" : "Connect"} Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="all_mobile_data">
        <div className="nav_mobile_main">
          <div className="mobile_all">
            <div className="mobile_logo">
              <div className="header_logo_image">
                <NavLink to="/">
                  <img src={images["mobile-logo.png"]} alt="" />
                </NavLink>
              </div>
            </div>

            <div className="mobile_search_new">
              <div className="input_search">
                <Autocomplete
                  options={options}
                  value="Search Items , Collections , and Accounts or #hashtags"
                  className="search-all"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <IconButton type="submit" aria-label="search">
                            <SearchIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="mobile_drawer">
              <button className="Btn_show_drawer" onClick={toggleDrawer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="30px"
                  height="30px"
                >
                  <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" />
                </svg>
              </button>
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="mobile_nav_menu"
              >
                <div className="drawer_nav_mobile">
                  <div className="mobile_logo">
                    <div className="header_logo_image">
                      {/* <img src={images["logo_mobile.png"]} alt="" /> */}
                      <NavLink to="/">
                        <img src={images["mobile-logo.png"]} alt="" />
                      </NavLink>
                    </div>
                  </div>

                  <ul>
                    <li>
                      <NavLink to="/">The Bay</NavLink>
                    </li>
                    <li>
                      <NavLink to="/profile">Profile</NavLink>
                    </li>
                    <li>
                      <NavLink to="/courses">Elite Academy</NavLink>
                    </li>

                    <li>
                      <NavLink to="/donate">Launchpad</NavLink>
                    </li>
                    <li>
                      <NavLink to="/nft">Marketplace</NavLink>
                    </li>
                    <li>
                      <NavLink to="/earn-rewards">Earn Rewards</NavLink>
                    </li>

                    <li>
                      {userData && (
                        <div className="pro_img" onClick={handleShowDate}>
                          <img src={userData.image} alt="" />
                          <h2>
                            {userData.first_name} {userData.last_name}{" "}
                          </h2>
                        </div>
                      )}
                    </li>

                    {isShow && (
                      <div className="list_toggel">
                        <MenuItem
                          onClick={() => {
                            directpage("profile");
                          }}
                          className="my-new-menu"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30909">
                              <path
                                id="Vector"
                                d="M19.3966 17.8945C19.3966 18.7461 19.1541 19.4766 18.6692 20.0859C18.1843 20.6953 17.6004 21 16.9177 21H6.97888C6.29612 21 5.71228 20.6953 5.22737 20.0859C4.74246 19.4766 4.5 18.7461 4.5 17.8945C4.5 17.2305 4.53297 16.6035 4.59892 16.0137C4.66487 15.4238 4.78707 14.8301 4.96552 14.2324C5.14397 13.6348 5.37091 13.123 5.64634 12.6973C5.92177 12.2715 6.28642 11.9238 6.7403 11.6543C7.19418 11.3848 7.71595 11.25 8.3056 11.25C9.32198 12.25 10.5362 12.75 11.9483 12.75C13.3603 12.75 14.5746 12.25 15.5909 11.25C16.1806 11.25 16.7024 11.3848 17.1562 11.6543C17.6101 11.9238 17.9748 12.2715 18.2502 12.6973C18.5256 13.123 18.7526 13.6348 18.931 14.2324C19.1095 14.8301 19.2317 15.4238 19.2976 16.0137C19.3636 16.6035 19.3966 17.2305 19.3966 17.8945ZM16.4172 7.5C16.4172 8.74219 15.9808 9.80273 15.108 10.6816C14.2351 11.5605 13.1819 12 11.9483 12C10.7147 12 9.66142 11.5605 8.78858 10.6816C7.91573 9.80273 7.47931 8.74219 7.47931 7.5C7.47931 6.25781 7.91573 5.19727 8.78858 4.31836C9.66142 3.43945 10.7147 3 11.9483 3C13.1819 3 14.2351 3.43945 15.108 4.31836C15.9808 5.19727 16.4172 6.25781 16.4172 7.5Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                          <p>My Profile</p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            directpage("orderhistory");
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30909">
                              <g id="Group">
                                <path
                                  id="Vector"
                                  d="M14.8462 10.3846C11.9191 10.3846 9.53846 12.7662 9.53846 15.6923C9.53846 18.6185 11.9191 21 14.8462 21C17.7732 21 20.1538 18.6185 20.1538 15.6923C20.1538 12.7662 17.7732 10.3846 14.8462 10.3846ZM16.6508 16.3846H14.8462C14.6625 16.3846 14.4865 16.3117 14.3566 16.1818C14.2268 16.052 14.1538 15.8759 14.1538 15.6923V13.8138C14.1538 13.6302 14.2268 13.4541 14.3566 13.3243C14.4865 13.1945 14.6625 13.1215 14.8462 13.1215C15.0298 13.1215 15.2059 13.1945 15.3357 13.3243C15.4655 13.4541 15.5385 13.6302 15.5385 13.8138V15H16.6508C16.8344 15 17.0105 15.0729 17.1403 15.2028C17.2701 15.3326 17.3431 15.5087 17.3431 15.6923C17.3431 15.8759 17.2701 16.052 17.1403 16.1818C17.0105 16.3117 16.8344 16.3846 16.6508 16.3846ZM15.7692 3H6.53846C5.13538 3 4 4.13538 4 5.53846V17.5385C4 18.9415 5.13538 20.0769 6.53846 20.0769H9.80615C8.73736 18.8668 8.14947 17.3068 8.15385 15.6923C8.15385 12 11.1538 9 14.8462 9C16.12 9 17.3015 9.35077 18.3077 9.97846V5.53846C18.3077 4.13538 17.1723 3 15.7692 3ZM8.38462 12.6923H7.46154C7.08308 12.6923 6.76923 12.3785 6.76923 12C6.76923 11.6215 7.08308 11.3077 7.46154 11.3077H8.38462C8.76308 11.3077 9.07692 11.6215 9.07692 12C9.07692 12.3785 8.76308 12.6923 8.38462 12.6923ZM11.1538 9.92308H7.46154C7.08308 9.92308 6.76923 9.60923 6.76923 9.23077C6.76923 8.85231 7.08308 8.53846 7.46154 8.53846H11.1538C11.5323 8.53846 11.8462 8.85231 11.8462 9.23077C11.8462 9.60923 11.5323 9.92308 11.1538 9.92308ZM14.8462 7.15385H7.46154C7.08308 7.15385 6.76923 6.84 6.76923 6.46154C6.76923 6.08308 7.08308 5.76923 7.46154 5.76923H14.8462C15.2246 5.76923 15.5385 6.08308 15.5385 6.46154C15.5385 6.84 15.2246 7.15385 14.8462 7.15385Z"
                                  fill="white"
                                />
                              </g>
                            </g>
                          </svg>
                          <p>Purchase History</p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            directpage("mystore");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.8 4H4.2C3.53726 4 3 4.53726 3 5.2V8.2C3 8.86274 3.53726 9.4 4.2 9.4H19.8C20.4627 9.4 21 8.86274 21 8.2V5.2C21 4.53726 20.4627 4 19.8 4Z"
                              fill="white"
                            />
                            <path
                              d="M19.8 9.99997H4.20002C4.09918 10.0022 3.99844 9.99211 3.90002 9.96997V17.8C3.90145 18.2769 4.09155 18.7339 4.42881 19.0712C4.76606 19.4084 5.22307 19.5985 5.70002 19.6H18.3C18.777 19.5985 19.234 19.4084 19.5712 19.0712C19.9085 18.7339 20.0986 18.2769 20.1 17.8V9.96997C20.0016 9.99211 19.9009 10.0022 19.8 9.99997ZM16.8 17.2H14.4C14.2409 17.2 14.0883 17.1368 13.9758 17.0242C13.8632 16.9117 13.8 16.7591 13.8 16.6C13.8 16.4408 13.8632 16.2882 13.9758 16.1757C14.0883 16.0632 14.2409 16 14.4 16H16.8C16.9592 16 17.1118 16.0632 17.2243 16.1757C17.3368 16.2882 17.4 16.4408 17.4 16.6C17.4 16.7591 17.3368 16.9117 17.2243 17.0242C17.1118 17.1368 16.9592 17.2 16.8 17.2Z"
                              fill="white"
                            />
                          </svg>
                          <p>My Store</p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            directpage("create-course");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.25 19.492V20.75C16.25 20.9489 16.171 21.1396 16.0303 21.2803C15.8897 21.421 15.6989 21.5 15.5 21.5C15.3011 21.5 15.1103 21.421 14.9697 21.2803C14.829 21.1396 14.75 20.9489 14.75 20.75V19.5H16C16.084 19.5 16.167 19.497 16.25 19.492ZM14.75 19.5H8C7.00544 19.5 6.05161 19.1049 5.34835 18.4016C4.64509 17.6984 4.25 16.7445 4.25 15.75V12.668L9.964 15.825C10.5898 16.1617 11.2894 16.3379 12 16.3379C12.7106 16.3379 13.4102 16.1617 14.036 15.825L14.75 15.431V19.5ZM16.25 14.602L19.75 12.668V15.75C19.7501 16.7013 19.3886 17.6171 18.7387 18.3119C18.0889 19.0067 17.1992 19.4285 16.25 19.492V14.602Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.75 14.2879L13.552 14.9499C13.0751 15.2069 12.5418 15.3414 12 15.3414C11.4582 15.3414 10.9249 15.2069 10.448 14.9499L2.134 10.3559C1.568 10.0429 1.25 9.49488 1.25 8.92188C1.25 8.34788 1.568 7.79988 2.134 7.48788L10.448 2.89288C10.9251 2.63643 11.4583 2.5022 12 2.5022C12.5417 2.5022 13.0749 2.63643 13.552 2.89288L21.866 7.48788C22.432 7.79988 22.75 8.34788 22.75 8.92188C22.75 9.49488 22.432 10.0429 21.866 10.3559L16.25 13.4589V12.7499C16.2501 12.6514 16.2307 12.5539 16.1929 12.4629C16.1552 12.372 16.0998 12.2894 16.03 12.2199L12.53 8.71988C12.4604 8.65028 12.3778 8.59507 12.2868 8.55741C12.1959 8.51974 12.0984 8.50035 12 8.50035C11.9016 8.50035 11.8041 8.51974 11.7132 8.55741C11.6222 8.59507 11.5396 8.65028 11.47 8.71988C11.4004 8.78948 11.3452 8.87211 11.3075 8.96305C11.2699 9.05399 11.2505 9.15145 11.2505 9.24988C11.2505 9.34831 11.2699 9.44578 11.3075 9.53672C11.3452 9.62765 11.4004 9.71028 11.47 9.77988L14.75 13.0609V14.2879Z"
                              fill="white"
                            />
                          </svg>
                          <p>My Course</p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            directpage("add-to-cart");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.25 19.492V20.75C16.25 20.9489 16.171 21.1396 16.0303 21.2803C15.8897 21.421 15.6989 21.5 15.5 21.5C15.3011 21.5 15.1103 21.421 14.9697 21.2803C14.829 21.1396 14.75 20.9489 14.75 20.75V19.5H16C16.084 19.5 16.167 19.497 16.25 19.492ZM14.75 19.5H8C7.00544 19.5 6.05161 19.1049 5.34835 18.4016C4.64509 17.6984 4.25 16.7445 4.25 15.75V12.668L9.964 15.825C10.5898 16.1617 11.2894 16.3379 12 16.3379C12.7106 16.3379 13.4102 16.1617 14.036 15.825L14.75 15.431V19.5ZM16.25 14.602L19.75 12.668V15.75C19.7501 16.7013 19.3886 17.6171 18.7387 18.3119C18.0889 19.0067 17.1992 19.4285 16.25 19.492V14.602Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.75 14.2879L13.552 14.9499C13.0751 15.2069 12.5418 15.3414 12 15.3414C11.4582 15.3414 10.9249 15.2069 10.448 14.9499L2.134 10.3559C1.568 10.0429 1.25 9.49488 1.25 8.92188C1.25 8.34788 1.568 7.79988 2.134 7.48788L10.448 2.89288C10.9251 2.63643 11.4583 2.5022 12 2.5022C12.5417 2.5022 13.0749 2.63643 13.552 2.89288L21.866 7.48788C22.432 7.79988 22.75 8.34788 22.75 8.92188C22.75 9.49488 22.432 10.0429 21.866 10.3559L16.25 13.4589V12.7499C16.2501 12.6514 16.2307 12.5539 16.1929 12.4629C16.1552 12.372 16.0998 12.2894 16.03 12.2199L12.53 8.71988C12.4604 8.65028 12.3778 8.59507 12.2868 8.55741C12.1959 8.51974 12.0984 8.50035 12 8.50035C11.9016 8.50035 11.8041 8.51974 11.7132 8.55741C11.6222 8.59507 11.5396 8.65028 11.47 8.71988C11.4004 8.78948 11.3452 8.87211 11.3075 8.96305C11.2699 9.05399 11.2505 9.15145 11.2505 9.24988C11.2505 9.34831 11.2699 9.44578 11.3075 9.53672C11.3452 9.62765 11.4004 9.71028 11.47 9.77988L14.75 13.0609V14.2879Z"
                              fill="white"
                            />
                          </svg>
                          <p>Add To Cart</p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            directpage("wishlist");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.25 19.492V20.75C16.25 20.9489 16.171 21.1396 16.0303 21.2803C15.8897 21.421 15.6989 21.5 15.5 21.5C15.3011 21.5 15.1103 21.421 14.9697 21.2803C14.829 21.1396 14.75 20.9489 14.75 20.75V19.5H16C16.084 19.5 16.167 19.497 16.25 19.492ZM14.75 19.5H8C7.00544 19.5 6.05161 19.1049 5.34835 18.4016C4.64509 17.6984 4.25 16.7445 4.25 15.75V12.668L9.964 15.825C10.5898 16.1617 11.2894 16.3379 12 16.3379C12.7106 16.3379 13.4102 16.1617 14.036 15.825L14.75 15.431V19.5ZM16.25 14.602L19.75 12.668V15.75C19.7501 16.7013 19.3886 17.6171 18.7387 18.3119C18.0889 19.0067 17.1992 19.4285 16.25 19.492V14.602Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.75 14.2879L13.552 14.9499C13.0751 15.2069 12.5418 15.3414 12 15.3414C11.4582 15.3414 10.9249 15.2069 10.448 14.9499L2.134 10.3559C1.568 10.0429 1.25 9.49488 1.25 8.92188C1.25 8.34788 1.568 7.79988 2.134 7.48788L10.448 2.89288C10.9251 2.63643 11.4583 2.5022 12 2.5022C12.5417 2.5022 13.0749 2.63643 13.552 2.89288L21.866 7.48788C22.432 7.79988 22.75 8.34788 22.75 8.92188C22.75 9.49488 22.432 10.0429 21.866 10.3559L16.25 13.4589V12.7499C16.2501 12.6514 16.2307 12.5539 16.1929 12.4629C16.1552 12.372 16.0998 12.2894 16.03 12.2199L12.53 8.71988C12.4604 8.65028 12.3778 8.59507 12.2868 8.55741C12.1959 8.51974 12.0984 8.50035 12 8.50035C11.9016 8.50035 11.8041 8.51974 11.7132 8.55741C11.6222 8.59507 11.5396 8.65028 11.47 8.71988C11.4004 8.78948 11.3452 8.87211 11.3075 8.96305C11.2699 9.05399 11.2505 9.15145 11.2505 9.24988C11.2505 9.34831 11.2699 9.44578 11.3075 9.53672C11.3452 9.62765 11.4004 9.71028 11.47 9.77988L14.75 13.0609V14.2879Z"
                              fill="white"
                            />
                          </svg>
                          <p>My Wishlist</p>
                        </MenuItem>

                        <MenuItem>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30909">
                              <path
                                id="Vector"
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM12 8.14286C11.6349 8.14286 11.3906 8.58 10.902 9.45814L10.776 9.68443C10.6371 9.93386 10.5677 10.0573 10.4584 10.1396C10.3504 10.2219 10.2154 10.2527 9.94671 10.3131L9.70114 10.3697C8.75229 10.5844 8.27786 10.6911 8.16471 11.0537C8.05157 11.4163 8.37557 11.7956 9.02229 12.5516L9.18943 12.747C9.37329 12.9617 9.46586 13.0684 9.507 13.2021C9.54814 13.3359 9.534 13.4786 9.507 13.7653L9.48129 14.0263C9.38357 15.0356 9.33471 15.5409 9.63043 15.7646C9.92614 15.9883 10.3697 15.7839 11.2581 15.375L11.487 15.2696C11.7403 15.1539 11.8663 15.0947 12 15.0947C12.1337 15.0947 12.2597 15.1539 12.513 15.2696L12.7419 15.375C13.6303 15.7839 14.0751 15.9883 14.3696 15.7646C14.6653 15.5409 14.6164 15.0356 14.5187 14.0263L14.493 13.7653C14.466 13.4786 14.4519 13.3359 14.493 13.2021C14.5341 13.0697 14.6267 12.9617 14.8106 12.747L14.9777 12.5516C15.6244 11.7956 15.9484 11.4176 15.8353 11.0537C15.7221 10.6899 15.2477 10.5844 14.2989 10.3697L14.0546 10.3131C13.7846 10.2527 13.6496 10.2231 13.5403 10.1396C13.4323 10.0573 13.3629 9.93386 13.224 9.68443L13.098 9.45814C12.6094 8.58 12.3651 8.14286 12 8.14286Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                          <p>Rewards Point</p>
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            directpage("legal-compliance");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.8 4H4.2C3.53726 4 3 4.53726 3 5.2V8.2C3 8.86274 3.53726 9.4 4.2 9.4H19.8C20.4627 9.4 21 8.86274 21 8.2V5.2C21 4.53726 20.4627 4 19.8 4Z"
                              fill="white"
                            />
                            <path
                              d="M19.8 9.99997H4.20002C4.09918 10.0022 3.99844 9.99211 3.90002 9.96997V17.8C3.90145 18.2769 4.09155 18.7339 4.42881 19.0712C4.76606 19.4084 5.22307 19.5985 5.70002 19.6H18.3C18.777 19.5985 19.234 19.4084 19.5712 19.0712C19.9085 18.7339 20.0986 18.2769 20.1 17.8V9.96997C20.0016 9.99211 19.9009 10.0022 19.8 9.99997ZM16.8 17.2H14.4C14.2409 17.2 14.0883 17.1368 13.9758 17.0242C13.8632 16.9117 13.8 16.7591 13.8 16.6C13.8 16.4408 13.8632 16.2882 13.9758 16.1757C14.0883 16.0632 14.2409 16 14.4 16H16.8C16.9592 16 17.1118 16.0632 17.2243 16.1757C17.3368 16.2882 17.4 16.4408 17.4 16.6C17.4 16.7591 17.3368 16.9117 17.2243 17.0242C17.1118 17.1368 16.9592 17.2 16.8 17.2Z"
                              fill="white"
                            />
                          </svg>
                          <p>Legal & Compliance</p>
                        </MenuItem>

                        <MenuItem onClick={() => loggedOut()}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Group 30911">
                              <g id="Group 30923">
                                <path
                                  id="Vector"
                                  d="M9.23071 12.926H17.4389L15.5523 14.8601L16.8461 16.1539L20.9999 12L16.8461 7.84619L15.5048 9.13994L17.4389 11.0741H9.23071V12.926Z"
                                  fill="#FF2F2F"
                                />
                                <path
                                  id="Vector_2"
                                  d="M11.987 19.1524C10.0788 19.1524 8.2875 18.4082 6.9375 17.0582C5.5875 15.7082 4.8476 13.9125 4.8476 12C4.8476 10.0918 5.59183 8.29183 6.9375 6.94183C8.2875 5.59183 10.0788 4.8476 11.987 4.8476C13.8909 4.8476 15.6779 5.5875 17.0279 6.93317L18.3389 5.62212C17.9712 5.25865 17.5687 4.92115 17.1404 4.61827C15.6216 3.5625 13.8433 3 11.987 3C7.03269 3 3 7.03702 3 12C3 16.963 7.03269 21 11.987 21C13.8433 21 15.6216 20.4375 17.1361 19.3774C17.5688 19.0745 17.9668 18.7413 18.3346 18.3736L17.0279 17.0668C15.6822 18.4125 13.8909 19.1524 11.987 19.1524Z"
                                  fill="#FF2F2F"
                                />
                              </g>
                            </g>
                          </svg>
                          <p className="logout_btn">Logout</p>
                        </MenuItem>
                      </div>
                    )}

                    <li className="zero_margin">
                      <div className="header_right_main">
                        <button>Connect Wallet</button>
                      </div>
                    </li>

                    {/* {isLoggedIn === true ? (
                            <li>
                              <div className="header_menu_btn">
                                <NavLink to="/login">Login</NavLink>
                                <NavLink to="/signup">Signup</NavLink>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="user_profile">
                                <NavLink to="/edit-profile">
                                  <img src={images["user_icon.png"]} alt="" />
                                  <span>User Name</span>
                                </NavLink>
                              </div>
                            </li>
                          )} */}
                  </ul>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
