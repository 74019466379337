import React, { useEffect, useState } from "react";
// import { images } from "../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import { FormHelperText, TextField, Tooltip } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { errorMsg, handleScrollError } from "../../actions/customFn";
import {
  asyncUpdatePersonalInfo,
  getUserDetail,
} from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
import ChipInput from "material-ui-chip-input";
import SmallLoader from "react-js-loader";

const AboutUs = (props) => {
  const { userInfo } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  const [isload, setIsLoad] = useState(false);
  const [formErrors, setFormError] = useState({});
  const errors = {};

  const [buttonloader, setButtonLoader] = useState(false);
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     props.setIsload(false);
  //   },1000);

  // },[ ])

  const [formData, setFormData] = useState({
    work: "",
    study: "",
    gender: "",
    skills: [],
    aboutme: "",
  });

  useEffect(() => {
    if (userInfo != undefined) {
      setFormData({
        work: userInfo.work,
        study: userInfo.study,
        gender: userInfo.gender,
        skills:
          userInfo.skills != undefined && userInfo.skills != ""
            ? userInfo.skills.split(",")
            : "",
        aboutme: userInfo.about_me,
      });
    }
  }, [userInfo]);

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
      // if(name=="skills"){
      //   setFormData((formData) => ({ ...formData, [name]: e }));
      // }
    }
  };

  // Add Chips
  const handleAddChip = (chip) => {
    // setFormData((formData) => ({ ...formData.skills, chip}));
    formData.skills = chip;

    // formData.skills.push(chip);
    // setChipValues((prev) => [...prev, chip.length > 15 ? chip.slice(0, 15) + '...' : chip])
  };

  // const handleDeleteChip = (chip) => {
  //   // console.log("chip", chip);
  //   let newChips = chipValues.filter((element) => element !== chip);
  //   setChipValues(newChips);
  //   formData.skills = chip;
  // };

  const handleRenderChip = (chip) => {
    const MAX_CHIP_LENGTH = 20; // Maximum length for chip value display
    if (chip.length > MAX_CHIP_LENGTH) {
      return (
        <Tooltip title={chip} key={chip}>
          <div style={{ cursor: "default" }}>{`${chip.substring(
            0,
            MAX_CHIP_LENGTH
          )}...`}</div>
        </Tooltip>
      );
    }
    return chip;
  };

  const validateFormData = async () => {
    try {
      if (formData.work == "" || formData.work == null) {
        errors.work = "Enter your work properly ";
      }
      if (formData.study == "" || formData.study == null) {
        errors.study = "Enter your study properly";
      }
      if (formData.gender == "" || formData.gender == null) {
        errors.gender = "Enter your gender properly";
      }
      if (formData.skills == "" || formData.skills == null) {
        errors.skills = "Enter your skill properly";
      }
      if (formData.aboutme == "" || formData.aboutme == null) {
        errors.aboutme = "Enter Text ";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();

  const handleUpdatePersonalInfo = (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        asyncUpdatePersonalInfo(
          formData,
          setIsLoad,
          navigate,
          dispatch,
          setButtonLoader
        );
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <>
      {isload == true ? (
        <Loader />
      ) : (
        <div className="aboutus">
          <div className="top-upload timep-upload profile-info">
            <h2>Add Your Personal Info</h2>

            {buttonloader == true ? (
              <button type="button" className="upload_btn">
                <SmallLoader
                  type="spinner-default"
                  bgColor={"#6ac2ff"}
                  color={"#6ac2ff"}
                  size={30}
                />
              </button>
            ) : (
              <button
                type="button"
                className="upload_btn"
                onClick={(e) => handleUpdatePersonalInfo(e)}
              >
                Update
              </button>
            )}
          </div>

          <div className="about-inptfields new-all-inputs p-3">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Your Work
                </FormHelperText>
                <TextField
                  sx={{
                    width: "100%",
                    color: "#fff",
                    borderColor: "#fff",
                  }}
                  name="work"
                  className="input_username new-edit-main-all"
                  id="outlined-basic"
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter your work"
                  error={formErrors.work}
                  value={formData.work}
                  helperText={formErrors.work}
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Your Study
                </FormHelperText>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  name="study"
                  className="input_username new-edit-main-all"
                  id="outlined-basic"
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter your study"
                  error={formErrors.study}
                  value={formData.study}
                  helperText={formErrors.study}
                />
              </div>

              <div className="col-xl-6 col-md-6">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Gender
                </FormHelperText>
                <div className="search_txteditor">
                  <div>
                    <Select
                      sx={{
                        width: "100%",
                      }}
                      className="input_username new-edit-main-all mb-1"
                      id="demo-simple-select-error"
                      name="gender"
                      placeholder="Select your gender"
                      value={formData.gender}
                      helperText={formErrors.gender}
                      error={Boolean(formErrors.gender)}
                      // onChange={(event) => setGender(event.target.value)}
                      onChange={(e) => handleChange(e)}
                    >
                      <MenuItem value="">
                        <em>Select your gender</em>
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </Select>
                    <FormHelperText className="text-danger">
                      {formErrors.gender}
                    </FormHelperText>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-md-6">
                {/* <TextField
                sx={{
                  width: "100%",
                }}
                name="skills"
                className="input_username new-edit-main-all"
                id="outlined-basic"
                label="Your Skills"
                onChange={(e) => handleChange(e)}
                placeholder="Enter your skills"
                error={formErrors.skills}
                value={formData.skills}
                helperText={formErrors.skills}
              /> */}
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Skills
                </FormHelperText>
                <div className="myskills_account new">
                  <ChipInput
                    name="skills"
                    defaultValue={formData.skills}
                    disableUnderline={true}
                    // defaultValue={chipValues}
                    // value={chipValues}
                    className="chipinput1"
                    placeholder="Type and press enter"
                    onChange={(e) => handleAddChip(e)}
                    style={{ width: "100%" }}
                    // onAdd={(e) => handleAddChip(e)}
                    // onDelete={(chip) => handleDeleteChip(chip)}
                    chipRenderer={({ value, handleDelete }) => (
                      <div className="customChipInput" key={value}>
                        {handleRenderChip(value)}
                        <svg
                          onClick={() => handleDelete()}
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                      </div>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {formErrors.skills}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-xl-12">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  About Me
                </FormHelperText>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  name="aboutme"
                  className="input_username new-edit-main-all"
                  id="outlined-basic"
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter text"
                  rows={5}
                  multiline
                  error={formErrors.aboutme}
                  value={formData.aboutme}
                  helperText={formErrors.aboutme}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutUs;
