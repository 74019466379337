import React from "react";
import ReactPlayer from "react-player";
import vidbg from "../../assets/images/video-1.png";
import { images } from "../../actions/customFn";
const VideoPlayer = () => {
  return (
    <>
      <div className="video_player">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          playing={true}
          controls={true}
          loop={true}
          width="100%"
          height="100%"
          muted={true}
          playIcon={
            <button>
              {" "}
              <img src={images["play-btn.png"]} alt="" />
            </button>
          }
          light={
            <img src={vidbg} alt="" style={{ width: "100%", height: "100%" }} />
          }
          playsinline={true}
        />
      </div>
    </>
  );
};

export default VideoPlayer;
