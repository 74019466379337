import { createSlice } from "@reduxjs/toolkit";
import { getcountryList } from "../actions/CountryAction";

const initialState = {
  loading: false,
  country_list: null,
  error: null,
  success: false,
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: {
    // Country List
    [getcountryList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getcountryList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.country_list = payload;
    },
    [getcountryList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    
    // Country List
  },
});


export default countrySlice.reducer;
