import React, { useEffect, useState } from "react";
import {
  FilterAll,
  FooterBig,
  FooterMobile,
  GridCourse,
  Header,
  MobileFilter,
  SearchInput,
  SimpleCourse,
} from "../components";
import { images } from "../actions/customFn";
import { motion } from "framer-motion";

import "react-tabs/style/react-tabs.css";
import { asyncgetcourseCategory, getCourseList } from "../actions/courseAction";
import { useParams } from "react-router-dom";

const Course = () => {
  const { id } = useParams();
  const [showgrid, setShowgrid] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [courselist, setCourseList] = useState([]);
  const [page_count, setPagecount] = useState(0);
  const [page_loading, setPageLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [Selectchecked, setSelectchecked] = useState([]);

  useEffect(() => {
    if (id != 0 && id != undefined) {
      setSelectchecked(id);
    }
  }, [id]);

  const [searchCourse, setSearchCourse] = useState();

  const [coursedetail, setCourseDetail] = useState();

  const [coursecount, setcoursecount] = useState("");
  useEffect(() => {
    if (currentPage == 0) {
      setIsLoader(true);
    }

    getCourseList(
      setCourseList,
      setIsLoader,
      setcoursecount,
      setPagecount,
      currentPage,
      setHasMore,
      Selectchecked,
      searchCourse
    );
  }, [currentPage, Selectchecked, searchCourse]);

  const refresh = (setItems) => {
    setCurrentPage(0);
  };

  useEffect(() => {
    asyncgetcourseCategory(setCourseDetail);
  }, []);

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 2000);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="all_page_bg new-inner-pages course-page">
          <div className="container-fluid">
            <Header />
            <div className="page-all-data list_margins">
              <div>
                <div className="paga-data-dis">
                  {id === undefined && (
                    <FilterAll
                      coursedetail={coursedetail}
                      setSelectchecked={setSelectchecked}
                      Selectchecked={Selectchecked}
                      setCurrentPage={setCurrentPage}
                      setCourseList={setCourseList}
                    />
                  )}

                  <div
                    className="page_data_mid_new"
                    style={{ width: id !== undefined ? "100%" : "" }}
                  >
                    {id === undefined && (
                      <SearchInput
                        setSearchCourse={setSearchCourse}
                        setCurrentPage={setCurrentPage}
                        setCourseList={setCourseList}
                      />
                    )}
                    <div className="blender_main_page">
                      <div className="blender_top_hedding">
                        <h2>3ds blender</h2>
                        <div className="grid-simple">
                          {/* <button onClick={() => setShowgrid(true)}>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M17.339 9.37979L2.65625 9.375C2.28329 9.375 1.9256 9.22684 1.66188 8.96312C1.39816 8.6994 1.25 8.34171 1.25 7.96875V2.65625C1.25 2.28329 1.39816 1.9256 1.66188 1.66188C1.9256 1.39816 2.28329 1.25 2.65625 1.25L17.339 1.25479C17.7119 1.25479 18.0696 1.40294 18.3333 1.66667C18.5971 1.93039 18.7452 2.28807 18.7452 2.66104V7.97354C18.7452 8.3465 18.5971 8.70418 18.3333 8.96791C18.0696 9.23163 17.7119 9.37979 17.339 9.37979Z"
                                  // fill="white"
                                  fill={`${
                                    showgrid === false ? "#454aa6" : "white"
                                  }`}
                                />
                                <path
                                  d="M17.339 18.5465L2.65625 18.5417C2.28329 18.5417 1.9256 18.3936 1.66188 18.1299C1.39816 17.8661 1.25 17.5085 1.25 17.1355V11.823C1.25 11.45 1.39816 11.0924 1.66188 10.8286C1.9256 10.5649 2.28329 10.4167 2.65625 10.4167L17.339 10.4215C17.7119 10.4215 18.0696 10.5697 18.3333 10.8334C18.5971 11.0971 18.7452 11.4548 18.7452 11.8278V17.1403C18.7452 17.5132 18.5971 17.8709 18.3333 18.1347C18.0696 18.3984 17.7119 18.5465 17.339 18.5465Z"
                                  // fill="white"
                                  fill={`${
                                    showgrid === false ? "#454aa6" : "white"
                                  }`}
                                />
                              </svg>{" "}
                            </button>
                            <button onClick={() => setShowgrid(false)}>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M7.96875 9.375H2.65625C2.28329 9.375 1.9256 9.22684 1.66188 8.96312C1.39816 8.6994 1.25 8.34171 1.25 7.96875V2.65625C1.25 2.28329 1.39816 1.9256 1.66188 1.66188C1.9256 1.39816 2.28329 1.25 2.65625 1.25H7.96875C8.34171 1.25 8.6994 1.39816 8.96312 1.66188C9.22684 1.9256 9.375 2.28329 9.375 2.65625V7.96875C9.375 8.34171 9.22684 8.6994 8.96312 8.96312C8.6994 9.22684 8.34171 9.375 7.96875 9.375Z"
                                  // fill="white"
                                  fill={`${
                                    showgrid === true ? "#454aa6" : "white"
                                  }`}
                                />
                                <path
                                  d="M17.3438 9.375H12.0312C11.6583 9.375 11.3006 9.22684 11.0369 8.96312C10.7732 8.6994 10.625 8.34171 10.625 7.96875V2.65625C10.625 2.28329 10.7732 1.9256 11.0369 1.66188C11.3006 1.39816 11.6583 1.25 12.0312 1.25H17.3438C17.7167 1.25 18.0744 1.39816 18.3381 1.66188C18.6018 1.9256 18.75 2.28329 18.75 2.65625V7.96875C18.75 8.34171 18.6018 8.6994 18.3381 8.96312C18.0744 9.22684 17.7167 9.375 17.3438 9.375Z"
                                  // fill="white"
                                  fill={`${
                                    showgrid === true ? "#454aa6" : "white"
                                  }`}
                                />
                                <path
                                  d="M7.96875 18.75H2.65625C2.28329 18.75 1.9256 18.6018 1.66188 18.3381C1.39816 18.0744 1.25 17.7167 1.25 17.3438V12.0312C1.25 11.6583 1.39816 11.3006 1.66188 11.0369C1.9256 10.7732 2.28329 10.625 2.65625 10.625H7.96875C8.34171 10.625 8.6994 10.7732 8.96312 11.0369C9.22684 11.3006 9.375 11.6583 9.375 12.0312V17.3438C9.375 17.7167 9.22684 18.0744 8.96312 18.3381C8.6994 18.6018 8.34171 18.75 7.96875 18.75Z"
                                  // fill="white"
                                  fill={`${
                                    showgrid === true ? "#454aa6" : "white"
                                  }`}
                                />
                                <path
                                  d="M17.3438 18.75H12.0312C11.6583 18.75 11.3006 18.6018 11.0369 18.3381C10.7732 18.0744 10.625 17.7167 10.625 17.3438V12.0312C10.625 11.6583 10.7732 11.3006 11.0369 11.0369C11.3006 10.7732 11.6583 10.625 12.0312 10.625H17.3438C17.7167 10.625 18.0744 10.7732 18.3381 11.0369C18.6018 11.3006 18.75 11.6583 18.75 12.0312V17.3438C18.75 17.7167 18.6018 18.0744 18.3381 18.3381C18.0744 18.6018 17.7167 18.75 17.3438 18.75Z"
                                  // fill="white"
                                  fill={`${
                                    showgrid === true ? "#454aa6" : "white"
                                  }`}
                                />
                              </svg>{" "}
                            </button> */}
                          <p>{coursecount} results</p>
                        </div>
                      </div>

                      {showgrid ? (
                        <GridCourse
                          courselist={{
                            courselist,
                            page_count,
                            currentPage,
                            handleLoadMore,
                            page_loading,
                            hasMore,
                            refresh,
                            isLoader,
                          }}
                        />
                      ) : (
                        <SimpleCourse
                          courselist={{
                            courselist,
                            page_count,
                            currentPage,
                            handleLoadMore,
                            page_loading,
                            hasMore,
                            refresh,
                            isLoader,
                          }}
                        />
                      )}
                    </div>

                    <div className="filter_mobile">
                      <MobileFilter
                        coursedetail={coursedetail}
                        setSelectchecked={setSelectchecked}
                        Selectchecked={Selectchecked}
                        setCurrentPage={setCurrentPage}
                      />
                    </div>
                  </div>

                  {/* <div className="filter_mobile">
                  <MobileFilter />
                </div> */}
                </div>
              </div>
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
            <FooterBig />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Course;
