import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

export const asyncAddCampaign = async (
  formdata,
  setShow,
  setIsLoader,
  setButtonLoader
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("first_name", formdata.firstname);
    sendformData.append("last_name", formdata.last_name);
    sendformData.append("email_address", formdata.email);
    sendformData.append("phone", formdata.phone);
    sendformData.append("account_holder", formdata.account_holder);
    sendformData.append("ifsc", formdata.ifscCode);
    sendformData.append("title", formdata.title);
    sendformData.append("sort_description", formdata.shortDescription);
    sendformData.append("image", formdata.image);
    sendformData.append("account_number", formdata.accountnumber);
    sendformData.append("target", formdata.campaigntarget);
    sendformData.append("category", formdata.category);
    sendformData.append("insurance_number", formdata.national_insurance);
    const url = "/add/campaign";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        // setState(response.data);
        successMsg(response.data.message);
        setTimeout(()=>{
          setButtonLoader(false);
          setShow(false);
        },1000)
        // successMsg(response.data.message);
        // setIsLoader(true);
        //   navigate('/post-list');
      } else {
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// campaign category

export const getCampaignCategory = async (setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
    };
    const url = "/campaign_categories";
    await axios.post(url, formData).then((response) => {
      if (response.data) {
        setState(response.data.data);
      } else {
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------- campaign details -------
export const getCampaignDetail = async (
  camp_id,
  setState,
  setIsLoader,
  navigate
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      campaign_id: camp_id,
    };
    const url = "/campaign_details";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setState(response.data.data);
      } else {
        errorMsg(response.data.message);
        navigate("/donate");
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//----get Currency List-------------
export const getCurrencyList = async (setGetCurrency) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
    };

    const url = "/get_currency";

    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setGetCurrency(response.data.data);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    console.log("error",error)
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};



//----add Campaign-------------
export const addCompaignComment = async (id, data,setCommentshow,setIsAddComment) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      campaign_id: id,
      comments: data.comments,
    };
    const url = "/add_campaign_comments";

    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setCommentshow();
        setIsAddComment((prev)=> prev ===1? 0:1);
        successMsg(response.data.message);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};


//----add Campaign-------------
export const getCampaignList = async (id,setCampaignComment,setIsLoader) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      campaign_id: id,
    };

    const url = "/get_campaign_comments";

    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        setCampaignComment(response.data.data);
        setIsLoader(false);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};