import React from "react";

const DonateCard = (props) => {
  const DonateCard_list = props.data;

  return (
    <div className="donate_card_main">
      <div className="row">
        {DonateCard_list.map(function(value, index) {
          return (
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 "
              key={index}
            >
              <div className="donate_card_border">
                <div
                  className="donate_card_inner"
                  style={{
                    backgroundImage: `url(${value.image})`,
                  }}
                >
                  <div className="donate_card_main_bg_color">
                    <img src={value.icon} alt="" />
                    <h1>{value.title}</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: value.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DonateCard;
