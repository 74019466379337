import React, { useState } from "react";
import { errorMsg, handleScrollError, images } from "../../actions/customFn";
// import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { loadStripe } from "@stripe/stripe-js";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../Checkout/StripeCheckoutForm";
import { asyncCourseBuyItnow } from "../../actions/courseAction";
import SmallLoader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CourseBuyNow = (props) => {
  const { amount, id, setIsRender, isRender, setLoadPage } = props;
  const { settingData } = useSelector((state) => state.userAuth);

  const PUBLISHABLE_KEY = settingData.stripe_publish_key;
  const stripePromise = loadStripe(PUBLISHABLE_KEY);

  const paymentOptions = {
    mode: "payment",
    amount: Math.round(amount),
    currency: "usd",
  };

  const [error, setError] = useState(null);
  const [checkterm, setCheckTerm] = useState(false);

  const handleCheckTerm = (e) => {
    if (e.target.checked == true) {
      setCheckTerm(true);
    } else {
      setCheckTerm(false);
    }
  };
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const navigate = useNavigate();

  const [buttonloader, setButtonLoader] = useState(false);

  const validateFormData = async () => {
    try {
      if (checkterm == false) {
        errors.checkterm = "Read and Check term and conditions";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyCourse = async (
    e,
    { stripe, elements, CardCvcElement, CardExpiryElement, CardNumberElement }
  ) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        const { token, error } = await stripe.createToken(
          elements.getElement(
            CardCvcElement,
            CardExpiryElement,
            CardNumberElement
          )
        );

        if (error) {
          errorMsg(error.message);
        } else {
          if (token) {
            setButtonLoader(true);
            // setLoadPage(true);
            asyncCourseBuyItnow(id, token.id, props.close, {
              setIsRender,
              isRender,
              setLoadPage,
              setButtonLoader,
              navigate,
            });
          }
        }
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg(error.response.data.message);
    }
  };

  return (
    <Modal
      show={props.show}
      centered
      className="main_popup_inner donate_popup_main payment-form-modal"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <Modal.Title className="popup_title_name">Payment Form</Modal.Title>
      </Modal.Header>
      <h1>Payment Form</h1>

      <div className="main_popup_inner1 new-all-inputs">
        <Elements
          stripe={stripePromise}
          options={{
            mode: "payment",
            amount: Math.round(amount),
            currency: "usd",
          }}
        >
          <StripeCheckoutForm
            button_text={
              buttonloader == true ? (
                <SmallLoader
                  type="spinner-default"
                  bgColor={"#fff"}
                  color={"#fff"}
                  size={40}
                />
              ) : (
                "Buy Now"
              )
            }
            error={error}
            handleCheckOut={buttonloader == false && handleBuyCourse}
            formErrors={formErrors}
            handleCheckTerm={handleCheckTerm}
          />
        </Elements>
      </div>
    </Modal>
  );
};

export default CourseBuyNow;
