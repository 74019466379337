import React from "react";
import { images } from "../../actions/customFn";

const data = [
  {
    image: images["friend-img1.png"],
  },
  {
    image: images["courses_list_2.png"],
  },
  {
    image: images["courses_list_3.png"],
  },
  {
    image: images["courses_list_2.png"],
  },
  {
    image: images["courses_list_3.png"],
  },
  {
    image: images["courses_list_1.png"],
  },
];

const FriendsList = () => {
  return (
    <>
      <div className="friends-me">
        <div className="friend-head">
          <h4>Friends</h4>
          <div className="total_friend">
            <h6>Total Friend </h6> <h5> 20</h5>
          </div>
        </div>

        <div className="friend-conent">
          <img src={images["friend-img2.png"]} alt="" />
          <img src={images["friend-img2.png"]} alt="" />

          <img src={images["friend-img3.png"]} alt="" />
          <img src={images["friend-img4.png"]} alt="" />
          <img src={images["friend-img5.png"]} alt="" />
          <img src={images["friend-img1.png"]} alt="" />
          <img src={images["course-persI1.png"]} alt="" />

          <img src={images["friend-img3.png"]} alt="" />
        </div>
      </div>
    </>
  );
};

export default FriendsList;
